import buildingsIcon from '../images/buildings-icon.svg';
import calculatorImage from '../images/calculator.svg';
import { Organization, User } from '../types';

interface DashboardHomeProps {
  user: User;
  organization: Organization;
}

export default function DashboardHome(props: DashboardHomeProps) {
  const { user, organization } = props;

  const firstName = user.name ? user.name.split(' ')[0] : 'Sales Rep';
  const organizatinName = organization?.name;
  return (
    <div className="relative flex flex-col items-center bg-repeat-x">
      <div className="mx-8 mt-6 flex space-x-3 self-end rounded-lg border border-gray-300 px-4 py-3">
        <div className="overflow-hidden rounded-full">
          <img src={buildingsIcon} alt="buildings icon" className="h-10 w-10" />
        </div>
        <div className="self-center">
          <div className="text-sm font-semibold">{organizatinName}</div>
          <div className="text-sm text-gray-600">Dealops</div>
        </div>
      </div>
      <div
        className="mt-16"
        style={{
          opacity: 0.8,
          backgroundImage: `linear-gradient(rgb(200,200,200) 2px, transparent 1px), linear-gradient(to right, rgb(200,200,200) 2px, white 1px)`,
          backgroundSize: '60px 60px',
        }}
      >
        <div
          className="flex w-[50rem] justify-center pt-4"
          style={{
            background:
              'radial-gradient(ellipse, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)',
          }}
        >
          <img className="h-80" alt="calculator" src={calculatorImage}></img>
        </div>
      </div>
      <div className="mt-4 text-3xl">👋</div>
      <div className="text-3xl font-semibold">Welcome {firstName}!</div>
      <div className="mt-4 max-w-xl text-center text-gray-600">
        End-to-end pricing engine. From custom deal pricing recommendations to
        seamless CPQ integration.
      </div>
      <a
        href="/app/opportunity"
        mb-8
        className="mb-8 mt-4 rounded-lg bg-fuchsia-900 px-3 py-2 text-sm font-bold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
      >
        Price an opportunity
      </a>
    </div>
  );
}
