import {
  CheckIcon,
  ClockIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import { Crepe } from '@milkdown/crepe';
import { replaceAll } from '@milkdown/kit/utils';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Badge, { BadgeColor } from 'src/components/Badge';
import Button from 'src/components/Button';
import { CrepeHeadlessEditor } from 'src/components/CrepeEditor';
import { useModal } from 'src/components/Modal';
import Tooltip from 'src/components/Tooltip';
import { User } from 'src/types';
import { classNames } from '../App';
import { getQuoteContextTemplate } from '../Opportunity/OpportunityDetailPage';
import ApprovalOptionsModal from './Approvals/ApprovalOptionsModal';
import { PricingFlowModelType } from './PricingFlow';
import { PricingFlowOrSnapshotForNavigation } from './QuoteOptionsSection';

type StepStatus =
  | 'APPROVED'
  | 'PENDING' // This is the enum that's returned from the StepSnapshot's conditionForApprovalJavascript
  | 'REQUIRES_PACKAGING'; // This is a special status that is used when a step is pending and requires packaging
interface StepSnapshotWithStatus {
  id: string; // snapshot ID
  name: string;
  status: StepStatus;
  usersWithStatus: UserWithStatus[];
  color: string;
  approvalRules: {
    id: string;
    humanReadable: string;
  }[];
}
interface UserWithStatus {
  id: string;
  name: string | null;
  status: UserApprovalStatus;
}
type UserApprovalStatus = 'APPROVED' | 'REJECTED' | 'PENDING';
type ApprovalFlowStatus =
  | 'APPROVED'
  | 'PENDING'
  | 'NO_APPROVAL_NEEDED'
  | 'REQUIRES_PACKAGING';
interface ApprovalFlowWithStatuses {
  status: ApprovalFlowStatus;
  stepsWithStatus: StepSnapshotWithStatus[];
}
type ApprovalStatusForPricingFlow = {
  currentApprovalRequest: {
    id: string;
    createdAt: string;
    recalledAt?: Date;
  } | null;
  approvalFlowWithStatuses: ApprovalFlowWithStatuses;
  submitter?: string;
};
const HARVEY_TEMPLATE = '[Please describe this quote option]';

export default function PricingFlowList(props: {
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  modelType: PricingFlowModelType;
  onClick?: (pricingFlow: PricingFlowOrSnapshotForNavigation) => void;
  hideUserActionButtons?: boolean;
  user: User;
  displayApprovalFlowForDraft?: boolean;
  hasApprovals?: boolean;
  hideName?: boolean;
  listItemClassName?: string;
  purpose: string;
  crepes: { [key: string]: Crepe | null };
  setCrepes: React.Dispatch<
    React.SetStateAction<{ [key: string]: Crepe | null }>
  >;
  contextEditableMode: 'edit-only' | 'no-edit' | 'default';
}) {
  const {
    pricingFlows,
    modelType,
    onClick,
    user,
    hideUserActionButtons,
    displayApprovalFlowForDraft,
    hasApprovals,
    hideName,
    listItemClassName,
    purpose,
    crepes,
    setCrepes,
    contextEditableMode,
  } = props;
  const [approvalStatuses, setApprovalStatuses] = useState<{
    [pricingFlowId: string]: ApprovalStatusForPricingFlow;
  }>({});

  function uniqueIdentifier(pricingFlowId: string) {
    return `PricingFlowList-${purpose}-${pricingFlowId}`;
  }
  const [isEditing, setIsEditing] = useState(
    contextEditableMode === 'edit-only',
  );
  const defaultValueForDisplay = 'No quote description';
  const templateForEditing = getQuoteContextTemplate({
    pricingFlow: pricingFlows[0],
  });

  useEffect(() => {
    hasApprovals &&
      Promise.all(
        pricingFlows.map(async (pricingFlow) => {
          const response = await (async () => {
            switch (modelType) {
              case 'pricingFlowSnapshot':
                return await api.get(
                  'approvals/flows/status?pricingFlowSnapshotId=' +
                    pricingFlow.id,
                );
              case 'pricingFlow':
              default:
                return await api.get(
                  'approvals/flows/status?pricingFlowId=' + pricingFlow.id,
                );
            }
          })();
          const data = await response.data;
          return [pricingFlow.id, data];
        }),
      ).then((data) => {
        setApprovalStatuses(Object.fromEntries(data));
      });
  }, [pricingFlows]);
  console.log(approvalStatuses);

  useEffect(() => {
    const crepeInstances: Crepe[] = [];

    pricingFlows.forEach((pricingFlow) => {
      const newCrepe = new Crepe({
        root: `#crepeeditor--HeadlessEditor-${uniqueIdentifier(pricingFlow.id)}`,
        defaultValue:
          pricingFlow.context ??
          (isEditing ? templateForEditing : defaultValueForDisplay),
      });

      if (!isEditing) {
        newCrepe.setReadonly(true);
      }

      newCrepe.create().then(() => {
        console.log('Crepe editor created');
        setCrepes((prev) => ({ ...prev, [pricingFlow.id]: newCrepe }));
      });

      crepeInstances.push(newCrepe);
    });

    // Return a single cleanup function that destroys all instances
    return () => {
      crepeInstances.forEach((crepe) => {
        crepe.destroy();
        console.log('Crepe editor destroyed');
      });
    };
  }, []);

  useEffect(() => {
    // if isEditing changes, toggle the edit mode for all crepes
    pricingFlows.forEach((pricingFlow) => {
      const crepe = crepes[pricingFlow.id];
      if (crepe) {
        crepe.setReadonly(!isEditing);

        if (isEditing && isNil(pricingFlow.context)) {
          crepe.editor.action(replaceAll(templateForEditing));
        }
      }
    });
  }, [isEditing, crepes]);

  return (
    <>
      {pricingFlows?.map((pricingFlow) => {
        return (
          <div
            className={classNames('flex flex-col gap-2', listItemClassName)}
            key={pricingFlow.id}
          >
            <div className="flex justify-between">
              {/* left side */}
              <div className="flex flex-col gap-2">
                {/* Name */}
                {!hideName && (
                  <div className="flex flex-row space-x-2 items-center">
                    <div className="font-medium text-slate-950 text-sm">
                      {pricingFlow.name ?? 'Quote option'}
                    </div>
                    <div className="text-xs text-slate-600">
                      Last updated {dayjs(pricingFlow.updatedAt).fromNow()}
                    </div>
                  </div>
                )}

                {/* Approvals */}
                {hasApprovals && (
                  <ApprovalStatus
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    displayApprovalFlowForDraft={displayApprovalFlowForDraft}
                  />
                )}
              </div>

              {/* right side */}
              <div className="flex items-start gap-x-2">
                {!hideUserActionButtons && hasApprovals && (
                  <ApprovalActionButtons
                    pricingFlow={pricingFlow}
                    modelType={modelType}
                    approvalStatus={approvalStatuses[pricingFlow.id]}
                    user={user}
                  />
                )}
                {!isNil(onClick) && (
                  <Button
                    color="white"
                    onClick={() => onClick(pricingFlow)}
                    label="Open"
                  />
                )}
              </div>
            </div>
            {/* Quote Description */}
            <div
              className={classNames(
                'flex flex-row justify-between w-full align-top rounded-md p-2 border border-gray-200',
                isEditing ? 'bg-white' : 'bg-gray-50',
              )}
            >
              <div className="w-full">
                <CrepeHeadlessEditor
                  uniqueIdentifier={uniqueIdentifier(pricingFlow.id)}
                />
              </div>
              {contextEditableMode === 'default' &&
                (isEditing ? (
                  <div className="">
                    <Button
                      color="noBg"
                      className=""
                      label="Save"
                      onClick={() => {
                        setIsEditing(false);
                        const crepe = crepes[pricingFlow.id];
                        if (!isNil(crepe)) {
                          const markdown = crepe.getMarkdown().trim();
                          api.post(
                            'pricingFlows/' +
                              pricingFlow.originalPricingFlowId +
                              '/update-context',
                            { context: markdown },
                          );
                          // replace the message
                          crepe.editor.action(replaceAll(markdown));
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div className="">
                    <Button
                      color="noBg"
                      className=""
                      label="Edit"
                      onClick={() => setIsEditing(true)}
                    />
                  </div>
                ))}
            </div>
          </div>
        );
      })}
    </>
  );
}

const hexToHSL = (hex: string) => {
  // Convert hex to RGB first
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  // Find greatest and smallest channel values
  let cmin = Math.min(r, g, b);
  let cmax = Math.max(r, g, b);
  let delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  // Calculate hue
  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);
  if (h < 0) h += 360;

  // Calculate lightness
  l = (cmax + cmin) / 2;

  // Calculate saturation
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

  return {
    h,
    s: Math.round(s * 100),
    l: Math.round(l * 100),
  };
};

export const getLightVariant = (hex: string) => {
  const { h, s, l } = hexToHSL(hex);
  // Keep the same hue, reduce saturation, increase lightness
  return `hsl(${h}, ${Math.max(s - 10, 0)}%, ${Math.min(95, l + 45)}%)`;
};

function ApprovalFlow(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
}) {
  const { approvalStatus } = props;
  const [expandedStep, setExpandedStep] = useState<string | null>(null);

  const getStepColor = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED') return '#166534';
    return step.color;
  };
  const getIcon = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED')
      return <CheckIcon className="w-3 h-3 mr-1" />;
    if (step.status === 'PENDING')
      return <ClockIcon className="w-3 h-3 mr-1" />;
    if (step.status === 'REQUIRES_PACKAGING')
      return <ExclamationTriangleIcon className="w-3 h-3 mr-1" />;
  };
  const getStepStatus = (step: StepSnapshotWithStatus) => {
    if (step.status === 'APPROVED') return 'Approved';
    if (step.status === 'PENDING') return 'Required';
    if (step.status === 'REQUIRES_PACKAGING') return 'Requires packaging';
  };
  const getUserBadge = (status: UserApprovalStatus) => {
    switch (status) {
      case 'APPROVED':
        return <Badge color="green">Approved</Badge>;
      // case 'SMART_APPROVED':
      //   return <Badge color="green">Smart approved</Badge>;
      case 'REJECTED':
        return <Badge color="red">Rejected</Badge>;
      case 'PENDING':
        return <Badge color="yellow">Required</Badge>;
    }
  };

  if (isNil(approvalStatus)) return null;

  return (
    <div className="flex flex-col gap-4 w-full max-w-2xl">
      <div className="flex flex-row gap-2 flex-wrap">
        {approvalStatus.approvalFlowWithStatuses.stepsWithStatus.map((step) => (
          <div key={step.id} className="flex flex-col gap-2">
            <Tooltip
              as="span"
              className=""
              location="TOP"
              text={getStepStatus(step)}
            >
              <button
                onClick={() =>
                  setExpandedStep(expandedStep === step.id ? null : step.id)
                }
              >
                <Badge
                  color="gray"
                  className="hover:opacity-80"
                  style={{
                    color: getStepColor(step),
                    background: getLightVariant(getStepColor(step)),
                    opacity: isNil(expandedStep)
                      ? 1
                      : expandedStep === step.id
                        ? 1
                        : 0.6,
                  }}
                >
                  {getIcon(step)}
                  {step.name}
                </Badge>
              </button>
            </Tooltip>
          </div>
        ))}
      </div>
      {expandedStep && (
        <div className="flex flex-col gap-2 pl-4">
          {approvalStatus.approvalFlowWithStatuses.stepsWithStatus.find(
            (s) => s.id === expandedStep,
          )?.status === 'REQUIRES_PACKAGING' && (
            // Information about the step that requires packaging
            <div className="flex items-center gap-3 p-2 bg-white rounded-lg border border-slate-200">
              <span className="text-sm font-medium">
                This step requires packaging. <br />
                You will need to add this quote to a group before it can be
                approved.
              </span>
            </div>
          )}
          {approvalStatus.approvalFlowWithStatuses.stepsWithStatus
            .find((s) => s.id === expandedStep)
            ?.usersWithStatus.map((user) => (
              <div
                key={user.name}
                className="flex items-center gap-8 p-2 bg-white rounded-lg border border-slate-200 flex-row justify-between"
              >
                <div className="flex flex-row gap-2 items-center">
                  <span className="text-sm font-medium">{user.name}</span>
                  {!isNil(approvalStatus.currentApprovalRequest?.createdAt) && (
                    <>
                      <span className="text-xs text-slate-500">
                        Requested{' '}
                        {dayjs(
                          approvalStatus.currentApprovalRequest?.createdAt,
                        ).fromNow()}
                      </span>
                      <button
                        className="flex items-center gap-1 text-xs text-blue-600 hover:text-blue-800"
                        onClick={(e) => {
                          e.stopPropagation();
                          // Handle nudge action
                          // @TODO(fay)
                        }}
                      >
                        {/* <Bell className="w-3 h-3" /> */}
                        Nudge
                      </button>
                    </>
                  )}
                </div>

                {getUserBadge(user.status)}
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
function ApprovalStatus(props: {
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  displayApprovalFlowForDraft?: boolean;
}) {
  const { approvalStatus, displayApprovalFlowForDraft } = props;

  const [shouldDisplayApprovalFlow, setShouldDisplayApprovalFlow] = useState(
    displayApprovalFlowForDraft,
  );
  const [isApprovalFlowExpanded, setIsApprovalFlowExpanded] = useState(
    displayApprovalFlowForDraft,
  );
  const [approvalFlowDetails, setApprovalFlowDetails] = useState<{
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } | null>(null);

  useEffect(() => {
    const statusDisplay = getStatusDisplay(approvalStatus);
    setShouldDisplayApprovalFlow(
      statusDisplay.displayDetails || displayApprovalFlowForDraft,
    );
    setIsApprovalFlowExpanded(
      statusDisplay.displayDetails || displayApprovalFlowForDraft,
    );
    setApprovalFlowDetails(statusDisplay);
  }, [approvalStatus]);

  if (isNil(approvalFlowDetails)) return null;

  function getStatusDisplay(
    approvalStatus: ApprovalStatusForPricingFlow | undefined,
  ): {
    color: BadgeColor;
    text: string;
    displayDetails: boolean;
  } {
    if (isNil(approvalStatus))
      return { color: 'gray', text: 'Draft', displayDetails: false };
    if (approvalStatus.currentApprovalRequest) {
      if (!isNil(approvalStatus.currentApprovalRequest.recalledAt)) {
        return { color: 'red', text: 'Recalled', displayDetails: true };
      }
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return { color: 'green', text: 'Approved', displayDetails: true };
        case 'PENDING':
          return {
            color: 'yellow',
            text: 'Pending approval',
            displayDetails: true,
          };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: true,
          };
        case 'REQUIRES_PACKAGING':
          return {
            color: 'yellow',
            text: 'Requires packaging',
            displayDetails: true,
          };
      }
    } else {
      switch (approvalStatus.approvalFlowWithStatuses.status) {
        case 'APPROVED':
          return {
            color: 'green',
            text: 'Smart approved',
            displayDetails: true,
          };
        case 'PENDING':
        case 'REQUIRES_PACKAGING':
          return { color: 'gray', text: 'Draft', displayDetails: false };
        case 'NO_APPROVAL_NEEDED':
          return {
            color: 'green',
            text: 'No approval needed',
            displayDetails: false,
          };
      }
    }
  }
  return (
    <div className="flex flex-col gap-2">
      <div className="flex flex-row gap-1">
        <Badge color={approvalFlowDetails.color}>
          {approvalFlowDetails.text}
        </Badge>
        {shouldDisplayApprovalFlow && (
          <Button
            color="noBg"
            size="xs"
            label={isApprovalFlowExpanded ? 'Hide details' : 'Show details'}
            onClick={() => setIsApprovalFlowExpanded(!isApprovalFlowExpanded)}
          />
        )}
      </div>
      {isApprovalFlowExpanded && (
        <ApprovalFlow approvalStatus={approvalStatus} />
      )}
    </div>
  );
}

function ApprovalActionButtons(props: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  modelType: PricingFlowModelType;
  approvalStatus: ApprovalStatusForPricingFlow | undefined;
  user: User;
}) {
  const { pricingFlow, modelType, approvalStatus, user } = props;
  const navigate = useNavigate();
  const { showModal, hideModal } = useModal();

  if (isNil(approvalStatus)) return null;

  if (!isNil(approvalStatus.currentApprovalRequest)) {
    if (modelType === 'pricingFlowSnapshot') {
      return null;
    }
    const isApprover =
      approvalStatus.approvalFlowWithStatuses.stepsWithStatus.some((s) =>
        s.usersWithStatus.some((u) => u.id === user.id),
      );
    if (isApprover) {
      const userApprovalStatus =
        approvalStatus.approvalFlowWithStatuses.stepsWithStatus
          .find((s) => s.usersWithStatus.some((u) => u.id === user.id))
          ?.usersWithStatus.find((u) => u.id === user.id)
          ?.status as UserApprovalStatus;
      switch (userApprovalStatus) {
        case 'APPROVED':
          return (
            <div className="flex items-center gap-x-2">
              <Button
                color="white"
                onClick={async () => {
                  // PUT /api/v1/approvals/requests/{approvalRequestId}/actions/recall
                  await api.put(
                    `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/actions/recall`, // @TODO(fay) how can this be null
                    {},
                  );
                  // refresh current page
                  window.location.reload();
                }}
                label="Recall approval"
              />
            </div>
          );
        // case 'SMART_APPROVED':
        //   return (
        //     <div className="flex items-center gap-x-2">
        //       <Button
        //         color="white"
        //         onClick={() => {
        //           navigate(`pricingflow/${pricingFlowId}`, {
        //             relative: 'path',
        //           });
        //         }}
        //         label="Recall smart approval"
        //       />
        //     </div>
        //   );
        case 'REJECTED':
          return (
            <div className="flex items-center gap-x-2">
              <Button
                color="white"
                onClick={async () => {
                  // PUT /api/v1/approvals/requests/{approvalRequestId}/actions/recall
                  await api.put(
                    `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/actions/recall`,
                    {},
                  );
                  // refresh current page
                  window.location.reload();
                }}
                label="Recall rejection"
              />
            </div>
          );
        case 'PENDING':
          return (
            <div className="flex items-center gap-x-2">
              <Button
                color="green-outline"
                onClick={async () => {
                  // POST /api/v1/approvals/requests/{approvalRequestId}/actions
                  // Body: { action: "APPROVE" | "REJECT" }
                  await api.post(
                    `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/actions`,
                    {
                      action: 'APPROVE',
                    },
                  );
                  // refresh current page
                  window.location.reload();
                }}
                label="Approve"
              />
              <Button
                color="red-outline"
                onClick={async () => {
                  // POST /api/v1/approvals/requests/{approvalRequestId}/actions
                  // Body: { action: "APPROVE" | "REJECT" }
                  await api.post(
                    `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/actions`,
                    {
                      action: 'REJECT',
                    },
                  );
                  // refresh current page
                  window.location.reload();
                }}
                label="Reject"
              />
            </div>
          );
      }
    }

    if (approvalStatus.submitter === user.id) {
      const recallButton = (
        <Button
          color="white"
          onClick={async () => {
            // show modal to confirm with user they want to recall
            showModal({
              newStyle: true,
              title: 'Recall approval request',
              children: (
                <div className="flex-1 flex flex-col overflow-hidden">
                  <span className="p-4 font-medium text-sm text-slate-600 mb-4">
                    Are you sure you want to recall this approval request?
                  </span>

                  <div className="mt-4 shrink-0 border-t border-100">
                    <div className="flex flex-row justify-between p-4 gap-4 sm:flex-row-reverse">
                      <Button
                        color="red-outline"
                        onClick={async () => {
                          await api.put(
                            `approvals/requests/${approvalStatus.currentApprovalRequest?.id}/recall`,
                            {},
                          );
                          // refresh current page
                          window.location.reload();
                        }}
                        label="Recall approval request"
                        className="flex-1"
                      />
                      <Button
                        color="white"
                        onClick={hideModal}
                        label="Cancel"
                        className="flex-1"
                      />
                    </div>
                  </div>
                </div>
              ),
            });
          }}
          label="Recall request"
        />
      );

      if (
        approvalStatus.approvalFlowWithStatuses.status === 'REQUIRES_PACKAGING'
      ) {
        return (
          <div className="flex items-center gap-x-2">
            {recallButton}
            <Button
              color="white"
              onClick={async () => {
                const response = await api.get(
                  `pricingFlow?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
                );
                const pricingFlowWithOpportunity =
                  response.data.pricingFlowData;
                showModal({
                  newStyle: true,
                  title: 'Add to group and submit for approval',
                  children: (
                    <ApprovalOptionsModal
                      hideModal={hideModal}
                      pricingFlow={pricingFlowWithOpportunity}
                      opportunity={pricingFlowWithOpportunity.opportunity}
                      user={props.user}
                    />
                  ),
                });
              }}
              label="Add to group"
            />
          </div>
        );
      }
      // If the user is the submitter, show [Recall approval request]
      return <div className="flex items-center gap-x-2">{recallButton}</div>;
    }

    return null;
  } else {
    return (
      <Button
        color="white"
        onClick={async () => {
          const response = await api.get(
            `pricingFlow?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
          );
          const pricingFlowWithOpportunity = response.data.pricingFlowData;
          showModal({
            newStyle: true,
            title: 'Ask for approval',
            children: (
              <ApprovalOptionsModal
                hideModal={hideModal}
                pricingFlow={pricingFlowWithOpportunity}
                opportunity={pricingFlowWithOpportunity.opportunity}
                user={props.user}
              />
            ),
          });
        }}
        label="Submit for approval"
      />
    );
  }
}
