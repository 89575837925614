import { HamsterOpportunityData } from '../Hamster/hamster_types';
import { PricingFlowCommon, PricingFlowType } from '../types';
import { PenguinOpportunityData, PenguinProduct } from './penguin_types';

const pricingCurveRegistry: Record<
  string,
  (pricingFlow: PricingFlowCommon) => boolean
> = {
  default: () => {
    return true;
  },
  testCurve_20240901: (pricingFlow) => {
    // corresponds to opp id 006UV000003tGwtYAE in penguin prod
    return pricingFlow.id === '06212f70-6239-4c2f-b5a1-f0a6d0a62f4c';
  },
  balance_and_transactions_experiment_202409: (pricingFlow) => {
    return pricingFlow.type === PricingFlowType.PENGUIN;
  },
  hamster_midmarket: (pricingFlow) => {
    return (
      pricingFlow.type === PricingFlowType.HAMSTER &&
      (pricingFlow.opportunity.opportunityData as HamsterOpportunityData)
        ?.Segment === 'Mid-Market'
    );
  },
  signal_3x_for_crypto: (pricingFlow) => {
    return (
      pricingFlow.type === PricingFlowType.PENGUIN &&
      (pricingFlow.opportunity.opportunityData as PenguinOpportunityData)
        ?.Opportunity_GTM_Segment__c === 'Crypto' &&
      ((pricingFlow.products as unknown as PenguinProduct[])?.find(
        (p) => p.name === 'Signal',
      )?.transactionSize?.value ?? 0) > 1000
    );
  },
};

export default pricingCurveRegistry;
