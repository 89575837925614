import { Organization, User } from '../../../../types';

import Waterfall, {
  Datum,
  GrossProfitAndTakeRateTooltip,
} from 'src/components/graphs/WaterfallChart';
import { useModal } from 'src/components/Modal';
import { useToast } from 'src/components/Toast';
import Tooltip from 'src/components/Tooltip';
import BottomBar from '../../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../../PricingFlow';
import {
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValueType,
  DerivedValue,
} from '../../types_common/price';
import {
  ALL_ALPACA_PRODUCT_CATEGORIES,
  AlpacaPricingFlow,
  AlpacaProductCategory,
  AlpacaSupportedCurrency,
  MonthlySubscriptionFee,
} from '../alpaca_types';
import {
  EMPTY_DERIVED_AGGREGATIONS,
  formatCurrencyValue,
} from '../alpaca_utils';
import AlpacaFinalizeQuoteModal from '../Components/AlpacaFinalizeQuoteModal';
import AlpacaHeader from '../Components/AlpacaHeader';
import AlpacaHelpButton from '../Components/AlpacaHelpButton';
import AlpacaOpportunityInfoButton from '../Components/AlpacaOpportunityInfoButton';
import DerivedCurrencyValue from '../Components/DerivedCurrencyValue';

// Check if there are any issues in the pricing flow that will prevent it from
// writing to sfdc correctly
function validatePricingFlow(pricingFlow: AlpacaPricingFlow): {
  isValid: boolean;
  maybeErrorReason: string;
} {
  // There are no validations right now
  return { isValid: true, maybeErrorReason: '' };
}

export default function DealSummary(props: {
  pricingFlow: AlpacaPricingFlow;
  updateFlow: (flow: AlpacaPricingFlow, showLoading?: boolean) => void;
  user: User;
  organization: Organization;
  nextStep: () => void;
  previousStep: () => void;
}) {
  const { pricingFlow, previousStep } = props;
  const { editMode } = usePricingFlowContext();
  const { showToast } = useToast();
  const derivedAggregations = pricingFlow.additionalData.derivedAggregations;
  const monthlySubscriptionFee =
    pricingFlow.additionalData.monthlySubscriptionFee;

  const { showModal, hideModal } = useModal();

  function handleShowFinalizeQuoteModal() {
    const { isValid, maybeErrorReason } = validatePricingFlow(pricingFlow);
    if (isValid) {
      showModal({
        title: 'Finalize quote',
        children: (
          <AlpacaFinalizeQuoteModal
            close={hideModal}
            pricingFlow={pricingFlow}
            updateFlow={props.updateFlow}
            editMode={editMode}
            user={props.user}
          />
        ),
      });
    } else {
      showToast({
        title: maybeErrorReason,
        subtitle: '',
        type: 'error',
      });
    }
  }

  return (
    <div id="step_2" className="">
      <AlpacaHeader
        title="Deal summary"
        description="Make sure everything is correct."
        rightChildren={[<AlpacaOpportunityInfoButton />, <AlpacaHelpButton />]}
      />

      <div className="px-8">
        <div className="my-5 rounded-xl border border-gray-200 bg-white">
          <h3 className="text-md whitespace-nowrap px-6 py-4 font-semibold text-gray-900">
            Deal Summary
          </h3>
          <table className="min-w-full border-separate border-spacing-0 border-t">
            <OverviewTableHeader />
            <tbody>
              {monthlySubscriptionFee && (
                <OverviewMonthlySubscriptionFeeRow
                  monthlySubscriptionFee={monthlySubscriptionFee}
                />
              )}
              {ALL_ALPACA_PRODUCT_CATEGORIES.map((category) => {
                const productCategory =
                  pricingFlow.additionalData.productCategories?.find(
                    (pc) => pc.category === category.category,
                  );
                if (!productCategory) return null;
                return (
                  <OverviewTableRow
                    key={category.category}
                    category={productCategory}
                    quoteCurrency={pricingFlow.additionalData.quoteCurrency}
                  />
                );
              })}
            </tbody>
            <OverviewTableFooter
              grossRevenue={derivedAggregations?.grossRevenue ?? null}
              grossProfit={derivedAggregations?.grossProfit ?? null}
              profitMargin={derivedAggregations?.profitMargin ?? null}
            />
          </table>
        </div>

        <DealSummaryWaterfallChart />
      </div>

      <div className="h-24" />
      <BottomBar
        primaryButtonProps={{
          label: 'Finalize quote',
          onClick: async () => handleShowFinalizeQuoteModal(),
        }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStep(),
        }}
      />
    </div>
  );
}

function OverviewTableHeader() {
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full  border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          Product
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Volume
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Transaction count
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Gross Revenue
        </th>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly revenue - monthly cost"
        >
          Gross profit
        </Tooltip>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly gross profit / monthly revenue"
        >
          Profit margin
        </Tooltip>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly gross profit / monthly est. volume"
        >
          Net take rate
        </Tooltip>
      </tr>
    </thead>
  );
}

function OverviewTableFooter(props: {
  grossRevenue: DerivedValue<CurrencyValue> | null;
  grossProfit: DerivedValue<CurrencyValue> | null;
  profitMargin: DerivedValue<CurrencyValue> | null;
}) {
  const { grossRevenue, grossProfit, profitMargin } = props;
  return (
    <tfoot className="text-left ">
      <tr className="border border-t bg-slate-50">
        <th className="whitespace-nowrap border-t bg-slate-50 px-6 py-4 text-sm font-medium text-gray-500">
          Total
        </th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500"></th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500"></th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500">
          <DerivedCurrencyValue value={grossRevenue} />
        </th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500">
          <DerivedCurrencyValue value={grossProfit} />
        </th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500">
          <DerivedCurrencyValue value={profitMargin} />
        </th>
        <th className="whitespace-nowrap border-t px-6 py-4 text-sm font-medium text-gray-500">
          {/* do not show aggregated take rate */}
        </th>
      </tr>
    </tfoot>
  );
}

// Semi-synced with server/src/utils/alpaca/opportunity_line_items.ts # getMonthlyFeeAtScale
function computeMonthlySubscriptionFeeRollup(
  monthlySubscriptionFee: MonthlySubscriptionFee,
): CurrencyValueFlat {
  if (monthlySubscriptionFee.type === 'tiered') {
    const tiers = monthlySubscriptionFee.tiers.sort((a, b) => {
      return a.minimum.value - b.minimum.value;
    });
    return tiers[tiers.length - 1].currencyValue;
  }
  return monthlySubscriptionFee;
}
function OverviewMonthlySubscriptionFeeRow(props: {
  monthlySubscriptionFee: MonthlySubscriptionFee;
}) {
  const { monthlySubscriptionFee } = props;
  const rollup = computeMonthlySubscriptionFeeRollup(monthlySubscriptionFee);
  return (
    <tr>
      {/* Product */}
      <td className="relative min-w-[172px] px-6 py-4">
        <div className="flex flex-col gap-1">
          <span className="min-h-6 text-sm font-medium text-gray-900">
            Monthly subscription fee
          </span>
          <span className="text-xs text-gray-600 2xl:hidden"></span>
          <span className="text-xs text-gray-600"></span>
        </div>
      </td>

      {/* Est. volume */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        N/A
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        N/A
      </td>
      {/* Gross revenue */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        {formatCurrencyValue(rollup)}
      </td>
      {/* Gross profit */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        {formatCurrencyValue(rollup)}
      </td>
      {/* Profit margin */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        100%
      </td>
      {/* Take rate */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        100%
      </td>
    </tr>
  );
}

function OverviewTableRow(props: {
  category: AlpacaProductCategory;
  quoteCurrency: AlpacaSupportedCurrency;
}) {
  const { category, quoteCurrency } = props;
  const {
    estimatedVolume,
    estimatedTransactionCount,
    grossRevenue,
    grossProfit,
    profitMargin,
    takeRate,
  } = category.derivedAggregations ?? EMPTY_DERIVED_AGGREGATIONS(quoteCurrency);

  return (
    <tr>
      {/* Product */}
      <td className="relative min-w-[172px] px-6 py-4">
        <div className="flex flex-col gap-1">
          <span className="min-h-6 text-sm font-medium text-gray-900">
            {category.category}
          </span>
          {/* @TODO(fay) add product category */}
          <span className="text-xs text-gray-600 2xl:hidden"></span>
          <span className="text-xs text-gray-600"></span>
        </div>
      </td>

      {/* Est. volume */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={estimatedVolume} />
      </td>
      {/* Transaction count */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={estimatedTransactionCount} />
      </td>
      {/* Gross revenue */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={grossRevenue} />
      </td>
      {/* Gross profit */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={grossProfit} />
      </td>
      {/* Profit margin */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={profitMargin} />
      </td>
      {/* Take rate */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500">
        <DerivedCurrencyValue value={takeRate} />
      </td>
    </tr>
  );
}

function DealSummaryWaterfallChart() {
  const { pricingFlow } = usePricingFlowContext<AlpacaPricingFlow>();
  const productCategories = pricingFlow.additionalData.productCategories;
  let pv = 0;
  let data: (Datum | undefined)[] = [];

  // First add monthly subscription fee if it exists
  const monthlySubscriptionFee =
    pricingFlow.additionalData.monthlySubscriptionFee;
  if (monthlySubscriptionFee) {
    const rollup = computeMonthlySubscriptionFeeRollup(monthlySubscriptionFee);
    pv = rollup.value;
    data.push({
      name: 'Monthly subscription fee',
      value: rollup.value,
      transparentValue: 0,
      tooltipData: {
        'Gross profit': rollup,
        'Take rate': { type: CurrencyValueType.PERCENT, value: 100 },
      },
    });
  }

  // Then add all product categories
  data = data.concat(
    ALL_ALPACA_PRODUCT_CATEGORIES.map((categoryInfo) => {
      const productCategory = productCategories?.find(
        (pc) => pc.category === categoryInfo.category,
      );
      if (!productCategory) return undefined;
      const derivedAggregations = productCategory.derivedAggregations;
      const grossProfit = derivedAggregations?.grossProfit?.value ?? 0;
      const oldPv = pv;
      pv = grossProfit + oldPv; // set the pv for the next datum
      return {
        name: productCategory.category,
        value: grossProfit,
        transparentValue: oldPv,
        tooltipData: {
          'Gross profit': derivedAggregations?.grossProfit,
          'Take rate': derivedAggregations?.takeRate,
        },
      };
    }),
  );
  const filteredData = data.filter((d) => d !== undefined) as Datum[];

  // Then add total
  filteredData?.push({
    name: 'Total',
    value:
      pricingFlow.additionalData.derivedAggregations?.grossProfit?.value ?? 0,
    transparentValue: 0,
    tooltipData: {
      'Gross profit':
        pricingFlow.additionalData.derivedAggregations?.grossProfit,
      'Take rate': pricingFlow.additionalData.derivedAggregations?.takeRate,
    },
  });

  return (
    <>
      {/* Legend */}
      <div className="w-full flex justify-end pt-4">
        <div className="flex flex-row gap-2">
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#22C55E' }}
            />
            <span className="text-sm text-slate-700">Positive</span>
          </div>
          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#DC2626' }}
            />
            <span className="text-sm text-slate-700">Negative</span>
          </div>

          <div className="flex flex-row gap-2 items-center">
            <div
              className="h-2 w-2 rounded-md"
              style={{ backgroundColor: '#3B82F6' }}
            />
            <span className="text-sm text-slate-700">Monthly gross profit</span>
          </div>
        </div>
      </div>
      {/* Chart */}
      <div className="w-full h-[300px]">
        <Waterfall
          data={filteredData}
          currency={pricingFlow.additionalData.quoteCurrency}
          TooltipComponent={GrossProfitAndTakeRateTooltip}
        />
      </div>
    </>
  );
}
