import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import 'src/components/CrepeEditor.css';

import { DocumentMagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { replaceAll } from '@milkdown/kit/utils';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { Spinner } from 'src/components/Loading';
import { classNames } from 'src/dashboard/App';
import { OpportunityCommon } from '../types';

interface ApprovalRequestFormProps {
  initialMessage: string;
  crepe: Crepe | null;
  setCrepe: React.Dispatch<React.SetStateAction<Crepe | null>>;
  opportunity: OpportunityCommon;
}
function OpportunityContextEditor(props: ApprovalRequestFormProps) {
  const { crepe, setCrepe, initialMessage, opportunity } = props;
  const [isLoading, setIsLoading] = useState(isNil(initialMessage));

  useEffect(() => {
    const newCrepe = new Crepe({
      root: '#crepeeditor--ApprovalRequestForm',
      defaultValue: initialMessage,
    });
    newCrepe.create().then(() => {
      console.log('Crepe editor created');
      setCrepe(newCrepe);
    });

    return () => {
      // Destroy the editor when unmounting component
      newCrepe.destroy();
      console.log('Crepe editor destroyed');
    };
  }, []);

  async function proofreadMessage() {
    setIsLoading(true);
    const markdown = crepe?.getMarkdown().trim();
    console.log('Got markdown');
    console.log(markdown);
    const response = await api.post(
      'opportunities/proofreadContext/' + opportunity.id,
      {
        markdown,
      },
    );
    if (response.status !== 200) {
      console.error('Failed to proofread message');
      setIsLoading(false);
      return;
    } else {
      console.log('Got proofread message');
      console.log(response.data);
      setIsLoading(false);
      replaceMessage(response.data);
    }
  }

  function replaceMessage(message: string) {
    console.log('Replacing message with: ');
    console.log(message);
    setIsLoading(false);
    crepe?.editor.action(replaceAll(message));
  }

  return (
    <div className="relative h-full">
      {isLoading && (
        <div className="absolute w-full h-full flex justify-center items-center opacity-60">
          <Spinner width={40} height={40} />
        </div>
      )}
      <div
        id="crepeeditor--ApprovalRequestForm"
        className={classNames(
          'border border-gray-300 py-2 px-4 m-2 rounded-xl h-full min-h-48',
          isLoading && 'opacity-50 bg-opacity-10 bg-slate-500',
        )}
      />
      {isNil(crepe?.editor) ? (
        'Loading editor...'
      ) : (
        <div>
          <button
            className={classNames(
              'border-gray-200 flex items-center justify-center gap-1 whitespace-nowrap rounded-lg border py-1 px-2 font-medium text-sm text-slate-900 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 absolute top-4 right-8',
              isLoading && 'bg-gray-100',
              !isLoading &&
                ' bg-white hover:bg-gray-200 focus-visible:outline-fuchsia-900',
            )}
            onClick={proofreadMessage}
            disabled={isLoading}
          >
            <DocumentMagnifyingGlassIcon className="h-4 w-4" />
            <span>Proofread</span>
          </button>
        </div>
      )}
    </div>
  );
}
export default OpportunityContextEditor;
