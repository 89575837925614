import { datadogRum } from '@datadog/browser-rum';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import api from 'src/api';
import { useToast } from 'src/components/Toast';
import { User } from 'src/types';
import {
  PricingFlowGroup,
  UpdatePricingFlowGroupBody,
} from '../Opportunity/types';

interface PricingFlowGroupContextType {
  pricingFlowGroup: PricingFlowGroup | null;
  updateGroup: (update: UpdatePricingFlowGroupBody) => Promise<void>;
  editMode: boolean;
}

export const PricingFlowGroupContext = createContext<
  PricingFlowGroupContextType | undefined
>(undefined);

export function usePricingFlowGroupContext() {
  const context = useContext(PricingFlowGroupContext);
  if (context === undefined) {
    throw new Error(
      'usePricingFlowGroupContext must be used within a PricingFlowGroupProvider',
    );
  }
  return context;
}

export function usePricingFlowGroup({
  pricingFlowGroupId,
  user,
}: {
  pricingFlowGroupId: string;
  user: User;
}) {
  const [pricingFlowGroup, setPricingFlowGroup] =
    useState<PricingFlowGroup | null>(null);
  const navigate = useNavigate();
  const { sfdcOppId } = useParams();
  const { showToast } = useToast();
  const editMode = user.permissions.includes('edit_pricing_flow');

  useEffect(() => {
    const fetchPricingFlowGroup = async () => {
      try {
        const { data } = await api.get(
          `pricingFlowGroups/${pricingFlowGroupId}`,
        );
        setPricingFlowGroup(data as PricingFlowGroup);
        // eslint-disable-next-line rulesdir/require-datadog-rum-error
      } catch (e) {
        showToast({
          title: 'Failed to group',
          subtitle: 'Please try again or contact support',
          type: 'error',
        });
        navigate(`/app/opportunity/${sfdcOppId}`);
      } finally {
      }
    };

    void fetchPricingFlowGroup();
  }, [pricingFlowGroupId, sfdcOppId, navigate]);

  const updateGroup = async (updatedGroup: UpdatePricingFlowGroupBody) => {
    if (!editMode) {
      showToast({
        title: 'You do not have permission to update this group',
        subtitle: 'Please contact support@dealops.com',
        type: 'error',
      });
      return;
    }

    try {
      const data = await api.put(
        `pricingFlowGroups/${pricingFlowGroupId}`,
        updatedGroup,
      );
      setPricingFlowGroup(data.data as PricingFlowGroup);
    } catch (error) {
      showToast({
        title: 'Failed to update group',
        subtitle: 'Please try again or contact support',
        type: 'error',
      });
      datadogRum.addError(error);
    }
  };

  return {
    pricingFlowGroup,
    updateGroup,
    editMode,
  };
}
