import {
  ChevronLeftIcon,
  DocumentDuplicateIcon,
  InformationCircleIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import React, { useState } from 'react';

type ButtonColor =
  | 'primary'
  | 'white'
  | 'noBg'
  | 'ghost'
  | 'green-outline'
  | 'red-outline';
type ButtonIcon = 'plus' | 'document-duplicate' | 'chevron-left' | 'info';
type TextAlign = 'left' | 'center';
type ButtonSize = 'xs' | 'default';

interface ButtonProps {
  disabled?: boolean;
  color?: ButtonColor;
  size?: ButtonSize;
  onClick: () => void | Promise<void>;
  icon?: ButtonIcon;
  label?: string;
  children?: React.ReactNode;
  className?: string;
  textAlign?: TextAlign;
}

export const Button: React.FC<ButtonProps> = ({
  disabled,
  color = 'primary',
  size = 'default',
  onClick,
  icon,
  label,
  children,
  className = '',
  textAlign = 'center',
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const baseClasses =
    'flex items-center gap-2 whitespace-nowrap rounded-lg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 transition-colors duration-200';

  const sizeClasses: Record<ButtonSize, string> = {
    default: 'py-1.5 px-2',
    xs: 'py-1 px-1.5 text-xs',
  };

  const colorClasses: Record<
    ButtonColor,
    Record<'default' | 'loading', string>
  > = {
    primary: {
      default:
        'border border-fuchsia-900 shadow-sm bg-fuchsia-900 text-white hover:bg-fuchsia-800',
      loading: 'border border-fuchsia-300 bg-fuchsia-100 text-fuchsia-900',
    },
    white: {
      default:
        'border border-gray-200 shadow-sm bg-white text-slate-900 hover:bg-gray-50',
      loading: 'border border-gray-200 bg-gray-50 text-gray-600',
    },
    noBg: {
      default: 'text-sm text-fuchsia-900 hover:text-fuchsia-700',
      loading: 'text-sm text-fuchsia-300',
    },
    ghost: {
      default: 'text-fuchsia-900 hover:bg-fuchsia-50 border border-transparent',
      loading: 'text-fuchsia-300 bg-fuchsia-50 border border-transparent',
    },
    'green-outline': {
      default:
        'border border-green-600 text-green-600 hover:bg-green-50 shadow-sm',
      loading: 'border border-green-300 text-green-300 bg-green-50',
    },
    'red-outline': {
      default: 'border border-red-600 text-red-600 hover:bg-red-50 shadow-sm',
      loading: 'border border-red-300 text-red-300 bg-red-50',
    },
  };

  const alignmentClasses = {
    left: 'justify-start',
    center: 'justify-center',
  };

  const getButtonClasses = (): string => {
    const state = isLoading ? 'loading' : 'default';
    return `${baseClasses} ${sizeClasses[size]} ${colorClasses[color][state]} ${alignmentClasses[textAlign]} ${className} ${
      disabled || isLoading ? 'cursor-not-allowed opacity-60' : ''
    }`;
  };

  const getIconSize = () => {
    return size === 'xs' ? 'w-3 h-3' : 'w-4 h-4';
  };

  const getSpinnerSize = () => {
    return size === 'xs' ? 'w-3 h-3' : 'w-4 h-4';
  };

  const handleClick = async () => {
    if (disabled || isLoading) return;

    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  const IconComponent = (() => {
    switch (icon) {
      case 'plus':
        return PlusIcon;
      case 'document-duplicate':
        return DocumentDuplicateIcon;
      case 'chevron-left':
        return ChevronLeftIcon;
      case 'info':
        return InformationCircleIcon;
      default:
        return null;
    }
  })();

  return (
    <button
      className={getButtonClasses()}
      disabled={disabled ?? isLoading}
      onClick={handleClick}
      type="button"
    >
      {isLoading ? (
        <div
          className={`${getSpinnerSize()} border-2 border-current rounded-full animate-spin border-t-transparent`}
        />
      ) : (
        <>
          {IconComponent && (
            <IconComponent className={`${getIconSize()} flex-shrink-0`} />
          )}
          {label && (
            <span
              className={`font-medium truncate ${size === 'xs' ? 'text-xs' : 'text-sm'}`}
            >
              {label}
            </span>
          )}
          {children}
        </>
      )}
    </button>
  );
};

export default Button;
