import { Dialog } from '@headlessui/react';
import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { isNil } from 'lodash';
import { useState } from 'react';
import { ProductSelectField } from 'src/components/Fields';
import { doesSearchQueryMatch } from 'src/utils';
import { usePricingFlowContext } from '../PricingFlow';
import {
  HamsterPricingFlow,
  HamsterProduct,
  HamsterProductPrice,
  HamsterSubcategoryName,
  HAMSTER_SUBCATEGORY_NAME,
} from './hamster_types';

// ##HamsterCreateProduct
// Keep these in sync! This creates a product when selected from the product
// select modal on the client
function createHamsterProductFromPricingSheet(
  productInfo: HamsterProductPrice,
) {
  const pricingInfo = productInfo.currentPricingCurve.pricingInformation;
  return {
    id: productInfo.id,
    volume: productInfo.fixedVolume ?? pricingInfo.defaultVolume ?? 0,
    name: productInfo.name,
    rampedVolumeIncremental: [],
    quotePrice: pricingInfo.listPrice,
  };
}

const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default function HamsterProductSelectionModal(props: {
  closeModal: () => void;
}) {
  const { pricingFlow, updateFlow } =
    usePricingFlowContext<HamsterPricingFlow>();
  const { products } = pricingFlow;
  const { closeModal } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [tempSelectedProducts, setTempSelectedProducts] =
    useState<HamsterProduct[]>(products);

  return (
    <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl bg-white py-6 text-left align-middle shadow-xl transition-all">
      <Dialog.Title className="flex flex-row items-center px-6">
        <div className="rounded-lg border border-gray-300 p-2 shadow-sm">
          <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
        </div>
        <div className="ml-4">
          <p className="text-lg font-medium text-gray-900">Add Products</p>
          <p className="text-sm text-gray-600">
            Select all the products you want to add in the quote.
          </p>
        </div>
      </Dialog.Title>

      <hr className="mt-4"></hr>
      {/* Nav Bar */}
      <div className="mt-4 flex flex-col items-center justify-end px-6 sm:flex-row pb-2">
        <SearchInput onChange={setSearchQuery} />
      </div>

      {/* Grid of products */}
      <hr className="mt-2 px-6"></hr>
      <div className="h-96 overflow-scroll px-6">
        <ProductGrid
          searchQuery={searchQuery}
          allSkus={Object.values(
            pricingFlow.pricingSheetData.productInfo,
          ).filter((pp) => !isNil(pp))}
          products={tempSelectedProducts}
          setProducts={setTempSelectedProducts}
        />
      </div>

      {/* Buttons */}
      <hr className="mt-4"></hr>
      <div className="mt-6 grid grid-flow-col gap-2 border-none px-6">
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg border border-gray-300 px-4 py-2 text-base font-semibold text-gray-900 shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex items-center justify-center rounded-lg bg-fuchsia-900 px-4 py-2 text-base font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
          onClick={() => {
            updateFlow(
              { ...pricingFlow, products: tempSelectedProducts },
              false,
            );
            closeModal();
          }}
        >
          Add{' '}
          {tempSelectedProducts && tempSelectedProducts.length > 0
            ? `(${tempSelectedProducts.length})`
            : ''}
        </button>
      </div>
    </Dialog.Panel>
  );
}

function ProductGrid({
  searchQuery,
  allSkus,
  products,
  setProducts,
}: {
  allSkus: HamsterProductPrice[];
  searchQuery: string;
  products: any[];
  setProducts: (products: HamsterProduct[]) => void;
}) {
  const { pricingFlow } = usePricingFlowContext<HamsterPricingFlow>();
  const filteredProductInfos = allSkus.filter(
    (
      productPrice: HamsterProductPrice | undefined,
    ): productPrice is HamsterProductPrice => {
      return productPrice
        ? doesSearchQueryMatch(searchQuery, productPrice.name)
        : false;
    },
  );

  if (filteredProductInfos.length === 0) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <p className="text-lg font-medium text-gray-900">No products found</p>
        <p className="mt-2 text-sm text-gray-600">
          Try searching with different keywords
        </p>
      </div>
    );
  }

  function stringToHtmlId(str: string) {
    return str
      .toLowerCase() // Convert to lowercase
      .trim() // Remove leading/trailing spaces
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Remove leading/trailing hyphens
  }

  const subSectionHeader = (title: HamsterSubcategoryName) => {
    const selectorId = stringToHtmlId(title);
    return (
      <header
        className="w-full bg-slate-100 rounded mt-4 flex flex-row"
        key={`${title}_section_header`}
      >
        {(() => {
          return (
            <label
              key={`${selectorId}_label`}
              htmlFor={selectorId}
              className="flex items-center"
            >
              <span className="text-gray-500 pl-3 py-1.5 text-xs font-medium tracking-wide">
                {title.toUpperCase()}
              </span>
            </label>
          );
        })()}
      </header>
    );
  };

  return HAMSTER_SUBCATEGORY_NAME.map((subcategoryName) => {
    const skusForSubcategory = filteredProductInfos.filter(
      (pi): pi is HamsterProductPrice => {
        return (
          pi?.subcategoryMemberships.some(
            (membership) => membership.subcategory.name === subcategoryName,
          ) ?? false
        );
      },
    );
    if (skusForSubcategory.length === 0) {
      return null;
    }
    return (
      <>
        {subSectionHeader(subcategoryName)}
        <div
          className="mt-4 grid auto-rows-fr grid-cols-2 gap-2 sm:grid-cols-4"
          key={`${subcategoryName}_products`}
        >
          {skusForSubcategory
            .sort((a, b) => {
              return a.displayOrder - b.displayOrder;
            })
            .map((productInfo: HamsterProductPrice) => {
              const selected =
                products.find(
                  (product) => product.name === productInfo.name,
                ) !== undefined;
              return (
                <ProductSelectField
                  className="h-full"
                  key={`${productInfo.id}`}
                  name={productInfo.name}
                  id={`${productInfo.id}`}
                  onChange={(id: string) => {
                    // if the product was previously selected and we're changing
                    // its state, remove it from the set of selected products
                    if (selected) {
                      setProducts(
                        products.filter((product) => product.id !== id),
                      );
                    } else {
                      setProducts([
                        // remove any products in the same exclusive group
                        ...products.filter((p) => {
                          return isNil(productInfo.exclusiveGroup)
                            ? true
                            : pricingFlow.pricingSheetData.productInfo[p.id]
                                ?.exclusiveGroup !== productInfo.exclusiveGroup;
                        }),
                        // add the product
                        createHamsterProductFromPricingSheet(productInfo),
                      ]);
                    }
                  }}
                  checked={selected}
                />
              );
            })}
        </div>
      </>
    );
  });
}
