import { datadogRum } from '@datadog/browser-rum';
import { useState } from 'react';
import Button from 'src/components/Button';
import { Organization, User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { classNames } from '../App';
import { Opportunity } from '../Opportunity/types';
import {
  ActivitySection,
  SystemActivity,
} from '../PricingFlow/Activity/ActivitySection';
import { PricingFlowPage } from '../PricingFlow/PricingFlow';
import { PricingFlow } from '../PricingFlow/types';

export default function MultipleQuoteSlideout(props: {
  opportunity: Opportunity;
  pricingFlows: PricingFlow[];
  currentPricingFlow: string;
  user: User;
  organization: Organization;
}) {
  const { opportunity, pricingFlows, user, organization } = props;
  const [currentPricingFlowId, setCurrentPricingFlowId] = useState<string>(
    props.currentPricingFlow,
  );
  const currentPricingFlow = pricingFlows.find(
    (pf) => pf.id === currentPricingFlowId,
  );
  if (!currentPricingFlow) {
    datadogRum.addError(
      'Unreachable, currentPricingFlowId not found in pricingFlows',
    );
    return;
  }

  return (
    <div className="w-[94vw]">
      <div className="flex flex-col xl:flex-row w-full">
        <Tabs
          currentPricingFlow={currentPricingFlowId}
          pricingFlows={pricingFlows}
          opportunity={opportunity}
          setCurrentPricingFlowId={setCurrentPricingFlowId}
          className="flex-shrink-0"
        />
        <div className="flex flex-col lg:flex-row flex-1 overflow-hidden">
          <div className="w-full flex-shrink-0 border-r border-gray-200">
            <div className="w-full lg:w-[111.11%] transform lg:scale-90 lg:origin-top-left">
              <PricingFlowPage
                pricingFlowOrSnapshotId={currentPricingFlowId}
                modelType={'pricingFlow'}
                user={user}
                organization={organization}
                viewMode="group"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function Tabs(props: {
  currentPricingFlow: string;
  pricingFlows: PricingFlow[];
  opportunity: Opportunity;
  setCurrentPricingFlowId: (pricingFlowId: string) => void;
  className?: string;
}) {
  const {
    currentPricingFlow,
    pricingFlows,
    opportunity,
    setCurrentPricingFlowId,
    className,
  } = props;
  return (
    <div
      className={classNames(
        'flex flex-col space-y-2 p-2 border-r border-gray-200 w-[140px]',
        className,
      )}
    >
      {pricingFlows.map((pricingFlow) => {
        return (
          <Button
            color={currentPricingFlow === pricingFlow.id ? 'primary' : 'noBg'}
            label={getNameForPricingFlow(pricingFlow, opportunity.pricingFlows)}
            key={pricingFlow.id}
            onClick={() => setCurrentPricingFlowId(pricingFlow.id)}
            textAlign="left"
          />
        );
      })}
    </div>
  );
}

/**
 * @todo(fayhamster)
 * This component is not ready yet
 */
function PricingFlowActivity({
  pricingFlow,
  user,
}: {
  pricingFlow: PricingFlow;
  user: User;
}) {
  const systemActivities: SystemActivity[] = [
    // {
    //   type: 'system',
    //   text: 'created this flow',
    //   user: pricingFlow.createdByUserId,
    //   timestamp: pricingFlow.createdAt,
    // },
  ];

  return (
    <ActivitySection
      parentType="pricingFlow"
      parentId={pricingFlow.id}
      systemActivities={systemActivities}
      user={user}
    />
  );
}
