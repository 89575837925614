import dayjs from 'dayjs';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import api from 'src/api';
import Button from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { User } from 'src/types';
import { AddComment } from '../../PricingFlow/Activity/AddComment';

export interface CommentThread {
  id: string;
  createdAt: string;
  createdByUser: User;
  resolvedAt: string | null;
  Comment: Comment[];
  parentTag?: string;
}

export interface Comment {
  id: string;
  createdAt: string;
  updatedAt: string;
  createdByUser: User;
  comment: string;
}

export interface SystemActivity {
  type: 'system';
  text: React.ReactNode;
  user: User;
  timestamp: string;
  parentTag?: string;
}

export type Activity = SystemActivity | CommentThread;

export interface ActivitySectionProps {
  parentType: 'pricingFlowGroup' | 'pricingFlow';
  parentId: string;
  systemActivities: Activity[];
  user: User;
}

export function ActivitySection({
  parentType,
  parentId,
  systemActivities,
  user,
}: ActivitySectionProps) {
  const [activities, setActivities] = useState<Activity[] | null>(null);

  useEffect(() => {
    loadActivities();
  }, [parentId, systemActivities]);

  async function loadActivities() {
    const commentThreads = await api.get(
      `comments/threads/${parentType}/${parentId}`,
    );

    // Merge and sort activities by timestamp/createdAt
    const allActivities = [...systemActivities, ...commentThreads.data].sort(
      (a, b) => {
        const dateA = isSystemActivity(a) ? a.timestamp : a.createdAt;
        const dateB = isSystemActivity(b) ? b.timestamp : b.createdAt;
        return new Date(dateB).getTime() - new Date(dateA).getTime();
      },
    );

    setActivities(allActivities);
  }
  console.log('activities: ', activities);

  function isSystemActivity(activity: Activity): activity is SystemActivity {
    return 'type' in activity && activity.type === 'system';
  }

  async function addComment(comment: string) {
    if (isNil(activities)) {
      return;
    }

    // Create a new comment thread
    const result = await api.post('comments', {
      [`${parentType}Id`]: parentId,
      comment,
    });

    const newThread = {
      ...result.data.thread,
      Comment: [result.data.initialComment],
      createdByUser: user,
    };

    setActivities([newThread, ...activities]);
  }

  async function addReply(threadId: string, comment: string) {
    if (isNil(activities)) {
      return;
    }

    const reply = await api.post(`comments/${threadId}/replies`, {
      comment,
    });

    setActivities(
      activities.map((activity) => {
        if ('type' in activity || activity.id !== threadId) {
          return activity;
        }
        return {
          ...activity,
          Comment: [
            ...activity.Comment,
            { ...reply.data, createdByUser: user },
          ],
        };
      }),
    );
  }

  async function resolveThread(threadId: string) {
    if (isNil(activities)) {
      return;
    }

    await api.post(`comments/${threadId}/resolve`, {});

    setActivities(
      activities.map((activity) => {
        if ('type' in activity || activity.id !== threadId) {
          return activity;
        }
        return {
          ...activity,
          resolvedAt: new Date().toISOString(),
        };
      }),
    );
  }

  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white border border-slate-200 w-full">
      <div className="px-4 py-4 flex justify-between items-center font-medium">
        <span className="text-xs text-gray-500 uppercase">Activity</span>
      </div>

      <div className="flex flex-col min-h-64 max-h-[460px]">
        {' '}
        {/* Fixed height container */}
        <div className="flex-1 overflow-y-auto pt-2 space-y-2">
          {activities === null && <InlineSpinner />}
          {!isNil(activities) && activities.length === 0 && (
            <div className="px-4 py-5 flex-grow space-y-5 justify-center flex">
              <div className="text-sm text-gray-500">
                No activity to display
              </div>
            </div>
          )}
          {!isNil(activities) &&
            activities.map((activity) => {
              if (isSystemActivity(activity)) {
                // @TODO(fay) there is an issue with keys
                return (
                  <SystemActivity
                    key={`${activity.timestamp}-${activity.text}`}
                    activity={activity}
                  />
                );
              }
              return (
                <CommentThreadComponent
                  key={activity.id}
                  thread={activity}
                  onReply={(comment) => addReply(activity.id, comment)}
                  onResolve={() => resolveThread(activity.id)}
                />
              );
            })}
        </div>
        <div className="border-t border-gray-200 bg-white rounded-b-lg">
          {' '}
          {/* Sticky footer */}
          <AddComment addComment={addComment} />
        </div>
      </div>
    </div>
  );
}

function ParentTagBadge({ parentTag }: { parentTag?: string }) {
  if (!parentTag) return null;

  return (
    <span className="inline-flex items-center rounded bg-slate-100 px-2 py-0.5 text-xs font-medium text-slate-600 mr-2">
      {parentTag}
    </span>
  );
}

function SystemActivity({ activity }: { activity: SystemActivity }) {
  return (
    <div className="px-4 py-1.5 flex-grow justify-left flex items-center">
      <ParentTagBadge parentTag={activity.parentTag} />
      <div className="text-sm text-gray-900">
        <span className="font-medium">{activity.user.name}</span>{' '}
        {activity.text}{' '}
        <span className="text-gray-500">
          {dayjs(activity.timestamp).fromNow()}
        </span>
      </div>
    </div>
  );
}

function CommentThreadComponent({
  thread,
  onReply,
  onResolve,
}: {
  thread: CommentThread;
  onReply: (comment: string) => Promise<void>;
  onResolve: () => Promise<void>;
}) {
  const [isReplying, setIsReplying] = useState(false);
  const [isExpanded, setIsExpanded] = useState(!thread.resolvedAt);
  const mainComment = thread.Comment[0];
  const replies = thread.Comment.slice(1);

  if (thread.resolvedAt && !isExpanded) {
    return (
      <div
        className="px-3 py-1.5 flex-grow flex items-center text-sm text-gray-500 hover:bg-gray-50 cursor-pointer group"
        onClick={() => setIsExpanded(true)}
      >
        <ParentTagBadge parentTag={thread.parentTag} />
        <div className="flex items-center gap-1.5">
          <svg className="w-3 h-3" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
              clipRule="evenodd"
            />
          </svg>
          <span>
            Resolved comment from {mainComment.createdByUser.name} (
            {dayjs(thread.resolvedAt).fromNow()})
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="px-4 py-1.5 flex-grow flex flex-col">
      <div className="flex justify-between items-start gap-2">
        <div className="text-sm text-gray-900 flex items-center">
          <ParentTagBadge parentTag={thread.parentTag} />
          <span className="font-medium">{mainComment.createdByUser.name}</span>
          <span className="text-gray-500 ml-2">
            {dayjs(mainComment.createdAt).fromNow()}
          </span>
        </div>
        <div className="flex items-center gap-2">
          {thread.resolvedAt && (
            <Button
              color="ghost"
              size="xs"
              onClick={() => setIsExpanded(false)}
            >
              Collapse
            </Button>
          )}
          {!thread.resolvedAt && (
            <Button color="ghost" size="xs" onClick={onResolve}>
              Resolve
            </Button>
          )}
        </div>
      </div>
      <div className="text-sm text-gray-900 border border-slate-200 rounded-md p-2 mt-1.5 ml-3">
        {mainComment.comment}
      </div>

      {/* Nested replies */}
      {replies.length > 0 && (
        <div className="ml-6 mt-1.5 space-y-1.5">
          {replies.map((reply) => (
            <div key={reply.id} className="border-l-2 border-slate-200 pl-3">
              <div className="text-sm text-gray-900">
                {reply.createdByUser.name}{' '}
                <span className="text-gray-500">
                  {dayjs(reply.createdAt).fromNow()}
                </span>
              </div>
              <div className="text-sm text-gray-900 mt-0.5">
                {reply.comment}
              </div>
            </div>
          ))}
        </div>
      )}

      {/* Reply UI */}
      {!thread.resolvedAt && (
        <div className="ml-6 mt-1.5">
          {!isReplying && (
            <Button color="ghost" size="xs" onClick={() => setIsReplying(true)}>
              Reply
            </Button>
          )}
          {isReplying && (
            <AddComment
              addComment={async (comment) => {
                await onReply(comment);
                setIsReplying(false);
              }}
              onCancel={() => setIsReplying(false)}
              threadId={thread.id}
            />
          )}
        </div>
      )}
    </div>
  );
}
