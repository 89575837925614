import { Crepe } from '@milkdown/crepe';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import Badge from 'src/components/Badge';
import Button from 'src/components/Button';
import CollapsibleSection from 'src/components/CollapsibleSection';
import { TextField } from 'src/components/Fields';
import { useToast } from 'src/components/Toast';
import { getOpportunityContextTemplate } from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { Opportunity, PricingFlowGroup } from 'src/dashboard/Opportunity/types';
import { User } from 'src/types';
import PricingFlowList, { getLightVariant } from '../PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../QuoteOptionsSection';
import { OpportunityCommon } from '../types';
import OpportunityContextEditor from './ApprovalRequestForm';

interface ApprovalBadgeProps {
  level: string;
}
// @TODO(fay) let's consolidate
export function ApprovalBadge(props: ApprovalBadgeProps) {
  let stepColor = '';
  let stepName = '';
  switch (props.level) {
    case 'Head of Mid-Market':
      stepColor = '#92400e';
      stepName = 'Head of Mid-Market';
      break;
    case 'VP of Sales':
      stepColor = '#9a3412';
      stepName = 'VP of Sales';
      break;
    case 'Deal Desk':
      stepColor = '#991b1b';
      stepName = 'Deal Desk';
      break;
    case 'Legal':
      stepColor = '#9f1239';
      stepName = 'Deal Ops';
      break;
    case 'Deal Ops':
      stepColor = '#9f1239';
      stepName = 'Deal Ops';
      break;
    default:
      return;
  }
  return (
    <Badge
      color="gray"
      style={{
        color: stepColor,
        background: getLightVariant(stepColor),
      }}
    >
      {stepName}
    </Badge>
  );
}

interface AskForApprovalModalProps {
  hideModal: () => void;
  pricingFlows: PricingFlowOrSnapshotForNavigation[];
  user: User;
  group?: PricingFlowGroup;
  opportunity: OpportunityCommon;
}
export default function AskForApprovalModal(props: AskForApprovalModalProps) {
  const { hideModal, user, group, pricingFlows, opportunity } = props;
  const [opportunityContextCrepe, setOpportunityContextCrepe] =
    useState<Crepe | null>(null);
  const [quoteContextCrepes, setQuoteContextCrepes] = useState<
    Record<string, Crepe | null>
  >({});
  const [groupName, setGroupName] = useState<string>(
    group?.name ?? 'New group',
  );

  const navigate = useNavigate();
  const { showToast } = useToast();
  async function submit() {
    // update opportunity context
    // get latest message
    const opportunityContextMarkdown = opportunityContextCrepe
      ?.getMarkdown()
      .trim();
    if (!opportunityContextMarkdown) {
      // @TODO(fay) check what's empty
      showToast({
        title: 'Error',
        subtitle: 'Please provide a context message',
        type: 'error',
      });
      return;
    }
    await api.put('opportunities/context/' + opportunity.id, {
      context: opportunityContextMarkdown,
    });

    // update each pricing flow's contexts
    await Promise.all(
      Object.entries(quoteContextCrepes).map(async ([id, crepe]) => {
        const markdown = crepe?.getMarkdown().trim();
        if (markdown) {
          // @TODO(fay): check if this is the right condition
          await api.post('pricingFlows/' + id + '/update-context', {
            context: markdown,
          });
        }
      }),
    );

    if (group) {
      // update existing group name + options if needed
      await api.put('pricingFlowGroups/' + group.id, {
        name: groupName,
        pricingFlowIds: pricingFlows.map((pf) => pf.id),
      });
      // submit group for approval
      await api.post('approvals/requests', {
        groupId: group.id,
      });
      // redirect to the group
      navigate(
        `/app/opportunity/${opportunity.sfdcOpportunityId}/group/${group.id}`,
      );
    } else if (pricingFlows.length > 1) {
      // create new group with name and options
      const newGroup = await api.post('pricingFlowGroups', {
        name: groupName,
        pricingFlowIds: pricingFlows.map((pf) => pf.id),
        opportunityId: opportunity.id,
      });
      // submit group for approval
      await api.post('approvals/requests', {
        groupId: newGroup.data.id,
      });
      // redirect to the group
      navigate(
        `/app/opportunity/${opportunity.sfdcOpportunityId}/group/${newGroup.data.id}`,
      );
    } else {
      // submit individual
      await api.post('approvals/requests', {
        pricingFlowId: pricingFlows[0].id,
      });
      // redirect to the opportunity page
      navigate(`/app/opportunity/${opportunity.sfdcOpportunityId}`);
    }
    hideModal();
  }
  return (
    <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
      {/* Fixed TextField */}
      {(group ?? pricingFlows.length > 1) && (
        <div className="p-4">
          <TextField
            label="Group name"
            type="text"
            name={'Group name'}
            value={groupName}
            onChange={(e) => setGroupName(e.currentTarget.value)}
            newStyle
          />
        </div>
      )}
      {/* Scrollable Section */}
      <div className="px-4 pb-4 flex flex-col overflow-hidden flex-1">
        <div className="overflow-y-auto flex-1 space-y-2">
          <CollapsibleSection title={'QUOTES'}>
            <div className="space-y-2">
              <PricingFlowList
                pricingFlows={pricingFlows}
                modelType="pricingFlow"
                user={user}
                hideUserActionButtons
                displayApprovalFlowForDraft
                hasApprovals // You obviously have approvals since you're submitting this
                listItemClassName="border border-gray-200 rounded-lg bg-white p-4"
                purpose="ApprovalModal"
                crepes={quoteContextCrepes}
                setCrepes={setQuoteContextCrepes}
                contextEditableMode="edit-only"
              />
            </div>
          </CollapsibleSection>

          <CollapsibleSection title="OPPORTUNITY CONTEXT" className="h-full">
            <div className="px-2 text-sm text-slate-900">
              {' '}
              Update the context for the opportunity{' '}
            </div>
            <OpportunityContextEditor
              initialMessage={
                opportunity.context ??
                getOpportunityContextTemplate({
                  opportunity: opportunity as Opportunity,
                }) // @TODO opportunity is not type split correctly
              }
              crepe={opportunityContextCrepe}
              setCrepe={setOpportunityContextCrepe}
              opportunity={opportunity}
            />
          </CollapsibleSection>
        </div>
      </div>
      {/* Footer */}
      <div className="mt-4 shrink-0 border-t border-100">
        <div className="flex flex-row justify-between p-4 gap-4 sm:flex-row-reverse">
          <Button
            color="primary"
            onClick={submit}
            className="flex-1"
            label="Submit"
          />
          <Button
            color="white"
            onClick={() => hideModal()}
            className="flex-1"
            label="Cancel"
          />
        </div>
      </div>
    </div>
  );
}
