/* eslint-disable rulesdir/require-datadog-rum-error, @typescript-eslint/prefer-nullish-coalescing */

import {
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
} from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import api from 'src/api';
import { InlineSpinner } from 'src/components/Loading';

interface SlackUserProfile {
  display_name?: string;
  real_name?: string;
  image_72?: string;
}

interface SlackUser {
  id: string;
  name: string;
  real_name?: string;
  profile?: SlackUserProfile;
}

interface SlackChannel {
  id: string;
  name: string;
}

interface SlackReaction {
  name: string;
  count: number;
  users: string[];
}

interface SlackMessage {
  client_msg_id?: string;
  type: string;
  text: string;
  user: string;
  user_info: SlackUser | null;
  channel_info: SlackChannel;
  ts: string;
  team?: string;
  thread_ts?: string;
  parent_user_id?: string;
  reactions?: SlackReaction[];
}

interface SlackThreadResponse {
  ok: boolean;
  messages: SlackMessage[];
  channel_info: SlackChannel;
  has_more?: boolean;
  response_metadata?: {
    next_cursor?: string;
  };
}

interface SlackThreadsDisplayProps {
  groupId: string;
}

const SlackThreadsDisplay: React.FC<SlackThreadsDisplayProps> = ({
  groupId,
}) => {
  const [threads, setThreads] = useState<SlackThreadResponse[] | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [expandedThreads, setExpandedThreads] = useState<Set<string>>(
    new Set(),
  );

  useEffect(() => {
    fetchSlackThreads();
  }, [groupId]);

  const fetchSlackThreads = async (): Promise<void> => {
    try {
      const response = await api.get(`comments/temp-slack/${groupId}`);
      const data: SlackThreadResponse[] = await response.data;
      setThreads(data);
      setError(null);
    } catch (err) {
      console.error('Error fetching Slack threads:', err);
      setError('Failed to load Slack messages');
      setThreads(null);
    }
  };

  const getUserDisplayName = (message: SlackMessage): string => {
    if (message.user_info) {
      return (
        message.user_info.profile?.display_name ||
        message.user_info.profile?.real_name ||
        message.user_info.real_name ||
        message.user_info.name
      );
    }
    return 'Unknown User';
  };

  const formatDate = (ts: string): string => {
    const date = new Date(Number(ts.split('.')[0]) * 1000);
    return new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    }).format(date);
  };

  const formatMessageText = (text: string): string => {
    // Handle user mentions - now we could potentially look up real names
    text = text.replace(/<@([A-Z0-9]+)>/g, '@user');
    // Handle channel mentions
    text = text.replace(/<#([A-Z0-9]+)\|([^>]+)>/g, '#$2');
    // Handle links
    text = text.replace(/<(https?:[^|>]+)\|([^>]+)>/g, '$2');
    text = text.replace(/<(https?:[^>]+)>/g, '$1');
    return text;
  };

  if (error) {
    return (
      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <div className="text-red-500 text-sm">{error}</div>
      </div>
    );
  }

  if (threads === null) {
    return (
      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <InlineSpinner />
      </div>
    );
  }

  if (threads.length === 0) {
    return (
      <div className="bg-white rounded-lg border border-gray-200 p-4">
        <div className="text-gray-500 text-sm">No Slack threads available</div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
      <div className="border-b border-gray-200 bg-gray-50 px-4 py-3">
        <h3 className="text-sm font-medium text-gray-900 flex items-center gap-2">
          <ChatBubbleLeftRightIcon className="w-4 h-4" />
          Slack Threads
        </h3>
      </div>

      <div className="divide-y divide-gray-200">
        {threads.map((threadResponse) => {
          if (
            !threadResponse?.messages ||
            threadResponse.messages.length === 0
          ) {
            return null;
          }

          const parentMessage = threadResponse.messages[0];
          const replies = threadResponse.messages.slice(1);
          const threadTs = parentMessage.ts;
          const isExpanded = expandedThreads.has(threadTs);

          return (
            <div key={threadTs} className="bg-white">
              {/* Parent message */}
              <div
                className="px-4 py-3 hover:bg-gray-50 cursor-pointer"
                onClick={() =>
                  setExpandedThreads((prev) => {
                    const newSet = new Set(prev);
                    if (newSet.has(threadTs)) {
                      newSet.delete(threadTs);
                    } else {
                      newSet.add(threadTs);
                    }
                    return newSet;
                  })
                }
              >
                <div className="flex justify-between items-start">
                  <div className="min-w-0 flex-1">
                    <div className="flex items-center gap-2 text-sm">
                      <span className="font-medium text-gray-900">
                        {getUserDisplayName(parentMessage)}
                      </span>
                      <span className="text-gray-500 text-xs">
                        in #{threadResponse.channel_info.name}
                      </span>
                      <span className="text-gray-500 text-xs">
                        {formatDate(parentMessage.ts)}
                      </span>
                    </div>
                    <div className="mt-1.5 text-sm text-gray-900">
                      {formatMessageText(parentMessage.text)}
                    </div>
                    {!isExpanded && replies.length > 0 && (
                      <div className="mt-2 text-xs text-gray-500">
                        {replies.length}{' '}
                        {replies.length === 1 ? 'reply' : 'replies'}
                      </div>
                    )}
                  </div>
                  <div className="ml-4">
                    <ChevronDownIcon
                      className={`w-5 h-5 text-gray-400 transform transition-transform ${
                        isExpanded ? 'rotate-180' : ''
                      }`}
                    />
                  </div>
                </div>
              </div>

              {/* Reply messages */}
              {isExpanded && replies.length > 0 && (
                <div className="bg-gray-50 px-4 py-3 space-y-3">
                  {replies.map((reply) => (
                    <div
                      key={reply.ts}
                      className="pl-4 border-l-2 border-gray-200"
                    >
                      <div className="flex items-center gap-2 text-sm">
                        <span className="font-medium text-gray-900">
                          {getUserDisplayName(reply)}
                        </span>
                        <span className="text-gray-500 text-xs">
                          {formatDate(reply.ts)}
                        </span>
                      </div>
                      <div className="mt-1 text-sm text-gray-900">
                        {formatMessageText(reply.text)}
                      </div>
                      {reply.reactions && reply.reactions.length > 0 && (
                        <div className="mt-2 flex flex-wrap gap-1">
                          {reply.reactions.map((reaction) => (
                            <span
                              key={reaction.name}
                              className="inline-flex items-center rounded-full bg-gray-100 px-2 py-0.5 text-xs text-gray-800"
                              title={`${reaction.count} ${
                                reaction.count === 1 ? 'reaction' : 'reactions'
                              }`}
                            >
                              {reaction.name} {reaction.count}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SlackThreadsDisplay;
