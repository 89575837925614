import {
  CubeTransparentIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import _, { isNil } from 'lodash';
import { useState } from 'react';
import { ProductSelectField } from 'src/components/Fields';
import { User } from '../../../../types';
import StepDetailsTopbar from '../Components/StepDetailsTopbar';

import { datadogRum } from '@datadog/browser-rum';
import { useToast } from 'src/components/Toast';
import { classNames } from 'src/dashboard/App';
import { doesSearchQueryMatch } from 'src/utils';
import { formatCurrencyValue } from '../../Alpaca/alpaca_utils';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowStage } from '../../types';
import { CurrencyValueFlat, CurrencyValueType } from '../../types_common/price';
import BottomBar from '../Components/BottomBar';
import OpportunityOverviewBar from '../Components/OpportunityOverviewBar';
import { PenguinFlowSteps } from '../PenguinPricingFlowPage';
import {
  PenguinCategories,
  PenguinCustomStageType,
  PenguinPricingFlow,
  PenguinProduct,
  PenguinProductPrice,
  PenguinProductPrices,
} from '../penguin_types';
const ALL_CATEGORIES_LABEL = 'All';

// START TRANSFER RISK ENGINE ADD-ON CONSTANTS

/**
 * This is a list of the parentIds for the vanilla originator and high risk transfer products
 * These are used to power the add-ons display logic. It's a bit of a hack, but it works.
 * More details here: https://www.notion.so/dealops/Transfer-Risk-Engine-as-add-on-2437c66b867a4419a6dca56b44141eb6?pvs=4#6d03e0f6911045b5a300c61511a7001b
 */

const vanillaOriginatorParentNames = [
  'Transfer (Standard ACH) - Vanilla Originators - bps',
  'Transfer (Standard ACH) - Vanilla Originators - Fixed',
  'Transfer (Same-day ACH) - Vanilla Originators - bps',
  'Transfer (Same-day ACH) - Vanilla Originators - Fixed',
];

const highRiskParentNames = [
  'Transfer (Standard ACH) - High Risk Customers - bps',
  'Transfer (Standard ACH) - High Risk Customers - Fixed',
  'Transfer (Same-day ACH) - High Risk Customers - bps',
  'Transfer (Same-day ACH) - High Risk Customers - Fixed',
];

const transferRiskEngineVanillaAddOnNames = [
  'Transfer Risk Engine - Vanilla Originators',
];
const transferRiskEngineHighRiskAddOnNames = [
  'Transfer Risk Engine - High Risk Customers',
];

// END TRANSFER RISK ENGINE ADD-ON CONSTANTS

export const updatePricingFlowWithProducts = (params: {
  products: PenguinProduct[];
  pricingFlow: PenguinPricingFlow;
  updateFlow: (pricingFlow: PenguinPricingFlow, showLoading?: boolean) => void;
}) => {
  const { products, pricingFlow, updateFlow } = params;
  // Check if products includes a product with skuGroup = 'Support'
  const pricingSheetData = pricingFlow.pricingSheetData;
  const productPrice = pricingSheetData.productInfo;
  const hasSupportPackage = products.some((product) => {
    return productPrice[product.id]?.skuGroup === 'Support';
  });

  // ##RealDefaultSupportPackageTimezone
  // We set the default support package timezone to PST
  const supportPackageTimezone = hasSupportPackage
    ? (pricingFlow.additionalData?.supportPackageTimezone ?? 'PST')
    : null;

  updateFlow(
    {
      ...pricingFlow,
      products: products,
      additionalData: {
        ...pricingFlow.additionalData,
        supportPackageTimezone,
      },
    },
    false,
  );
};
export default function Step2ProductSelection(props: {
  validateStep: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
  user: User;
  previousStage: () => void;
}) {
  const { previousStage, validateStep } = props;
  const { pricingFlow, updateFlow, editMode, setStage } =
    usePricingFlowContext<PenguinPricingFlow>();
  const { showToast } = useToast();

  const products = (pricingFlow?.products as PenguinProduct[]) ?? [];

  const setProducts = (products: PenguinProduct[]) => {
    updatePricingFlowWithProducts({ products, updateFlow, pricingFlow });
  };

  const setSupportPackageTimezone = (timezone: string | null) => {
    updateFlow(
      {
        ...pricingFlow,
        additionalData: {
          ...pricingFlow.additionalData,
          supportPackageTimezone: timezone,
        },
      },
      false,
    );
  };
  const pricingSheetData = pricingFlow.pricingSheetData;

  const submit = async () => {
    const { value, error } = validateStep({
      ...pricingFlow,
      products: products,
    });
    if (!value) {
      if (error) {
        showToast({
          title: error,
          subtitle: '',
          type: 'error',
        });
      }
      return;
    }

    if (!editMode) {
      setStage({
        stage: PricingFlowStage.CALCULATE_PRICE,
        customStage:
          PenguinFlowSteps[
            PenguinFlowSteps.indexOf(PenguinCustomStageType.VOLUME_INPUTS)
          ],
      });
      return;
    }
    updateFlow({
      ...pricingFlow,
      products: products,
      stage: PricingFlowStage.CALCULATE_PRICE,
      additionalData: {
        ...pricingFlow.additionalData,
        customStage:
          PenguinFlowSteps[
            PenguinFlowSteps.indexOf(PenguinCustomStageType.VOLUME_INPUTS)
          ],
      },
    });
  };

  return (
    <div className="flex w-full grow flex-col overflow-hidden">
      <div className="flex h-full w-full grow flex-row">
        <OpportunityOverviewBar
          opportunityData={pricingFlow.opportunity.opportunityData}
        />
        <ProductSelectionForm
          products={products}
          productPrices={pricingSheetData.productInfo}
          setProducts={setProducts}
          setSupportPackageTimezone={setSupportPackageTimezone}
          supportPackageTimezone={
            pricingFlow.additionalData?.supportPackageTimezone
          }
        />
      </div>
      <BottomBar
        primaryButtonProps={{ label: 'Next', onClick: submit }}
        secondaryButtonProps={{
          label: 'Back',
          onClick: async () => previousStage(),
        }}
        helpText={
          products.length +
          ' product' +
          (products.length === 1 ? '' : 's') +
          ' selected'
        }
      />
    </div>
  );
}

const Tab = ({
  name,
  selected,
  onClick,
  count,
}: {
  name: string;
  selected: boolean;
  onClick: (tab: string) => void;
  count?: number;
}) => {
  return (
    <button
      name={name}
      onClick={() => {
        onClick(name);
      }}
    >
      <div
        className={classNames(
          'flex w-fit rounded-md px-3 py-2 text-sm font-semibold',
          selected
            ? 'bg-fuchsia-100 text-fuchsia-950'
            : 'text-fuchsia-800 hover:bg-fuchsia-50 hover:text-fuchsia-950',
        )}
      >
        {name}
        {!!count && name !== ALL_CATEGORIES_LABEL && (
          <span
            className={classNames(
              selected ? 'bg-fuchsia-100' : 'bg-fuchsia-50',
              'ml-2 inline-flex h-4 items-center justify-center self-center rounded-full px-2 py-2.5 text-xs font-semibold text-fuchsia-950',
            )}
          >
            {count}
          </span>
        )}
      </div>
    </button>
  );
};

function getSkuGroups(skus: PenguinProductPrices): string[] {
  const hardcodedFirstSkuGroups = [
    // expected skuGroups for penguin
    'Core & Assets',
    'IDV',
    'Credits & Promotions',
    'Payments',
    'Income',
    'Transfers',
    'Signal',
    'Enrich',
    'CRA Products',
    'Hosted Link',
    'Pilot Products',
    // expected skuGroups for complex_demo
    'Authentication and Security',
    'Payment Processing',
    'AI and Data Services',
    'Developer Tools and Support',
    'Analytics and Reporting',
    'Partnerships',
  ];
  const hardcodedLastSkuGroups = [
    'Support',
    'Implementation Services',
    'TODO_SKUGROUP',
  ];

  // We look to see if there are any Sku groups that were not captured in the hardcoded lists
  const skuGroups = new Set();
  if (skus == null) {
    return [];
  }
  Object.values(skus).forEach((sku) => {
    if (
      !isNil(sku) &&
      !hardcodedFirstSkuGroups.includes(sku.skuGroup) &&
      !hardcodedLastSkuGroups.includes(sku.skuGroup)
    ) {
      skuGroups.add(sku.skuGroup);
    }
  });
  const unknownSkuGroups = Array.from(skuGroups) as string[];
  if (skuGroups.size !== 0) {
    // This is unexpected – send an error to Datadog but don't throw an error to the user
    console.error('found unknown sku groups: ', unknownSkuGroups);
    datadogRum.addError(
      new Error(
        `found unknown sku groups: ${JSON.stringify(unknownSkuGroups)}`,
      ),
    );
  }

  return [
    ...hardcodedFirstSkuGroups,
    ...unknownSkuGroups,
    ...hardcodedLastSkuGroups,
  ];
}

const ProductSelectionForm = (props: {
  products: PenguinProduct[];
  productPrices: PenguinProductPrices;
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
}) => {
  const {
    products,
    productPrices,
    setProducts,
    setSupportPackageTimezone,
    supportPackageTimezone,
  } = props;
  const { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  const additionalData = pricingFlow?.additionalData;

  const pricingSheetData = pricingFlow.pricingSheetData;
  const allCategories = pricingSheetData.categories;

  let chosenCategories: { name: string; id: string }[] = [
    ...(additionalData?.productCategories?.flatMap((id) => {
      const category = allCategories[id];
      if (!isNil(category)) {
        return [
          {
            name: allCategories[id].name,
            id: id,
          },
        ];
      } else {
        return [];
      }
    }) ?? []),
    { name: ALL_CATEGORIES_LABEL, id: ALL_CATEGORIES_LABEL },
  ];

  const [currentTab, setCurrentTab] = useState<string>(
    chosenCategories[0].name,
  );
  const [searchQuery, setSearchQuery] = useState('');

  // Map each category to the # of selected products in that category.
  let productCountPerCategory: { [category: string]: number } = {};
  chosenCategories.forEach((chosenCategory) => {
    let count = 0;
    if (chosenCategory.name === ALL_CATEGORIES_LABEL) {
      count = Object.keys(products).length;
    } else {
      const category = allCategories[chosenCategory.id];
      if (category !== undefined) {
        count = Object.values(category.subcategories)
          .map((subGroup) => {
            const parentProductCount = products.filter((product) => {
              return subGroup.products.includes(product.id);
            }).length;

            const addOnsCount = Object.values(productPrices).filter(
              (productPrice) =>
                !isNil(productPrice) &&
                !isNil(productPrice.parentProduct) &&
                products.some((product) => product.id === productPrice.id) &&
                typeof productPrice.parentProductId === 'string' &&
                subGroup.products.includes(productPrice.parentProductId),
            ).length;

            return parentProductCount + addOnsCount;
          })
          .reduce((acc, val) => acc + val, 0);
      }
    }
    productCountPerCategory[chosenCategory.id] = count;
  });

  return (
    <div className="w-full transform overflow-auto rounded-2xl bg-white px-6 pb-28 text-left align-middle transition-all">
      <StepDetailsTopbar
        stepName="Add Products"
        stepDescription="Select the products you want to include in your quote."
        stepIcon={
          <CubeTransparentIcon className="h-6 w-6" aria-hidden="true" />
        }
      />
      {/* Nav Bar */}
      <div className="my-4 flex flex-col items-center justify-between sm:flex-row">
        <div className="flex gap-2 sm:mb-0">
          {chosenCategories.map((category) => (
            <Tab
              key={category.id}
              name={category.name}
              selected={category.name === currentTab}
              onClick={setCurrentTab}
              count={productCountPerCategory[category.id]}
            />
          ))}
        </div>
        <SearchInput onChange={setSearchQuery} />
      </div>

      <hr className="mt-2 px-6"></hr>
      <ProductGrid
        productGroups={allCategories}
        currentTab={currentTab}
        searchQuery={searchQuery}
        productPrices={productPrices}
        products={products}
        setProducts={setProducts}
        setSupportPackageTimezone={setSupportPackageTimezone}
        supportPackageTimezone={supportPackageTimezone}
        // @ts-ignore
        isComplexDemo={pricingFlow.type === 'COMPLEX_DEMO'}
      />
    </div>
  );
};

const ProductGrid = ({
  productGroups,
  currentTab,
  searchQuery,
  productPrices,
  products,
  setProducts,
  setSupportPackageTimezone,
  supportPackageTimezone,
  isComplexDemo = false,
}: {
  productGroups: PenguinCategories;
  currentTab: string;
  searchQuery: string;
  productPrices: PenguinProductPrices;
  products: PenguinProduct[];
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
  isComplexDemo?: boolean;
}) => {
  const filteredProductPrices: PenguinProductPrices = Object.values(
    productPrices,
  )
    .filter((productPrice: any) => {
      return doesSearchQueryMatch(searchQuery, productPrice.name);
    })
    .reduce((acc: any, productPrice: any) => {
      acc[productPrice.id] = productPrice;
      return acc;
    }, {});

  // Switch it to all
  if (currentTab === ALL_CATEGORIES_LABEL) {
    return (
      <div className="mt-4 flex flex-col gap-5">
        {getSkuGroups(productPrices).map((skuGroup) => {
          return (
            <ProductSubGroupSection
              key={skuGroup}
              title={skuGroup}
              productIds={Object.keys(filteredProductPrices)
                .filter((productPriceId) => {
                  const productPrice = productPrices[productPriceId];
                  return (
                    !isNil(productPrice) &&
                    productPrice.skuGroup === skuGroup &&
                    isNil(productPrice.parentProduct)
                  );
                })
                .sort((a, b) => {
                  const ppa = productPrices[a];
                  const ppb = productPrices[b];
                  if (!isNil(ppa) && !isNil(ppb)) {
                    return ppa.displayOrder - ppb.displayOrder;
                  }
                  return 0;
                })}
              products={products}
              productPrices={productPrices} // pass in all productPrices here
              setProducts={setProducts}
              setSupportPackageTimezone={setSupportPackageTimezone}
              supportPackageTimezone={supportPackageTimezone}
              isComplexDemo={isComplexDemo}
            />
          );
        })}
      </div>
    );
  } else {
    // Each subgroup render a subsection
    const currentTabId = Object.keys(productGroups).find(
      (key) => productGroups[key].name === currentTab,
    );
    if (!currentTabId) {
      datadogRum.addError(new Error('ProductGroup ID is not found for a tab'), {
        skuGroups: JSON.stringify({ currentTabName: currentTab }),
      });
      return <></>;
    }
    let sections = Object.keys(productGroups[currentTabId].subcategories)
      .sort((a, b) => {
        const getOrder = (sectionId: string) => {
          const sectionName =
            productGroups[currentTabId].subcategories[
              sectionId
            ].name.toLowerCase();
          if (sectionName.includes('core') && sectionName.includes('solution'))
            return 0;
          if (sectionName === 'add-ons') return 1;
          if (sectionName === 'partnerships') return 2;
          if (sectionName === 'support') return 3;
          if (sectionName === 'implementation services') return 4;
          datadogRum.addError(`Unexpected section name ${sectionName}`);
          return 5;
        };
        return getOrder(a) - getOrder(b);
      })
      .map((subctegoryId) => {
        const filteredProductIds = productGroups[currentTabId].subcategories[
          subctegoryId
        ].products
          .filter((productId) => {
            return filteredProductPrices[productId] !== undefined;
          })
          .sort((a, b) => {
            const ppa = productPrices[a];
            const ppb = productPrices[b];
            if (ppa && ppb) {
              return ppa.displayOrder - ppb.displayOrder;
            } else {
              return 0;
            }
          });

        const subGroupName =
          productGroups[currentTabId].subcategories[subctegoryId].name;

        return (
          <ProductSubGroupSection
            key={subctegoryId}
            title={subGroupName}
            productIds={filteredProductIds}
            products={products}
            productPrices={filteredProductPrices}
            setProducts={setProducts}
            setSupportPackageTimezone={setSupportPackageTimezone}
            supportPackageTimezone={supportPackageTimezone}
            isComplexDemo={isComplexDemo}
          />
        );
      });
    sections.push(
      <ProductSubGroupSection
        key={'no-group'}
        title={''}
        productIds={productGroups[currentTabId]?.products}
        products={products}
        productPrices={productPrices}
        setProducts={setProducts}
        setSupportPackageTimezone={setSupportPackageTimezone}
        supportPackageTimezone={supportPackageTimezone}
        isComplexDemo={isComplexDemo}
      />,
    );

    return <div className="mt-4 flex flex-col gap-5">{sections}</div>;
  }
};

const shouldShowProduct = (id: string, productPrices: PenguinProductPrices) => {
  return productPrices[id]?.isActive !== false;
};

const getDependentProducts = (
  id: string, // the dependency, e.g. CRA Base Report is a dependency for CRA Income Insights Module
  productPrices: PenguinProductPrices,
) => {
  const dependents = Object.keys(productPrices)
    // filter out products that should not be shown
    .filter((id) => shouldShowProduct(id, productPrices))
    .filter((productPriceId) => {
      const dependencies = productPrices[productPriceId]?.dependencyProductIds;
      return !isNil(dependencies) && dependencies.includes(id);
    })
    .map((dependentId) => productPrices[dependentId]);
  return dependents;
};
const getAddOnsForProduct = (
  parentId: string,
  productPrices: PenguinProductPrices,
  products: PenguinProduct[],
) => {
  const parentProductPrice = productPrices[parentId];
  if (isNil(parentProductPrice)) {
    return [];
  }
  const parentName = parentProductPrice.name;
  let addOns = Object.keys(productPrices)
    .sort((addonIdA, addonIdB) => {
      const ppa = productPrices[addonIdA];
      const ppb = productPrices[addonIdB];
      return (ppa?.displayOrder ?? -1) - (ppb?.displayOrder ?? -1);
    })
    // filter out products that should not be shown
    .filter((addonId) => shouldShowProduct(addonId, productPrices))
    .filter((addonId) => {
      const addonProductPrice = productPrices[addonId];
      if (isNil(addonProductPrice)) {
        return false;
      }
      // Transfer Risk Engine Custom Logic
      const addonName = addonProductPrice.name;
      if (
        vanillaOriginatorParentNames.includes(parentName) &&
        transferRiskEngineVanillaAddOnNames.includes(addonName)
      ) {
        return true;
      }

      if (
        highRiskParentNames.includes(parentName) &&
        transferRiskEngineHighRiskAddOnNames.includes(addonName)
      ) {
        return true;
      }
      // End Transfer Risk Engine Custom Logic

      return addonProductPrice.parentProductId === parentId;
    })
    .map((addonId) => {
      return {
        id: addonId,
        name: productPrices[addonId]!.name,
        checked:
          products.find((product) => product.id === addonId) !== undefined,
      };
    });

  return addOns;
};

// Normally, addons are unselected when their parent is unselected. If this
// returns true, that behavior is overridden and the Transfer Risk Engine addon
// is kept, because it is still being used by another parent
const shouldKeepTREWhenParentUnselected = (
  parentProductPrice: PenguinProductPrice | undefined,
  treAddOn: PenguinProduct,
  transferParentNames: string[],
  transferAddOnNames: string[],
  products: PenguinProduct[],
) => {
  if (isNil(parentProductPrice)) {
    return false;
  }
  if (
    transferParentNames.includes(parentProductPrice.name) &&
    transferAddOnNames.includes(treAddOn.name)
  ) {
    // check if another parent is in the set of products
    const remainingTransferParentNames = transferParentNames.filter(
      (pName) => parentProductPrice.name !== pName,
    );
    if (
      products.find((product) =>
        remainingTransferParentNames.includes(product.name),
      )
    ) {
      // if so, keep the addon
      return true;
    }
  }
  return false;
};

export const unselectProduct = ({
  id,
  products,
  productPrices,
}: {
  id: string;
  products: PenguinProduct[];
  productPrices: PenguinProductPrices;
}): PenguinProduct[] => {
  const addOns = getAddOnsForProduct(id, productPrices, products);
  const dependents = getDependentProducts(id, productPrices);
  const unselectedProductPrice = productPrices[id];

  // if product has add-ons or dependents, remove them as well
  return products.filter((product) => {
    // Transfer Risk Engine Add-ons Custom Logic
    if (
      shouldKeepTREWhenParentUnselected(
        unselectedProductPrice,
        product,
        vanillaOriginatorParentNames,
        transferRiskEngineVanillaAddOnNames,
        products,
      )
    ) {
      return true;
    }
    if (
      shouldKeepTREWhenParentUnselected(
        unselectedProductPrice,
        product,
        highRiskParentNames,
        transferRiskEngineHighRiskAddOnNames,
        products,
      )
    ) {
      return true;
    }
    return (
      product.id !== id &&
      !addOns.find((addOn) => addOn.id === product.id) &&
      !dependents.find((dependent) => dependent?.id === product.id)
    );
  });
};

export function productRequiresTransactionSize(
  productPrice: PenguinProductPrice,
  isComplexDemo: boolean,
) {
  return (
    (productPrice.priceType === 'percent' ||
      (productPrice.priceType === 'numerical' &&
        productPrice.priceComputationType === 'dynamic') ||
      productPrice.collectTxnSize) &&
    !isComplexDemo
  );
}

const ProductSubGroupSection = ({
  title,
  productIds,
  products,
  productPrices,
  setProducts,
  setSupportPackageTimezone,
  supportPackageTimezone,
  isComplexDemo = false,
}: {
  title: string;
  productIds: string[];
  products: PenguinProduct[];
  productPrices: PenguinProductPrices;
  setProducts: (products: PenguinProduct[]) => void;
  setSupportPackageTimezone: (timezone: string | null) => void;
  supportPackageTimezone: string | null;
  isComplexDemo?: boolean;
}) => {
  const { showToast } = useToast();

  const { pricingFlow, editMode } = usePricingFlowContext<PenguinPricingFlow>();

  if (productIds.length === 0) {
    return null;
  }

  const isProductSelected = (id: string) => {
    return products.find((product) => product.id === id) !== undefined;
  };

  const selectProduct = ({
    id,
    products: previouslySelectedProducts,
    productPrices,
  }: {
    id: string;
    products: PenguinProduct[];
    productPrices: PenguinProductPrices;
  }): PenguinProduct[] => {
    const productPrice = productPrices[id];
    if (isNil(productPrice)) {
      // product is not in pricing sheet, should not have been able to select in the first place
      return previouslySelectedProducts;
    }
    const name = productPrice.name;
    const exclusiveGroup = productPrice.exclusiveGroup;
    const addons = getAddOnsForProduct(
      productPrice.id,
      productPrices,
      products,
    );
    // When selecting a product, if it has addons, add all of the addons UNLESS it's a special add-on that shouldn't be pre-selected
    const addOnsNotAutoSelected = ['Assets Refresh Monthly (1 Acct)'];
    const addonsToAdd = addons
      .map((addon): PenguinProduct | null => {
        if (
          products.some(
            (p) =>
              p.id === addon.id || addOnsNotAutoSelected.includes(addon.name),
          )
        ) {
          return null;
        } else {
          return {
            name: addon.name,
            volume: productPrice.fixedVolume, // set to either fixedVolume, or undefined since user has not manually touched this value yet
            id: addon.id,
            rampType: 'fixed',
          };
        }
      })
      .filter((newProduct): newProduct is PenguinProduct => !isNil(newProduct));

    if (exclusiveGroup !== undefined) {
      // if a product is in an exclusive group we need to remove all other members
      const exclusiveGroupMembers = Object.values(productPrices)
        .filter(
          (p): p is PenguinProductPrice =>
            !isNil(p) && p.exclusiveGroup === exclusiveGroup,
        )
        .map((p) => p.id) as string[];

      // find all of the currently selected products with the same exclusiveGroup
      const selectedProductsMember = previouslySelectedProducts.map(
        (p) => p.id,
      );
      const selectedExclusiveGroupMembers = _.intersection(
        exclusiveGroupMembers,
        selectedProductsMember,
      );

      if (selectedExclusiveGroupMembers.length !== 0) {
        // if there are any selected products in the exclusiveGroup unselect and notify the user
        showToast({
          title: `You can only select one product from the "${exclusiveGroup}" group.`,
          subtitle: '',
          type: 'info',
        });

        // unselect all products in the exclusive group
        const productsWithoutGroupMembers =
          selectedExclusiveGroupMembers.reduce(
            (currentProducts, groupMemeber) =>
              unselectProduct({
                products: currentProducts,
                id: groupMemeber,
                productPrices: productPrices,
              }),
            previouslySelectedProducts,
          );

        // @TODO(Fay) hackkkk
        // remove all products in the exclusiveGroup "Custom pricing" and "Data Insights Dashboard"
        const productsWithoutMoreGroupMembers =
          productsWithoutGroupMembers.filter((product) => {
            const productPrice = productPrices[product.id];
            return (
              !isNil(productPrice) &&
              productPrice.exclusiveGroup !== 'Custom pricing' &&
              productPrice.exclusiveGroup !== 'Data Insights Dashboard'
            );
          });

        return [
          ...(name ===
          'Customized AI Models + Data Insights Dashboard (Basic Bundle)'
            ? productsWithoutMoreGroupMembers
            : productsWithoutGroupMembers),
          {
            name: name,
            volume: productPrice.fixedVolume, // set to either fixedVolume, or undefined since user has not manually touched this value yet
            id,
            rampType: 'fixed',
          },
          ...addonsToAdd,
        ];
      }
    }

    return [
      ...previouslySelectedProducts,
      {
        name,
        volume: productPrice.fixedVolume, // set to either fixedVolume, or undefined since user has not manually touched this value yet
        id,
        rampType: 'fixed',
      },
      ...addonsToAdd,
    ];
  };

  const toggleSelected = (id: string) => {
    if (isProductSelected(id)) {
      setProducts(unselectProduct({ id, products, productPrices }));
    } else {
      setProducts(selectProduct({ id, products, productPrices }));
    }
  };

  return (
    <div className="flex flex-col items-stretch gap-5">
      {title && (
        <div className="rounded-md bg-gray-100 px-3 py-2 text-xs font-medium text-gray-500">
          {title.toUpperCase()}
        </div>
      )}
      <div className="grid grid-cols-2  gap-2 sm:grid-cols-4">
        {productIds.map((productId) => {
          const productPrice = productPrices[productId];
          if (productPrice == null) {
            return null;
          }

          // feature flags
          if (!shouldShowProduct(productId, productPrices)) {
            return null;
          }

          const selected =
            products.find((product) => product.id === productPrice.id) !==
            undefined;
          const product = products.find(
            (product) => product.id === productPrice.id,
          );

          if (productPrice == null) {
            return null;
          }

          const addOns = getAddOnsForProduct(
            productId,
            productPrices,
            products,
          );

          const displayTransactionSize = productRequiresTransactionSize(
            productPrice,
            isComplexDemo,
          );

          const displaySupportPackageTimezone =
            productPrice.skuGroup === 'Support';
          const isEnabled =
            isNil(productPrice.dependencyProductIds) ||
            productPrice.dependencyProductIds.every((dependencyId) =>
              products.some(
                (selectedProduct) => selectedProduct.id === dependencyId,
              ),
            );

          const helpText = (() => {
            if (isNil(product)) {
              return null;
            }
            const transactionSizeValue = product.transactionSize?.value ?? 0;
            const local100 = formatCurrencyValue(
              {
                type: CurrencyValueType.FLAT,
                value: 100,
                currency: pricingFlow.additionalData.quoteCurrency,
              },
              0,
            );
            const local600 = formatCurrencyValue(
              {
                type: CurrencyValueType.FLAT,
                value: 600,
                currency: pricingFlow.additionalData.quoteCurrency,
              },
              0,
            );
            if (
              !isNil(transactionSizeValue) &&
              (transactionSizeValue < 100 || transactionSizeValue > 600)
            ) {
              if (productPrice.ProductCode === 'TRFSVO - BPS') {
                // Transfer (Standard ACH) - Vanilla Originators - bps
                const fixedPriceExists = Object.values(productPrices).some(
                  // Transfer (Standard ACH) - Vanilla Originators - Fixed
                  (productPrice) => productPrice?.ProductCode === 'TRFSVO - UF',
                );
                if (fixedPriceExists) {
                  return `BPS pricing should be used when ${local100} < txn size < ${local600}. Use fixed pricing instead`;
                } else {
                  return `BPS pricing should be used when ${local100} < txn size < ${local600}.`;
                }
              } else if (productPrice.ProductCode === 'TRFSDVO - BPS') {
                // Transfer (Same-day ACH) - Vanilla Originators - bps
                const fixedPriceExists = Object.values(productPrices).some(
                  (productPrice) =>
                    // Transfer (Same-day ACH) - Vanilla Originators - Fixed
                    productPrice?.ProductCode === 'TRFSDVO - UF',
                );
                if (fixedPriceExists) {
                  return `BPS pricing should be used when ${local100} < txn size < ${local600}. Use fixed pricing instead`;
                } else {
                  return `BPS pricing should be used when ${local100} < txn size < ${local600}.`;
                }
              }
            }
            if (
              !isNil(transactionSizeValue) &&
              transactionSizeValue > 100 &&
              transactionSizeValue < 600
            ) {
              if (productPrice.ProductCode === 'TRFSVO - UF') {
                // Transfer (Standard ACH) - Vanilla Originators - Fixed
                const bpsExists = Object.values(productPrices).some(
                  // Transfer (Standard ACH) - Vanilla Originators - bps
                  (productPrice) =>
                    productPrice?.ProductCode === 'TRFSVO - BPS',
                );
                if (bpsExists) {
                  return `Fixed pricing should be used when txn size < ${local100} or ${local600} < txn size. Use bps pricing instead`;
                } else {
                  return `Fixed pricing should be used when txn size < ${local100} or ${local600} < txn size.`;
                }
              } else if (productPrice.ProductCode === 'TRFSDVO - UF') {
                // Transfer (Same-day ACH) - Vanilla Originators - Fixed
                const bpsExists = Object.values(productPrices).some(
                  // Transfer (Standard ACH) - Vanilla Originators - bps
                  (productPrice) =>
                    productPrice?.ProductCode === 'TRFSDVO - BPS',
                );
                if (bpsExists) {
                  return `Fixed pricing should be used when txn size < ${local100} or ${local600} < txn size. Use bps pricing instead`;
                } else {
                  return `Fixed pricing should be used when txn size < ${local100} or ${local600} < txn size.`;
                }
              }
            }
            if (
              productPrice.name === 'Assets-1' ||
              productPrice.name === 'Assets (1 Acct) & Bank Income'
            ) {
              if (
                !isNil(
                  products.find((p) => {
                    return (
                      productPrices[p.id]?.parentProductId ===
                        productPrice.id &&
                      p.name === 'Assets Refresh Monthly (1 Acct)'
                    );
                  }),
                )
              ) {
                return 'Use of "Assets Refresh Monthly" requires a feature flag to be enabled by Eng. Skipping this step will result in billing errors - please post in #team-assets-open to have it enabled.';
              }
            }
          })();

          return (
            <ProductSelectField
              key={title + productPrice.name}
              name={productPrice.name}
              id={productPrice.id}
              addOns={addOns}
              description={productPrice.unitDefinition}
              onChange={toggleSelected}
              onTransactionSizeChange={(
                id: string,
                transactionSize: CurrencyValueFlat,
              ) => {
                setProducts(
                  products.map((product) => {
                    if (product.id === id) {
                      return {
                        ...product,
                        transactionSize,
                      };
                    } else {
                      return product;
                    }
                  }),
                );
              }}
              checked={selected}
              transactionSize={
                product?.transactionSize ?? {
                  type: CurrencyValueType.FLAT,
                  value: 0,
                  currency: pricingFlow.additionalData.quoteCurrency,
                }
              }
              displayTransactionSize={displayTransactionSize}
              setSupportPackageTimezone={setSupportPackageTimezone}
              displaySupportPackageTimezone={displaySupportPackageTimezone}
              supportPackageTimezone={supportPackageTimezone}
              helpText={helpText}
              disabled={!isEnabled || !editMode}
            />
          );
        })}
        {title === 'Partnerships' && ( // Remove when we implement this product
          <ProductSelectField
            key={title + 'Monthly Fee (Custom End Client)'}
            name={'Monthly Fee (Custom End Client)'}
            id={'--NO-ID-Monthly Fee (Custom End Client)'}
            description={'Coming soon!'}
            onChange={() => {}}
            checked={false}
            disabled={true}
          />
        )}
      </div>
    </div>
  );
};
const SearchInput = ({ onChange }: { onChange: (val: string) => void }) => {
  return (
    <div className="flex h-10 w-72 flex-row items-center rounded-lg border border-gray-300 p-2 shadow-sm focus-within:border-none focus-within:outline focus-within:outline-2 focus-within:outline-fuchsia-900">
      <MagnifyingGlassIcon
        className="mr-2 h-4 w-4 text-gray-500"
        aria-hidden="true"
      />
      <input
        className="text-md -ml-3 border-none bg-transparent text-gray-900 outline-none focus:border-none focus:ring-0 focus:ring-transparent"
        placeholder={'Search for a product'}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
    </div>
  );
};
