import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import Badge from 'src/components/Badge';
import { AlpacaProductSelectionModal } from './AlpacaProductSelectionModal';
import {
  AlpacaCategoryName,
  AlpacaCollectionsSkuSubgroup,
  AlpacaCurrentPricingCurves,
  AlpacaPricingCurve,
  AlpacaPricingFlow,
  AlpacaProduct,
  AlpacaProductPrice,
  AlpacaProductPrices,
} from './alpaca_types';

import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import Tooltip from 'src/components/Tooltip';
import { classNames } from 'src/dashboard/App';
import { usePricingFlowContext } from '../PricingFlow';
import TrashButton from '../TrashButton';
import {
  CurrencyValueFlat,
  CurrencyValueType,
  DerivedValue,
  isQuotePriceEqual,
  QuotePrice,
  ZERO_FLAT,
  ZERO_PERCENT,
} from '../types_common/price';
import {
  computeDerivedAggregationsForProducts,
  convertCurrencyValueForex,
  formatCurrencyValue,
  roundQuotePrice,
} from './alpaca_utils';
import { QuotePriceEditable } from './Components/AlpacaQuotePriceEditable';
import { QuoteTableBodyEmptyState } from './Components/AlpacaQuoteTableEmptyState';
import { VolumeEditable } from './Components/AlpacaVolumeEditable';
import DerivedCurrencyValue from './Components/DerivedCurrencyValue';

/**
 * Alpaca quote table
 * This component will fetch the available products from pricingFlow.pricingSheetData filtered by the skuFilter
 * and the already chosen products from pricingFlow.products filtered by the skuFilter.
 * The available products will be displayed in an "Add / edit" product modal.
 */
export default function AlpacaQuoteTable(props: {
  pricingFlow: AlpacaPricingFlow;
  skuFilter: (sku: AlpacaProductPrice) => boolean;
  // to sort pickable products in the modal
  productPriceSort?: (a: AlpacaProductPrice, b: AlpacaProductPrice) => number;
  // to sort selected products in the table
  productSort?: (a: AlpacaProduct, b: AlpacaProduct) => number;
  suggestedSkuFilter?: (sku: AlpacaProductPrice) => boolean;
  updateFlow: (flow: AlpacaPricingFlow) => void;
  title: string;
  categoryName: AlpacaCategoryName;
  selectorHelpText?: string | JSX.Element;
}) {
  const {
    pricingFlow,
    updateFlow,
    skuFilter,
    productSort,
    productPriceSort,
    suggestedSkuFilter,
    title,
    categoryName,
    selectorHelpText,
  } = props;

  // Get the available products from the pricingFlow.pricingSheetData and filter them based on the skuFilter
  // @TODO(fay) country US filter seems wrong!
  const unfilteredProductInfos =
    pricingFlow.pricingSheetData?.countryPricingSheets['us']?.productInfo;
  const productInfos = Object.fromEntries(
    Object.entries(unfilteredProductInfos).filter(([key, value]) =>
      skuFilter(value),
    ),
  );
  const productIds = Object.keys(productInfos);

  // Get the available pricing curves from pricingFlow.currentPricingCurves and filter them based on the skuFilter
  const productPricingCurves = Object.fromEntries(
    Object.entries(pricingFlow.currentPricingCurves).filter(([key, value]) => {
      return productIds.includes(key);
    }),
  );

  // Get the chosen products from pricingFlow.products and filter them based on the skuFilter
  const chosenProducts = (pricingFlow.products ?? []).filter((product) =>
    productIds.includes(product.id),
  );
  if (!isNil(productSort)) {
    chosenProducts.sort(productSort);
  }
  const otherProducts = (pricingFlow.products ?? []).filter(
    (product) => !productIds.includes(product.id),
  );

  return (
    <div>
      <div className="mt-2 w-full">
        <div>
          <QuoteTable
            products={chosenProducts}
            productInfos={productInfos}
            productPricingCurves={productPricingCurves}
            setProducts={(products) =>
              updateFlow({
                ...pricingFlow,
                products: products.concat(otherProducts),
              })
            }
            updateFlow={updateFlow}
            pricingFlow={pricingFlow}
            title={title}
            categoryName={categoryName}
            suggestedSkuFilter={suggestedSkuFilter}
            productPriceSort={productPriceSort}
            selectorHelpText={selectorHelpText}
          />
        </div>
      </div>
    </div>
  );
}

function QuoteTable(props: {
  products: AlpacaProduct[];
  productInfos: AlpacaProductPrices;
  productPricingCurves: AlpacaCurrentPricingCurves;
  setProducts: (products: AlpacaProduct[]) => void;
  updateFlow: (flow: AlpacaPricingFlow) => void;
  pricingFlow: AlpacaPricingFlow;
  title: string;
  categoryName: AlpacaCategoryName;
  suggestedSkuFilter?: (sku: AlpacaProductPrice) => boolean;
  productPriceSort?: (a: AlpacaProductPrice, b: AlpacaProductPrice) => number;
  selectorHelpText?: string | JSX.Element;
}) {
  const {
    products,
    productInfos,
    productPricingCurves,
    updateFlow,
    setProducts,
    pricingFlow,
    title,
    categoryName,
    suggestedSkuFilter,
    productPriceSort,
    selectorHelpText: productSelectorHelpText,
  } = props;

  const [showModal, setShowModal] = useState(false);

  const closeModal = () => {
    setShowModal(false);
  };
  const derivedAggregations = computeDerivedAggregationsForProducts(
    products,
    pricingFlow,
    pricingFlow.additionalData.quoteCurrency,
  );
  const monthlyGrossProfit = derivedAggregations.grossProfit ?? null;

  return (
    <div className="rounded-xl border border-gray-200 bg-white">
      <table className="min-w-full border-separate border-spacing-0">
        <QuoteTableHeader title={title} categoryName={categoryName} />
        <QuoteTableBody
          products={products}
          productInfos={productInfos}
          productPricingCurves={productPricingCurves}
          updateFlow={updateFlow}
          pricingFlow={pricingFlow}
        />
        <QuoteTableFooter
          monthlyGrossProfit={monthlyGrossProfit}
          setShowModal={setShowModal}
        />
      </table>
      <Transition appear show={showModal} as={Fragment}>
        <Dialog
          as="div"
          className="absolute z-50 items-center justify-center overflow-y-auto min-w-full"
          onClose={closeModal}
        >
          {/* This transitions the background to a dark shade */}
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto lg:px-20">
            <div className="min-h-full flex items-center justify-center px-8 py-4 text-center">
              <Transition.Child
                className="w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <AlpacaProductSelectionModal
                  products={products}
                  productInfos={productInfos}
                  setProducts={(products) => {
                    setProducts(products);
                  }}
                  closeModal={closeModal}
                  categoryName={categoryName}
                  suggestedSkuFilter={suggestedSkuFilter}
                  productPriceSort={productPriceSort}
                  helpText={productSelectorHelpText}
                />
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}

function QuoteTableBody(props: {
  products: AlpacaProduct[];
  productInfos: AlpacaProductPrices;
  productPricingCurves: AlpacaCurrentPricingCurves;
  updateFlow: (flow: AlpacaPricingFlow, showLoading?: boolean) => void;
  pricingFlow: AlpacaPricingFlow;
}) {
  const {
    products,
    productInfos,
    productPricingCurves,
    updateFlow,
    pricingFlow,
  } = props;

  return (
    <tbody>
      {products.length > 0 ? (
        products.map((product: AlpacaProduct, idx) => {
          const productInfo = productInfos[product.id];
          const productPricingCurve = productPricingCurves[product.id];
          return (
            <CurrentQuoteTableRow
              key={idx}
              product={product}
              productInfo={productInfo}
              productPricingCurve={productPricingCurve}
              updateFlow={updateFlow}
              pricingFlow={pricingFlow}
            />
          );
        })
      ) : (
        <QuoteTableBodyEmptyState colSpan={8} />
      )}
    </tbody>
  );
}

function QuoteTableFooter(props: {
  monthlyGrossProfit: DerivedValue<CurrencyValueFlat> | null;
  setShowModal: (showModal: boolean) => void;
}) {
  const { monthlyGrossProfit, setShowModal } = props;
  const { editMode } = usePricingFlowContext();

  return (
    <tfoot>
      <tr>
        <th
          colSpan={5}
          className="border-t bg-gray-50 whitespace-nowrap rounded-bl-xl bg-slate-50  px-6 py-2.5"
        >
          <button
            className={classNames(
              'block rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-semibold text-gray-700 shadow-sm focus:border-fuchsia-800 focus:outline-none focus:ring-fuchsia-800',
              !editMode && 'cursor-not-allowed opacity-50',
            )}
            onClick={() => {
              setShowModal(true);
            }}
            disabled={!editMode}
          >
            Add / edit
          </button>
        </th>
        <th
          scope="col"
          colSpan={1}
          className="border-t bg-gray-50 hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Gross Profit
        </th>
        <th
          scope="col"
          className="border-t bg-gray-50 hidden bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          <DerivedCurrencyValue value={monthlyGrossProfit} />
        </th>
        <th
          scope="col"
          className="border-t bg-gray-50 hidden rounded-br-xl bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {/* Trash icon */}
        </th>
      </tr>
    </tfoot>
  );
}

type QuoteTableHeaderProps = {
  title: string;
  categoryName: AlpacaCategoryName;
};
function QuoteTableHeader(props: QuoteTableHeaderProps) {
  const { title, categoryName } = props;
  const volumeHeader = (() => {
    switch (categoryName) {
      case 'Global Accounts':
        return '# of new accounts';
      case 'Acquiring':
      case 'Treasury FX':
      case 'Collections':
      case 'Payouts':
      case 'Issuing':
        return 'Monthly est. volume';
      default:
        const typecheck: never = categoryName;
        datadogRum.addError(`Unexpected category name ${typecheck}`);
        return 'Monthly est. volume';
    }
  })();
  return (
    <thead>
      <tr>
        <th
          scope="col"
          className="sticky top-0 z-10 w-full rounded-t-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
        >
          {title}
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {volumeHeader}
        </th>
        <th
          scope="col"
          className=" min-w-[140px] sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Quote price
        </th>
        <th
          scope="col"
          className="has-tooltip sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Sticker price
        </th>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          Cost
        </th>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="TOP"
          text="Monthly gross revenue - monthly cost"
        >
          Monthly gross profit
        </Tooltip>
        <Tooltip
          as="th"
          scope="col"
          className="sticky top-0 z-10 hidden border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap has-tooltip"
          location="LEFT"
          text="Monthly gross profit / monthly revenue"
        >
          Margin
        </Tooltip>
        <th
          scope="col"
          className="sticky top-0 z-10 hidden rounded-tr-xl border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap"
        >
          {/* Trash icon */}
        </th>
      </tr>
    </thead>
  );
}

export function getBadgeForProduct(productInfo: AlpacaProductPrice) {
  const spanStyleTemplate = (color: 'blue' | 'purple' | 'pink' | 'yellow') =>
    `ml-2 inline-flex items-center rounded-full bg-${color}-100 px-1.5 py-0.5 text-xs font-medium text-${color}-700`;

  switch (productInfo.skuGroup) {
    case 'Collections':
      switch (productInfo.skuSubgroup) {
        case AlpacaCollectionsSkuSubgroup.SWIFTCollection:
          return (
            <span className={spanStyleTemplate('blue')}>
              {productInfo.skuSubgroup.toUpperCase()}
            </span>
          );
        case AlpacaCollectionsSkuSubgroup.Local:
          return (
            <span className={spanStyleTemplate('purple')}>
              {productInfo.skuSubgroup.toUpperCase()}
            </span>
          );
        case AlpacaCollectionsSkuSubgroup.DirectDebit:
          return (
            <span className={spanStyleTemplate('pink')}>
              {productInfo.skuSubgroup.toUpperCase()}
            </span>
          );
        case AlpacaCollectionsSkuSubgroup.SWIFT:
          return (
            <span className={spanStyleTemplate('yellow')}>
              {productInfo.skuSubgroup.toUpperCase()}
            </span>
          );
        default:
          return <></>;
      }
    case 'Treasury FX':
    case 'Issuing':
    case 'Payouts':
    case 'Global Accounts':
      return <></>;
  }
}

function CurrentQuoteTableRow(props: {
  product: AlpacaProduct;
  productInfo: AlpacaProductPrice;
  productPricingCurve: AlpacaPricingCurve;
  updateFlow: (flow: AlpacaPricingFlow, showLoading?: boolean) => void;
  pricingFlow: AlpacaPricingFlow;
}) {
  const { product, productPricingCurve, updateFlow, pricingFlow, productInfo } =
    props;
  const { editMode } = usePricingFlowContext();

  const additionalData = pricingFlow.additionalData;
  const pricingInfo = productPricingCurve.pricingInformation;
  if (
    isNil(pricingInfo.listPrice) &&
    !(
      pricingInfo.skuGroup === 'Collections' ||
      pricingInfo.skuGroup === 'Global Accounts'
    )
  ) {
    datadogRum.addError(
      `Unexpectedly null list price ${pricingInfo.listPrice} for product ${product.name}`,
    );
  }
  if (isNil(pricingInfo.cost)) {
    datadogRum.addError(
      `Unexpectedly null cost ${pricingInfo.cost} for product ${product.name}`,
    );
  }
  if (pricingInfo.cost?.type === 'tiered') {
    datadogRum.addError(
      `Unexpectedly got a tiered cost ${pricingInfo.cost}. This only happens for Issuing transaction cost products, which should not use this component`,
    );
  }
  const stickerPrice = pricingInfo.listPrice
    ? convertCurrencyValueForex(
        pricingInfo.listPrice,
        additionalData.quoteCurrency,
        pricingFlow,
      )
    : null;
  const cost = convertCurrencyValueForex(
    isNil(pricingInfo.cost) || pricingInfo.cost.type === 'tiered'
      ? ZERO_PERCENT
      : pricingInfo.cost,
    additionalData.quoteCurrency,
    pricingFlow,
  );
  const quotePrice =
    product.quotePrice ??
    stickerPrice ??
    ZERO_FLAT(pricingFlow.additionalData.quoteCurrency);

  function updateVolume(volume: number) {
    if (volume === product.volume) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          volume: volume,
        };
      } else {
        return p;
      }
    });

    updateFlow(
      {
        ...pricingFlow,
        // @ts-ignore
        products: newProducts,
      },
      true,
    );
  }

  function updateTransactionCount(transactionCount: number | undefined) {
    if (transactionCount === product.transactionCount) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          transactionCount: transactionCount,
        };
      } else {
        return p;
      }
    });

    updateFlow(
      {
        ...pricingFlow,
        // @ts-ignore
        products: newProducts,
      },
      true,
    );
  }

  function updateQuotePrice(newQuotePriceRaw: QuotePrice | null) {
    if (newQuotePriceRaw == null) return;
    const newQuotePrice = roundQuotePrice(newQuotePriceRaw);
    if (newQuotePrice && isQuotePriceEqual(newQuotePrice, quotePrice)) {
      // No change
      return;
    }

    const newProducts = (pricingFlow.products ?? []).map((p) => {
      if (p.id === product.id) {
        return {
          ...p,
          quotePrice: roundQuotePrice(newQuotePrice ?? stickerPrice),
        };
      } else {
        return p;
      }
    });
    updateFlow({
      ...pricingFlow,
      products: newProducts,
    });
  }

  const handleDelete = () => {
    // handle delete action here
    const newProducts: AlpacaProduct[] =
      pricingFlow.products?.filter((p) => {
        return p.id !== product.id;
      }) ?? [];

    updateFlow({
      ...pricingFlow,
      products: newProducts,
    });
  };

  const customCountSuffix = (() => {
    switch (product.categoryName) {
      case 'Treasury FX':
      case 'Payouts':
      case 'Issuing':
      case 'Collections':
        return null;
      case 'Global Accounts':
        return 'ct';
      default:
        const typecheck: never = product;
        datadogRum.addError(`unexpected category name ${typecheck}`);
        return null;
    }
  })();

  const isProductTierable = (() => {
    switch (product.categoryName) {
      case 'Treasury FX':
      case 'Issuing':
      case 'Payouts':
        return true;
      case 'Collections':
      case 'Global Accounts':
        return false;
      default:
        const typecheck: never = product;
        datadogRum.addError(`unexpected category name ${typecheck}`);
        return true;
    }
  })();
  const validTierMinimumTypesForProduct = ((): (
    | CurrencyValueType.FLAT
    | 'count'
  )[] => {
    switch (product.categoryName) {
      case 'Treasury FX':
      case 'Issuing':
      case 'Collections':
      case 'Global Accounts':
        return ['count', CurrencyValueType.FLAT];
      case 'Payouts':
        return ['count'];
      default:
        const typecheck: never = product;
        datadogRum.addError(`unexpected category name ${typecheck}`);
        return ['count', CurrencyValueType.FLAT];
    }
  })();

  return (
    <tr>
      {/* Product */}
      <td className="relative min-w-[172px] px-6 py-4">
        <div className="flex flex-col gap-1">
          <span className="min-h-6 text-sm font-medium text-gray-900">
            {product.name} {getBadgeForProduct(productInfo)}
          </span>
        </div>
      </td>

      {/* Monthly est. volume */}
      <td className="overflow-show h-full w-full p-0 align-top">
        <VolumeEditable
          volume={product.volume}
          updateVolume={updateVolume}
          transactionCount={product.transactionCount}
          updateTransactionCount={updateTransactionCount}
          customCountSuffix={customCountSuffix}
          quotePrice={quotePrice}
          quoteCurrency={pricingFlow.additionalData.quoteCurrency}
          cost={cost}
        />
      </td>

      {/* Quote price */}
      <td className="whitespace-nowrap overflow-show h-full w-full p-0 align-top">
        <QuotePriceEditable
          quotePrice={roundQuotePrice(quotePrice)}
          updateQuotePrice={updateQuotePrice}
          validPriceTypes={
            productPricingCurve.pricingInformation.validQuotePriceTypes
          }
          quoteCurrency={pricingFlow.additionalData.quoteCurrency}
          stickerPrice={stickerPrice}
          cost={cost}
          productName={product.name}
          tierable={isProductTierable}
          validTierMinimumTypes={validTierMinimumTypesForProduct}
        />
      </td>

      {/* Sticker price */}
      {/* If there is no sticker price right now, then show a tooltip saying coming soon */}
      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium align-top">
        {isNil(stickerPrice) ? (
          <Tooltip
            as="button"
            location="TOP"
            text="Coming soon"
            disabled={true}
            onClick={() => {}}
          >
            <Badge color="green">{formatCurrencyValue(stickerPrice)}</Badge>
          </Tooltip>
        ) : (
          <button
            className=""
            title="Set quote price to sticker price"
            onClick={() => {
              updateQuotePrice(stickerPrice);
            }}
            disabled={!editMode}
          >
            <Badge color="green">{formatCurrencyValue(stickerPrice)}</Badge>
          </button>
        )}
      </td>

      {/* Cost */}
      <td className="whitespace-nowrap px-6 py-4 text-xs font-medium text-orange-700 align-top">
        <button
          className=""
          title="Set quote price to cost"
          disabled={isNil(cost) || !editMode}
          onClick={() => {
            updateQuotePrice(cost);
          }}
        >
          <Badge color="orange">{formatCurrencyValue(cost)}</Badge>
        </button>
      </td>

      {/* Monthly gross profit */}
      <td className="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-500 align-top">
        <DerivedCurrencyValue
          value={product.derivedAggregations?.grossProfit ?? null}
        />
      </td>

      {/* Margin */}
      <td className="px-6 py-4 text-sm  text-gray-500 2xl:table-cell align-top">
        <DerivedCurrencyValue
          value={product.derivedAggregations?.profitMargin ?? null}
        />
      </td>

      {/* Trash icon */}
      <td>
        <TrashButton editMode={editMode} handleDelete={handleDelete} />
      </td>
    </tr>
  );
}
