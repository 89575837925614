import { useEffect, useRef, useState } from 'react';
import { classNames } from 'src/dashboard/App';

export default function EditableTitle(props: {
  title: string;
  updateTitle: (title: string) => void;
  disabled: boolean;
}) {
  const { updateTitle, disabled } = props;
  const [title, setTitle] = useState<string>(props.title);
  const [isEditing, setIsEditing] = useState(false);
  const [inputWidth, setInputWidth] = useState(0);

  // hidden span for computing the width of the title for the input
  const spanRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (spanRef.current) {
      setInputWidth(spanRef.current.offsetWidth + 19);
    }
  }, [title]);

  return (
    <div className="inline-block min-w-64">
      {isEditing ? (
        <input
          type="text"
          value={title}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
          onBlur={() => {
            updateTitle(title);
            setIsEditing(false);
          }}
          disabled={disabled ?? !isEditing}
          autoFocus
          style={{ width: `${inputWidth}px` }}
          className={classNames(
            `text-lg font-semibold border border-gray-300 focus:outline-none focus:ring-1 focus:ring-fuchsia-900 rounded-md px-2 py-1 min-w-full text-nowrap max-w-2xl overflow-hidden`,
          )}
          title="Rename"
        />
      ) : (
        <h1
          onClick={() => {
            if (!disabled) {
              setIsEditing(true);
            }
          }}
          className={classNames(
            'text-lg font-semibold cursor-default border border-transparent  rounded-md px-2 py-1 min-h-[2.39rem] min-w-full text-nowrap max-w-2xl overflow-hidden',
            !disabled && 'hover:border-gray-300 hover:cursor-text',
          )}
          title="Rename"
        >
          {title}
        </h1>
      )}
      {/* Hidden span to calculate the width of the input */}
      <span
        ref={spanRef}
        className="text-lg font-semibold absolute invisible whitespace-pre"
      >
        {title}
      </span>
    </div>
  );
}
