import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { useEffect, useState } from 'react';
import 'src/components/CrepeEditor.css';
import { classNames } from 'src/dashboard/App';

export function CrepeDisplay(props: {
  uniqueIdentifier: string;
  context: string;
  header: string;
}) {
  const { uniqueIdentifier, context, header } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [crepeEditor, setCrepeEditor] = useState<Crepe | null>(null);

  useEffect(() => {
    const newCrepe = new Crepe({
      root: `#crepeeditor--Display-${uniqueIdentifier}`,
      defaultValue: context,
    });
    newCrepe.setReadonly(true);

    newCrepe.create().then(() => {
      console.log('Crepe editor created');
      setCrepeEditor(newCrepe);
    });

    return () => {
      // Destroy the editor when unmounting component
      newCrepe.destroy();
      console.log('Crepe editor destroyed');
    };
  }, []);

  useEffect(() => {
    if (crepeEditor) {
      console.log('Setting crepe readonly to: ', !isEditing);
      crepeEditor.setReadonly(!isEditing);
    }
  }, [isEditing, crepeEditor]);

  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="p-4 flex justify-between items-center font-medium">
        <span className="text-xs text-gray-500 uppercase">{header}</span>
      </div>
      {/* body */}
      <div
        id={`crepeeditor--Display-${uniqueIdentifier}`}
        className={classNames('p-4')}
      />
    </div>
  );
}

export function CrepeHeadlessEditor(props: { uniqueIdentifier: string }) {
  return <div id={`crepeeditor--HeadlessEditor-${props.uniqueIdentifier}`} />;
}
