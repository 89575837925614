import { Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { classNames } from 'src/dashboard/App';

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}
export default function CollapsibleSection(props: CollapsibleSectionProps) {
  const { title, children, className } = props;
  const [isExpanded, setIsExpanded] = useState<boolean>(true);
  return (
    <>
      <div
        className={classNames(
          'bg-gray-100 px-2 py-1.5 text-xs font-medium text-gray-500 w-full flex items-center cursor-pointer',
          !isExpanded && 'border-b border-b-slate-200',
        )}
        onClick={() =>
          setIsExpanded((prevIsExpanded) => {
            return !prevIsExpanded;
          })
        }
      >
        <span className="mr-2">
          {isExpanded ? (
            <ChevronDownIcon width={16} height={16} />
          ) : (
            <ChevronUpIcon width={16} height={16} />
          )}
        </span>
        {title}
      </div>
      <Transition
        as={'div'}
        unmount={false}
        show={isExpanded}
        className={classNames('w-full', className)}
        enter="transition-all duration-300"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-[48rem] opacity-100"
        leave="transition-all duration-300"
        leaveFrom="max-h-[48rem] opacity-100"
        leaveTo="max-h-0 opacity-0"
      >
        {children}
      </Transition>
    </>
  );
}
