import { classNames } from 'src/dashboard/App';

export type BadgeColor =
  | 'gray'
  | 'red'
  | 'orange'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'indigo'
  | 'purple'
  | 'pink';

const colorMapping = {
  gray: 'bg-gray-100 text-gray-800',
  red: 'bg-red-100 text-red-800',
  orange: 'bg-orange-100 text-orange-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  green: 'bg-green-100 text-green-800',
  blue: 'bg-blue-100 text-blue-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  purple: 'bg-purple-100 text-purple-800',
  pink: 'bg-pink-100 text-pink-800',
};

export default function Badge(props: {
  children: React.ReactNode;
  color: BadgeColor;
  className?: string;
  size?: 'small' | 'medium';
  style?: React.CSSProperties;
}) {
  return (
    <span
      className={classNames(
        colorMapping[props.color],
        'inline-flex items-center rounded-full bg-gray-100 text-xs font-medium text-gray-600',
        props.size === 'small' ? 'px-1.5 py-0.5' : 'px-2 py-1',
        props.className ?? '',
      )}
      style={props.style}
    >
      {props.children}
    </span>
  );
}
