import { datadogRum } from '@datadog/browser-rum';
import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowDownRightIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { Fragment, useRef, useState } from 'react';
import Badge from 'src/components/Badge';
import { FormattedNumberField } from 'src/components/Fields';
import Tooltip from 'src/components/Tooltip';
import { unreachable } from 'src/typeUtils';
import { getHandleKeyDownForEnterNextRowHandling } from 'src/utils';
import { formatCurrencyValue } from '../Alpaca/alpaca_utils';
import { QuotePriceEditable } from '../Alpaca/Components/AlpacaQuotePriceEditable';
import { QuoteTableBodyEmptyState } from '../Alpaca/Components/AlpacaQuoteTableEmptyState';
import EditableIndicator from '../Penguin/Components/EditableIndicator';
import { usePricingFlowContext } from '../PricingFlow';
import { getLightVariant } from '../PricingFlowList';
import TrashButton from '../TrashButton';
import {
  Count,
  CurrencyValue,
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueTiered,
  CurrencyValueType,
  ZERO_FLAT,
} from '../types_common/price';
import HamsterProductSelectionModal from './HamsterProductSelectionModal';
import {
  ApprovalsDisplay,
  HamsterCV,
  HamsterPricingFlow,
  HamsterPricingInformation,
  HamsterProduct,
  HamsterQuotePrice,
  StepSnapshot,
} from './hamster_types';
import {
  estimatedMonthlyRevenue,
  getBlendedPriceForVolume,
  getListPriceForVolume,
  tierForValue,
} from './hamster_utils';

interface ListPriceBadgeProps {
  product: HamsterProduct;
}
function ListPriceBadge(props: ListPriceBadgeProps) {
  const { product } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const listPrice =
    pricingFlow.pricingSheetData.productInfo[product.id]?.currentPricingCurve
      .pricingInformation.listPrice;
  const onClick = () => {
    updateFlow(
      produce(pricingFlow, (draft) => {
        const productToUpdate = draft.products.find((p) => p.id === product.id);
        if (productToUpdate) {
          productToUpdate.quotePrice = listPrice ?? null;
        }
      }),
      false,
    );
  };
  switch (listPrice?.type) {
    case 'tiered': {
      const blendedListPrice = getBlendedPriceForVolume(
        product.volume,
        listPrice.tiers,
      );
      const tooltipText = listPrice.tiers
        .map(
          (t) =>
            `≥${formatCurrencyValue(t.minimum)}:  ${formatCurrencyValue(t.currencyValue, 0)}`,
        )
        .join('\n');
      return (
        <div className="flex flex-col justify-start items-start mt-[-12px]">
          <div
            className="w-full text-gray-400"
            style={{ fontSize: '10px', lineHeight: '12px' }}
          >
            Tiered
          </div>

          <Tooltip
            as="button"
            location="LEFT"
            text={<div className="text-left">{tooltipText}</div>}
            className=""
            disabled={!editMode || isNil(blendedListPrice)}
            onClick={onClick}
          >
            <Badge
              color="purple"
              className={!isNil(blendedListPrice) ? 'hover:bg-purple-200' : ''}
            >
              {formatCurrencyValue(blendedListPrice, 2)}
            </Badge>
          </Tooltip>
        </div>
      );
    }
    default: {
      return (
        <div className="flex flex-col justify-start items-start mt-[-12px]">
          <div
            className="w-full text-gray-400"
            style={{ fontSize: '10px', lineHeight: '12px' }}
          >
            <div className="h-[12px]" />
          </div>

          <button
            className=""
            title="Set quote price to rack rate"
            disabled={!editMode || isNil(listPrice)}
            onClick={onClick}
          >
            <Badge
              color="purple"
              className={!isNil(listPrice) ? 'hover:bg-purple-200' : ''}
            >
              {formatCurrencyValue(listPrice as unknown as CurrencyValue, 2)}
            </Badge>
          </button>
        </div>
      );
    }
  }
}

interface ProductPricingSummary {
  blendedListPrice: CurrencyValueFlat | null;
  blendedQuotePrice: CurrencyValueFlat;
  discount: CurrencyValuePercent | null;
}
export function computeProductPricingSummary(
  product: HamsterProduct,
  pricingFlow: HamsterPricingFlow,
  monthIdx: number | null,
): ProductPricingSummary {
  const pricingInfo =
    pricingFlow.pricingSheetData.productInfo[product.id]?.currentPricingCurve
      .pricingInformation;
  const volume =
    isNil(monthIdx) || isNil(product.rampedVolumeIncremental[monthIdx])
      ? product.volume
      : product.volume + product.rampedVolumeIncremental[monthIdx].value;
  const blendedListPrice = getBlendedPriceForVolume(
    product.volume,
    pricingInfo?.listPrice?.tiers,
  );
  const quotePriceVal = getQuotePrice(product, volume);
  const blendedQuotePrice: CurrencyValueFlat = quotePriceVal
    ? {
        type: CurrencyValueType.FLAT,
        value: quotePriceVal,
        currency: 'USD',
      }
    : (blendedListPrice ?? ZERO_FLAT('USD'));
  const discount: CurrencyValuePercent | null = isNil(blendedListPrice)
    ? null
    : {
        type: CurrencyValueType.PERCENT,
        value:
          (100 * (blendedListPrice.value - blendedQuotePrice.value)) /
          blendedListPrice.value,
      };
  return { blendedListPrice, blendedQuotePrice, discount };
}

export function formatNumberAsUSDCV(n: number, numDecimals: number) {
  return formatCurrencyValue(
    {
      type: CurrencyValueType.FLAT,
      value: n,
      currency: 'USD',
    },
    numDecimals,
  );
}

enum ApprovalLevel {
  None = 0,
  Manager = 1,
  DealOps = 2,
  HeadOfMidmarket = 3,
  VPOfSales = 4,
  DealDesk = 5,
}
function _getApprovalLevelForPrice(
  price: HamsterCV | null,
  volume: number,
  pricingInfo: HamsterPricingInformation,
) {
  let approvalLevel = ApprovalLevel.None;
  const requiresApproval = (
    approvalTiers:
      | CurrencyValueTiered<CurrencyValueFlat, Count>
      | true
      | null
      | undefined,
  ) => {
    if (isNil(approvalLevel)) {
      return false;
    }
    if (approvalTiers === true) {
      return true;
    }
    const approvalTier = tierForValue(approvalTiers?.tiers, volume);
    if (isNil(approvalTier)) {
      return false;
    }
    return (
      approvalTier.currencyValue.value >
      (price?.value ?? getListPriceForVolume(volume, pricingInfo)?.value ?? 0)
    );
  };
  if (requiresApproval(pricingInfo.headOfMidmarketSales)) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.HeadOfMidmarket);
  }
  if (requiresApproval(pricingInfo.vpOfSales)) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.VPOfSales);
  }
  if (requiresApproval(pricingInfo.dealDesk)) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.DealDesk);
  }
  if (requiresApproval(pricingInfo.legal)) {
    approvalLevel = Math.max(approvalLevel, ApprovalLevel.DealOps);
  }
  return approvalLevel;
}
function _getApprovalLevelForTier(
  price: HamsterCV,
  tierMin: number,
  pricingInfo: HamsterPricingInformation,
) {
  return _getApprovalLevelForPrice(price, tierMin, pricingInfo);
}

function _getTopLevelApprovalLevelForProduct(
  product: HamsterProduct,
  pricingInfo: HamsterPricingInformation,
) {
  const quotePrice = product.quotePrice;
  switch (quotePrice?.type) {
    case CurrencyValueType.FLAT:
      return _getApprovalLevelForPrice(quotePrice, product.volume, pricingInfo);
    case 'tiered':
      const highestApproval = _.max(
        quotePrice.tiers.map((t) =>
          _getApprovalLevelForTier(
            t.currencyValue,
            t.minimum.value,
            pricingInfo,
          ),
        ),
      );
      if (!isNil(highestApproval)) {
        return highestApproval;
      } else {
        datadogRum.addError(
          `computing approval for empty tiers, falling back to no approval`,
        );
        return ApprovalLevel.None;
      }
    case undefined:
      return _getApprovalLevelForPrice(
        getBlendedPriceForVolume(product.volume, pricingInfo.listPrice?.tiers),
        product.volume,
        pricingInfo,
      );
    default:
      unreachable(quotePrice);
  }
}
function getLargerApproval(
  pricingCurveApproval: ApprovalLevel,
  stepSnapshot: StepSnapshot | undefined,
) {
  if (isNil(stepSnapshot)) {
    return pricingCurveApproval;
  }
  const stepSnapshotEffectiveApproval = (() => {
    switch (stepSnapshot.name.toLowerCase()) {
      case 'head of mid-market':
        return ApprovalLevel.HeadOfMidmarket;
      case 'vp of sales':
        return ApprovalLevel.VPOfSales;
      case 'deal desk':
        return ApprovalLevel.DealDesk;
      case 'deal ops':
        return ApprovalLevel.DealOps;
      default:
        datadogRum.addError(
          `unexpected step snapshot name: ${stepSnapshot.name}`,
        );
        return ApprovalLevel.None;
    }
  })();
  return Math.max(stepSnapshotEffectiveApproval, pricingCurveApproval);
}

interface ApprovalBadgeProps {
  approvalLevel: ApprovalLevel;
}
// @TODO(fay) let's consolidate
export function ApprovalLevelBadge(props: ApprovalBadgeProps) {
  let stepColor = '';
  let stepName = '';
  switch (props.approvalLevel) {
    case ApprovalLevel.None:
      stepColor = '#166534';
      stepName = 'None';
      break;
    case ApprovalLevel.HeadOfMidmarket:
      stepColor = '#92400e';
      stepName = 'Head of Mid-Market';
      break;
    case ApprovalLevel.VPOfSales:
      stepColor = '#9a3412';
      stepName = 'VP of Sales';
      break;
    case ApprovalLevel.DealDesk:
      stepColor = '#991b1b';
      stepName = 'Deal Desk';
      break;
    case ApprovalLevel.DealOps:
      stepColor = '#9f1239';
      stepName = 'Deal Ops';
      break;
    default:
      return;
  }
  return (
    <Badge
      color="gray"
      style={{
        color: stepColor,
        background: getLightVariant(stepColor),
      }}
    >
      {stepName}
    </Badge>
  );
}

// #HamsterGetQuotePrice
function getQuotePrice(product: HamsterProduct, volumeForMonth: number) {
  const quotePrice = product.quotePrice;
  if (isNil(quotePrice)) {
    return null;
  }
  switch (quotePrice.type) {
    case CurrencyValueType.FLAT:
      return quotePrice.value;
    case 'tiered':
      return getBlendedPriceForVolume(volumeForMonth, quotePrice.tiers)?.value;
    default:
      unreachable(quotePrice);
  }
}
interface QuoteTableRowProps {
  product: HamsterProduct;
  approvalsNeeded: ApprovalsDisplay;
}
function QuoteTableRow(props: QuoteTableRowProps) {
  const volumeInputRef = useRef<HTMLInputElement>(null);
  const { product, approvalsNeeded } = props;
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
  const [showRampUp, setShowRampUp] = useState(false);
  if (isNil(productInfo)) {
    return null;
  }
  const pricingInfo = productInfo.currentPricingCurve.pricingInformation;
  const { blendedListPrice, discount } = computeProductPricingSummary(
    product,
    pricingFlow,
    null,
  );
  const estMonthlyRevenue = estimatedMonthlyRevenue(
    product,
    productInfo,
    pricingFlow.additionalData.subscriptionTerms,
    'prorated',
  );
  const months = Array.from(
    { length: pricingFlow.additionalData.subscriptionTerms },
    (_, i) => i + 1,
  );
  const handleDelete = () => {
    const newProducts = pricingFlow.products.filter((p) => p.id !== product.id);
    updateFlow({ ...pricingFlow, products: newProducts }, false);
  };
  return (
    <>
      {/* Main product row */}
      <tr key={product.id}>
        {/* Product */}
        <td className="flex min-w-[172px] items-center gap-4 border-b border-gray-200 px-6 py-4 h-full">
          <div className="flex flex-row items-center">
            {isNil(productInfo.fixedVolume) ? (
              <button
                className="mr-3 flex h-5 w-5 items-center justify-center rounded-full border border-gray-200 bg-white"
                onClick={() => {
                  setShowRampUp((prevShowRampUp) => !prevShowRampUp);
                }}
              >
                {showRampUp ? (
                  <ChevronDownIcon className="h-3 w-3" aria-hidden="true" />
                ) : (
                  <ChevronRightIcon className="h-3 w-3" aria-hidden="true" />
                )}
              </button>
            ) : (
              <div className="mr-3 h-5 w-5" />
            )}
            {productInfo.isFreeformProduct ? (
              <input
                type="text"
                data-1p-ignore
                className="text-sm font-medium text-gray-900 rounded-sm outline-1 outline-gray-300 outline-none border-none focus-within:outline focus-within:outline-fuchsia-900 focus:border-none focus:ring-0 focus:ring-transparent p-1"
                value={product.customName ?? productInfo.name}
                onChange={(e) => {
                  updateFlow(
                    produce(pricingFlow, (draftFlow) => {
                      const productToRename = draftFlow.products.find(
                        (p) => p.id === product.id,
                      );
                      if (productToRename)
                        productToRename.customName = e.currentTarget.value;
                    }),
                    false,
                  );
                }}
              />
            ) : (
              <span className="text-sm font-medium text-gray-900">
                {product.customName ?? productInfo.name}
              </span>
            )}
          </div>
        </td>
        {/* Quantity */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 text-sm text-gray-500">
          {!isNil(productInfo.fixedVolume) ? (
            <div className="w-28 h-full">
              <FormattedNumberField
                type="text"
                value={productInfo.fixedVolume}
                required={true}
                numberDecimals={0}
                className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                updateValue={() => {}}
                disabled={true}
              />
            </div>
          ) : (
            <EditableIndicator>
              <FormattedNumberField
                type="text"
                value={product.volume}
                required={true}
                numberDecimals={0}
                className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
                data-volume-editable
                ref={volumeInputRef}
                onKeyDown={getHandleKeyDownForEnterNextRowHandling(
                  volumeInputRef,
                  'data-volume-editable',
                )}
                updateValue={(value: number) => {
                  updateFlow(
                    produce(pricingFlow, (draft) => {
                      const productToUpdate = draft.products.find(
                        (p) => p.id === product.id,
                      );
                      if (productToUpdate) {
                        productToUpdate.volume = value;
                      }
                    }),
                    false,
                  );
                }}
                disabled={!editMode}
              />
            </EditableIndicator>
          )}
        </td>
        {/* List price */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-purple-700">
          <ListPriceBadge product={product} />
        </td>
        {/* Proposed price */}
        <td
          className="overflow-show h-full w-full p-0 align-top border-b border-gray-200 min-w-[120px]"
          onClick={(e: React.MouseEvent<HTMLTableDataCellElement>) => {}}
        >
          <QuotePriceEditable
            quotePrice={
              product.quotePrice ?? blendedListPrice ?? ZERO_FLAT('USD')
            }
            productVolume={product.volume}
            updateQuotePrice={(newQuotePrice) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.quotePrice =
                      (newQuotePrice as HamsterQuotePrice) ?? ZERO_FLAT('USD');
                  }
                }),
                false,
              );
            }}
            validPriceTypes={[CurrencyValueType.FLAT]}
            validTierMinimumTypes={['count']}
            quoteCurrency="USD"
            stickerPrice={null}
            cost={null}
            productName={productInfo.name}
            tierable={isNil(productInfo.fixedVolume)}
            tierConfig={{
              showBlendedPrice: true,
              showStickerPrice: false,
              showCost: false,
              showApprovalLevel: true,
              getApprovalBadge: (tierMin, price) => {
                return (
                  <ApprovalLevelBadge
                    approvalLevel={_getApprovalLevelForTier(
                      price as CurrencyValueFlat,
                      tierMin.value,
                      pricingInfo,
                    )}
                  />
                );
              },
              countMinimumHeaderOverride: 'Quantity ≥',
              countMinimumSuffixOverride: '',
              additionalPrices: [
                {
                  label: 'List price',
                  badgeColor: 'purple',
                  value: (tierMin, _ignored) => {
                    return (
                      getListPriceForVolume(tierMin.value, pricingInfo) ?? null
                    );
                  },
                },
                {
                  label: 'Discount',
                  badgeColor: null,
                  value: (tierMin, tierPrice) => {
                    const listPrice =
                      getListPriceForVolume(tierMin.value, pricingInfo) ??
                      ZERO_FLAT('USD');
                    const tierPriceValue = (() => {
                      switch (tierPrice.type) {
                        case CurrencyValueType.FLAT:
                          return tierPrice.value;
                        default:
                          datadogRum.addError(
                            `unexpected tier price type ${tierPrice.type}`,
                          );
                          return 0;
                      }
                    })();
                    const discount =
                      (100 * (listPrice.value - tierPriceValue)) /
                      listPrice.value;
                    return {
                      type: CurrencyValueType.PERCENT,
                      value: discount,
                    };
                  },
                },
              ],
            }}
          />
        </td>
        {/* Discount */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500">
          {formatCurrencyValue(discount, 1)}
        </td>
        {/* Approval level */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-xs font-medium text-green-700">
          <ApprovalLevelBadge
            approvalLevel={getLargerApproval(
              _getTopLevelApprovalLevelForProduct(product, pricingInfo),
              approvalsNeeded.productsNeeded[product.id]?.greatestStep,
            )}
          />
        </td>
        {/* Est. Monthly revenue */}
        <td className="whitespace-nowrap border-b border-gray-200 px-6 py-4 text-sm font-medium text-gray-500">
          {formatNumberAsUSDCV(estMonthlyRevenue, 2)}
        </td>
        {/* Trash icon */}
        <td className="border-b border-gray-200">
          <TrashButton editMode={editMode} handleDelete={handleDelete} />
        </td>
      </tr>
      {/* Optionally, ramp up rows */}
      {showRampUp &&
        months.map((monthNumber) => {
          return (
            <Transition
              key={`${product.id}_${monthNumber}`}
              as="tr"
              className="overflow-hidden"
              show={showRampUp}
              enter="transition-all duration-300"
              enterFrom="max-h-0 opacity-0"
              enterTo="max-h-[48rem] opacity-100"
              leave="transition-all duration-300"
              leaveFrom="max-h-[48rem] opacity-100"
              leaveTo="max-h-0 opacity-0"
            >
              <ProductRampUpRow
                monthIdx={monthNumber - 1}
                monthNumber={monthNumber}
                product={product}
              />
            </Transition>
          );
        })}
    </>
  );
}

function ProductRampUpRow(props: {
  monthIdx: number;
  monthNumber: number;
  product: HamsterProduct;
}) {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<HamsterPricingFlow>();
  const { monthNumber, monthIdx, product } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const incrementalVolumeAtMonth = product.rampedVolumeIncremental[monthIdx];
  const productInfo = pricingFlow.pricingSheetData.productInfo[product.id];
  if (isNil(productInfo)) {
    return null;
  }
  const estMonthlyRevenue = estimatedMonthlyRevenue(
    product,
    productInfo,
    pricingFlow.additionalData.subscriptionTerms,
    monthIdx,
  );

  return (
    <>
      <td className="h-full flex flex-row items-center gap-2 bg-gray-100 px-6 py-4 pr-24 text-sm text-gray-500">
        <ArrowDownRightIcon
          className="h-4 w-4 text-gray-400"
          aria-hidden="true"
        />
        Month {monthNumber}
      </td>
      {/* Quantity */}
      <td className="whitespace-nowrap bg-gray-100 px-6 text-sm text-gray-500">
        <EditableIndicator className="flex flex-col justify-start items-start">
          <div className="w-full pl-2 text-gray-400 text-xs">
            {product.volume} +{' '}
          </div>
          <FormattedNumberField
            type="text"
            value={incrementalVolumeAtMonth?.value ?? 0}
            required={true}
            numberDecimals={0}
            className="cursor-pointer border-none bg-transparent text-gray-500 outline-none focus:border-none focus:ring-0 focus:ring-transparent h-full w-28"
            data-volume-editable
            ref={inputRef}
            onKeyDown={getHandleKeyDownForEnterNextRowHandling(
              inputRef,
              'data-volume-editable',
            )}
            updateValue={(value: number) => {
              updateFlow(
                produce(pricingFlow, (draft) => {
                  const productToUpdate = draft.products.find(
                    (p) => p.id === product.id,
                  );
                  if (productToUpdate) {
                    productToUpdate.rampedVolumeIncremental[monthIdx] = {
                      type: 'count',
                      value,
                    };
                  }
                }),
                false,
              );
            }}
            disabled={!editMode}
          />
        </EditableIndicator>
      </td>
      {/* List price, proposed price, approval level, discount */}
      <td className="bg-gray-100" colSpan={4}></td>
      {/* Est. Monthly revenue */}
      <td className="whitespace-nowrap bg-gray-100 px-6 py-4 text-sm font-medium text-gray-500">
        {formatNumberAsUSDCV(estMonthlyRevenue, 2)}
      </td>
      {/* Trash icon */}
      <td className="bg-gray-100"></td>
    </>
  );
}

interface HamsterQuoteTableProps {
  approvalsNeeded: ApprovalsDisplay;
}
export default function HamsterQuoteTable(props: HamsterQuoteTableProps) {
  const { pricingFlow, editMode } = usePricingFlowContext<HamsterPricingFlow>();
  const [showProductSelectionModal, setShowProductSelectionModal] =
    useState(false);
  const { approvalsNeeded } = props;
  return (
    <div className="my-2 w-full self-stretch">
      {/* Outer container with border and rounded corners */}
      <div className="rounded-xl border border-gray-200 bg-white overflow-hidden">
        {/* Scrollable container */}
        <div className="overflow-auto">
          <table className="h-full min-w-full border-separate border-spacing-0">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="sticky top-0 z-10 w-full border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter first:rounded-tl-xl"
                >
                  Products
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  Quantity
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  List Price
                </th>
                <th
                  scope="col"
                  className="has-tooltip sticky top-0 z-20 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  Proposed Price
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  Discount
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  Approval Level
                </th>
                <th
                  scope="col"
                  className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap"
                >
                  Est. Monthly Revenue
                </th>
                {/* trash icon */}
                <th className="sticky top-0 z-10 border-b bg-gray-50 px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter xl:whitespace-nowrap last:rounded-tr-xl"></th>
              </tr>
            </thead>
            <tbody>
              {pricingFlow.products.length > 0 ? (
                pricingFlow.products
                  .sort((a, b) => {
                    return (
                      (pricingFlow.pricingSheetData.productInfo[a.id]
                        ?.displayOrder ?? 0) -
                      (pricingFlow.pricingSheetData.productInfo[b.id]
                        ?.displayOrder ?? 0)
                    );
                  })
                  .map((product) => {
                    return (
                      <QuoteTableRow
                        product={product}
                        approvalsNeeded={approvalsNeeded}
                      />
                    );
                  })
              ) : (
                <QuoteTableBodyEmptyState colSpan={6} />
              )}
            </tbody>
            <tfoot>
              <tr>
                <th
                  scope="col"
                  colSpan={5}
                  className="bg-slate-50 px-6 py-3.5 text-left text-sm
                font-semibold text-gray-700 backdrop-blur backdrop-filter
                sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                >
                  <button
                    type="button"
                    className="mt-3 inline-flex items-center w-full justify-center rounded-lg bg-white px-3 py-2 text-sm font-semibold text-slate-900  ring-1 ring-inset ring-gray-200 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      setShowProductSelectionModal(true);
                    }}
                    disabled={!editMode}
                  >
                    <PlusIcon
                      className="-ml-0.5 mr-1 h-4 w-4"
                      aria-hidden="true"
                    />
                    Add / Edit Products
                  </button>
                </th>
                <th
                  scope="col"
                  colSpan={7}
                  className="bg-slate-50 px-6 py-3.5 text-left text-sm font-semibold text-gray-700 backdrop-blur backdrop-filter sm:table-cell xl:whitespace-nowrap rounded-bl-xl rounded-br-xl"
                ></th>
              </tr>
            </tfoot>
          </table>
        </div>
        <Transition appear show={showProductSelectionModal} as={Fragment}>
          <Dialog
            as="div"
            className="absolute z-50 items-center justify-center overflow-y-auto min-w-full"
            onClose={() => {
              setShowProductSelectionModal(false);
            }}
          >
            {/* This transitions the background to a dark shade */}
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/25" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto lg:px-20">
              <div className="min-h-full flex items-center justify-center px-8 py-4 text-center">
                <Transition.Child
                  className="w-full"
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <HamsterProductSelectionModal
                    closeModal={() => {
                      setShowProductSelectionModal(false);
                    }}
                  />
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
    </div>
  );
}
