import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Crepe } from '@milkdown/crepe';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import { InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import { User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import { PricingFlowGroup } from '../../Opportunity/types';
import PricingFlowList from '../PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../QuoteOptionsSection';
import { OpportunityCommon, PricingFlow } from '../types';
import AskForApprovalModal from './ApprovalModal';

interface PricingFlowRowProps {
  opportunity: OpportunityCommon;
  pricingFlow: PricingFlowOrSnapshotForNavigation;
  isChecked: boolean;
  setIsChecked: (newIsChecked: boolean) => void;
}
export function PricingFlowRow(props: PricingFlowRowProps) {
  const { isChecked, setIsChecked, pricingFlow, opportunity } = props;
  return (
    <div className="flex justify-between items-center border rounded-lg border-gray-200 p-2 mt-2">
      {/* left side */}
      <div className="flex items-center gap-x-4">
        <input
          className="rounded-sm border-gray-300 text-fuchsia-900 focus:ring-fuchsia-900 focus:border-fuchsia-900 pl-1"
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
        />
        <div className="flex flex-col">
          <span className="font-medium text-sm text-gray-950">
            {getNameForPricingFlow(pricingFlow, opportunity.pricingFlows)}
          </span>
          <span className="text-xs text-gray-600">
            Last updated {dayjs(pricingFlow.updatedAt).fromNow()}
          </span>
        </div>
      </div>
      {/* right side */}
      <div className="flex items-center pr-2">
        <Link
          to={`/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${pricingFlow.id}`}
          target="_blank"
          relative="path"
          className="text-sm font-semibold text-fuchsia-900"
        >
          <ArrowTopRightOnSquareIcon
            className="inline-block h-5 w-5"
            aria-hidden="true"
          />
        </Link>
      </div>
    </div>
  );
}

interface ApprovalOptionsModalProps {
  hideModal: () => void;
  pricingFlow: PricingFlow;
  opportunity: OpportunityCommon;
  user: User;
}
export default function ApprovalOptionsModal(props: ApprovalOptionsModalProps) {
  const { hideModal, opportunity, pricingFlow, user } = props;
  const [activeGroups, setActiveGroups] = useState<PricingFlowGroup[] | null>(
    null,
  );
  const [selectedPricingFlows, setSelectedPricingFlows] = useState<string[]>([
    pricingFlow.id,
  ]);
  const pricingFlows = opportunity.pricingFlows;
  const [crepeEditors, setCrepeEditors] = useState<
    Record<string, Crepe | null>
  >({});

  const { showModal } = useModal();

  useEffect(() => {
    async function fetchGroup() {
      const maybeActiveGroups = await api.get('pricingFlowGroups', {
        opportunityId: opportunity.id,
      });
      setActiveGroups(
        maybeActiveGroups.data.filter((g: PricingFlowGroup) =>
          isNil(g.archivedAt),
        ),
      );
    }
    fetchGroup();
  }, []);

  if (isNil(activeGroups)) return <InlineSpinner />;

  function submitOnlyThisQuote() {
    showModal({
      newStyle: true,
      title: 'Ask for approval',
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={[pricingFlow]}
          user={user}
          opportunity={opportunity}
        />
      ),
    });
  }

  function submitSelectedQuotes() {
    showModal({
      newStyle: true,
      title: 'Ask for approval',
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={selectedPricingFlows
            .map((id) => pricingFlows.find((p) => p.id === id))
            .filter((p) => p !== undefined)}
          user={user}
          opportunity={opportunity}
        />
      ),
    });
  }

  function submitGroup(params: {
    group: PricingFlowGroup;
    newPricingFlow?: PricingFlow;
  }) {
    showModal({
      newStyle: true,
      title: 'Ask for approval',
      children: (
        <AskForApprovalModal
          hideModal={hideModal}
          pricingFlows={
            params.newPricingFlow
              ? [
                  params.newPricingFlow,
                  ...params.group.pricingFlowOnGroup.map((p) => p.pricingFlow),
                ]
              : params.group.pricingFlowOnGroup.map((p) => p.pricingFlow)
          }
          group={params.group}
          user={user}
          opportunity={opportunity}
        />
      ),
    });
  }

  const Footer = (
    <div className="mt-4 shrink-0 border-t border-100">
      <div className="flex flex-row justify-between p-4 gap-4 sm:flex-row-reverse">
        {selectedPricingFlows.length > 1 ? (
          <Button
            color="primary"
            onClick={() => submitSelectedQuotes()}
            className="flex-1"
            label="Submit quotes in group"
          />
        ) : (
          <Button
            color="primary"
            onClick={submitOnlyThisQuote}
            className="flex-1"
            label="Submit only this quote"
          />
        )}
        <Button
          color="white"
          onClick={() => hideModal()}
          className="flex-1"
          label="Cancel"
        />
      </div>
    </div>
  );

  const activeGroupsWithThisQuote = activeGroups.filter((group) => {
    return group.pricingFlowOnGroup.some(
      (p) => p.pricingFlowId === props.pricingFlow.id,
    );
  });
  // Pricing flow is in an active group
  if (activeGroupsWithThisQuote.length > 0) {
    return (
      <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
        {/* Scrollable Options Section */}
        <div className="p-4 flex flex-col overflow-hidden flex-1">
          <span className="font-medium text-sm text-slate-600 mb-4">
            This quote is in an active group. Do you want to submit it with the
            group?
          </span>
          <div className="overflow-y-auto flex-1">
            <div className="space-y-2">
              {activeGroupsWithThisQuote.map((group) => {
                return (
                  <div className="flex flex-col gap-2" key={group.id}>
                    <span className="font-medium text-sm text-gray-900">
                      {group.name}
                    </span>
                    <div className="p-2 border border-gray-200 rounded-lg space-y-4">
                      <PricingFlowList
                        pricingFlows={group.pricingFlowOnGroup.map(
                          (p) => p.pricingFlow,
                        )}
                        modelType="pricingFlow"
                        user={user}
                        hasApprovals
                        hideUserActionButtons
                        purpose="ApprovalOptionsModalActiveGroup"
                        crepes={crepeEditors}
                        setCrepes={setCrepeEditors}
                        contextEditableMode="no-edit"
                      />
                    </div>
                    <Button
                      color="primary"
                      onClick={() => {
                        submitGroup({ group });
                      }}
                      label="Submit quotes in group"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {Footer}
      </div>
    );
  }
  // Pricing flow is not in the active group, ask if you want to add it
  if (activeGroups.length > 0) {
    return (
      <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
        {/* Scrollable Options Section */}
        <div className="p-4 flex flex-col overflow-hidden flex-1">
          <span className="font-medium text-sm text-slate-600 mb-4">
            This quote is not in an active group. Do you want to add it to a
            group and submit for approval?
          </span>
          <div className="overflow-y-auto flex-1">
            <div className="space-y-4 divide divide-gray-200">
              {activeGroups.map((group) => {
                return (
                  <div className="flex flex-col gap-2" key={group.id}>
                    <span className="font-medium text-sm text-gray-900">
                      {group.name}
                    </span>
                    <div className="p-2 border border-gray-200 rounded-lg space-y-4">
                      <PricingFlowList
                        pricingFlows={group.pricingFlowOnGroup.map(
                          (p) => p.pricingFlow,
                        )}
                        modelType="pricingFlow"
                        user={user}
                        hasApprovals
                        hideUserActionButtons
                        purpose="ApprovalOptionsModalNoActiveGroup"
                        crepes={crepeEditors}
                        setCrepes={setCrepeEditors}
                        contextEditableMode="no-edit"
                      />
                    </div>
                    <Button
                      color="primary"
                      onClick={() => {
                        submitGroup({ group, newPricingFlow: pricingFlow });
                      }}
                      label="Add to group and submit"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        {Footer}
      </div>
    );
  }
  // No active group, ask if you want to create a new group with this pricing flow
  if (activeGroups.length === 0 && pricingFlows.length > 1) {
    return (
      <div className="min-h-[540px] h-[75vh] flex-1 flex flex-col overflow-hidden">
        {/* Scrollable Options Section */}
        <div className="px-4 pb-4 flex flex-col overflow-hidden flex-1">
          <span className="font-medium text-sm text-gray-900 mb-2">
            Do you want to create a group with this quote to submit for
            approval?
          </span>
          <div className="overflow-y-auto flex-1">
            <div className="space-y-2">
              {pricingFlows.map((p) => {
                return (
                  <PricingFlowRow
                    key={p.id}
                    opportunity={opportunity}
                    pricingFlow={p}
                    isChecked={
                      pricingFlow.id === p.id ||
                      selectedPricingFlows.includes(p.id)
                    }
                    setIsChecked={(newIsChecked) => {
                      if (pricingFlow.id === p.id) return;
                      if (newIsChecked) {
                        setSelectedPricingFlows([
                          ...selectedPricingFlows,
                          p.id,
                        ]);
                      } else {
                        setSelectedPricingFlows(
                          selectedPricingFlows.filter((id) => id !== p.id),
                        );
                      }
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>

        {Footer}
      </div>
    );
  }
  // No active group and no other pricing flows, so just go to approval modal
  if (activeGroups.length === 0 && pricingFlows.length === 1) {
    submitOnlyThisQuote();
  }

  return null;
}
