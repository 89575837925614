import { useState } from 'react';
import { Organization, User } from '../../../types';
import { PricingFlowStage } from '../types';
import FlowProgressBar from './Components/FlowProgressBar';
import {
  PenguinAdditionalData,
  PenguinCustomStageType,
  PenguinPricingFlow,
  PenguinProduct,
} from './penguin_types';
import Step2ProductSelection, {
  productRequiresTransactionSize,
} from './Steps/Step2ProductSelection';
import Step3VolumeSelection from './Steps/Step3VolumeSelection';

import { isNil } from 'lodash';
import { useToast } from 'src/components/Toast';
import { usePricingFlowContext } from '../PricingFlow';
import PenguinPricingFlowNotStartedPage, {
  validateNotStartedFields,
} from './Components/PricingFlowNotStartedPage';
import Step1ModelSelection from './Steps/Step1ModelSelection';
import Step4PricingCalculator from './Steps/Step4PricingCalculator';
import { VolumeSuggestionsContextProvider } from './Steps/VolumeSuggestionsContext';

export const PenguinFlowSteps = [
  PenguinCustomStageType.OPP_OVERVIEW,
  PenguinCustomStageType.MODEL_SELECTION,
  PenguinCustomStageType.PRODUCT_SELECTION,
  PenguinCustomStageType.VOLUME_INPUTS,
  PenguinCustomStageType.PRICING_ADJUSTMENTS,
];

interface StepValidationsType {
  [key: string]: (pricingFlow: PenguinPricingFlow) => {
    value: boolean;
    error: string | null;
  };
}

const StepValidations: StepValidationsType = {
  [PenguinCustomStageType.OPP_OVERVIEW]: (pricingFlow: PenguinPricingFlow) => {
    return validateNotStartedFields({
      startDate: pricingFlow.additionalData?.startDate,
      subscriptionTerms: pricingFlow.additionalData?.subscriptionTerms,
    });
  },
  [PenguinCustomStageType.MODEL_SELECTION]: (
    pricingFlow: PenguinPricingFlow,
  ) => {
    return { value: true, error: null };
  },
  [PenguinCustomStageType.PRODUCT_SELECTION]: (
    pricingFlow: PenguinPricingFlow,
  ) => {
    const hasProducts = pricingFlow.products && pricingFlow.products.length > 0;
    if (!hasProducts) {
      return {
        value: false,
        error: 'Please select at least one product to continue',
      };
    }
    const txnSizesAllNonZero = pricingFlow.products.every(
      (p: PenguinProduct) => {
        const productPrice = pricingFlow.pricingSheetData.productInfo[p.id];
        if (
          (productPrice &&
            productRequiresTransactionSize(productPrice, false) &&
            isNil(p.transactionSize)) ||
          p.transactionSize?.value === 0
        ) {
          return false;
        }
        return true;
      },
    );
    if (!txnSizesAllNonZero) {
      return { value: false, error: 'Please set transaction sizes to > 0' };
    }
    return { value: true, error: null };
  },
  [PenguinCustomStageType.VOLUME_INPUTS]: (pricingFlow: PenguinPricingFlow) => {
    switch (pricingFlow.type) {
      // @ts-ignore I'm not sure how we stuffed COMPLEX_DEMO into here
      case 'COMPLEX_DEMO':
        const complexDemoValue =
          pricingFlow.products &&
          pricingFlow.products.every((product) => {
            if (typeof product.volume == 'number') {
              return product.volume > 0; // @TODO(fayplaid) remove
            } else {
              return product.volume != null && product.volume.value > 0;
            }
          });
        return {
          value: complexDemoValue,
          error: complexDemoValue
            ? null
            : 'Please select volume greater than 0 for all products to continue',
        };
      case 'PENGUIN':
      default:
        return {
          value: true,
          error: null,
        };
    }
  },
};

export interface PricingFlowProps {
  user: User;
  organization: Organization;
}

export default function PenguinPricingFlowPage(props: PricingFlowProps) {
  const organization = props.organization;
  const { pricingFlow, updateFlow, editMode, setStage } =
    usePricingFlowContext<PenguinPricingFlow>();

  console.log('PRICING FLOW: ', pricingFlow);

  const additionalData = pricingFlow?.additionalData as PenguinAdditionalData;
  const [selectedProductCategories, setSelectedProductCategories] = useState<
    string[]
  >(
    (pricingFlow.additionalData as PenguinAdditionalData)?.productCategories ??
      [],
  );
  // const [currentStep, setCurrentStep] = useState<string>(
  //   additionalData?.customStage ?? OPP_OVERVIEW,
  // );
  const currentStep =
    additionalData?.customStage ?? PenguinCustomStageType.OPP_OVERVIEW;

  const { showToast } = useToast();

  const setFlowProgressBarStage = (stage: PenguinCustomStageType) => {
    const targetStepIndex = PenguinFlowSteps.indexOf(stage);

    for (let i = 0; i < targetStepIndex; i++) {
      const validationStep = PenguinFlowSteps[i];
      const validationResult = StepValidations[validationStep](pricingFlow);
      if (!validationResult.value) {
        showToast({
          title: validationResult.error ?? 'Please complete all previous steps',
          subtitle: '',
          type: 'error',
        });
        stage = validationStep;
        break;
      }
    }

    if (stage === currentStep) {
      return;
    }
    if (!editMode) {
      setStage({
        stage: null,
        customStage: stage,
      });
      return;
    }

    let products = pricingFlow.products;
    if (stage === PenguinCustomStageType.PRICING_ADJUSTMENTS) {
      // make sure all products have volume set if we're going to the pricing adjustments stage
      products = products.map((product: PenguinProduct) => {
        if (product.volume != null) return product;
        return {
          ...product,
          volume: {
            value: 0,
            type: 'count',
          },
        };
      });
    }

    updateFlow({
      ...pricingFlow,
      products,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: stage,
      },
    });
  };

  const nextStage = () => {
    const currentStageIndex = PenguinFlowSteps.indexOf(currentStep);
    const nextStage =
      PenguinFlowSteps[
        Math.min(PenguinFlowSteps.length - 1, currentStageIndex + 1)
      ];
    if (!editMode) {
      setStage({ stage: null, customStage: nextStage });
      return;
    }

    let products = pricingFlow.products;
    if (nextStage === PenguinCustomStageType.PRICING_ADJUSTMENTS) {
      // make sure all products have volume set if we're going to the pricing adjustments stage
      products = products.map((product: PenguinProduct) => {
        if (product.volume != null) return product;
        return {
          ...product,
          volume: {
            value: 0,
            type: 'count',
          },
        };
      });
    }

    updateFlow({
      ...pricingFlow,
      products,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: nextStage,
      },
    });
  };

  const previousStage = () => {
    const currentStageIndex = PenguinFlowSteps.indexOf(currentStep);

    let previousStage = PenguinFlowSteps[Math.max(0, currentStageIndex - 1)];
    const hasCategories =
      Object.keys(pricingFlow.pricingSheetData?.categories ?? {}).length > 0;
    if (
      !hasCategories &&
      previousStage === PenguinCustomStageType.MODEL_SELECTION
    ) {
      previousStage = PenguinCustomStageType.OPP_OVERVIEW;
    }

    if (!editMode) {
      setStage({
        stage:
          previousStage === PenguinCustomStageType.OPP_OVERVIEW
            ? PricingFlowStage.NOT_STARTED
            : null,
        customStage: previousStage,
      });
      return;
    }

    updateFlow({
      ...pricingFlow,
      additionalData: {
        ...(pricingFlow.additionalData as PenguinAdditionalData),
        customStage: previousStage,
      },
      ...(previousStage === PenguinCustomStageType.OPP_OVERVIEW
        ? { stage: PricingFlowStage.NOT_STARTED }
        : {}),
    });
  };

  console.log(currentStep);
  if (currentStep === PenguinCustomStageType.OPP_OVERVIEW) {
    // We don't show the FlowProgressBar for NOT_STARTED stage + OPP_OVERVIEW custom stage
    return (
      <PenguinPricingFlowNotStartedPage
        validateStep={StepValidations[PenguinCustomStageType.OPP_OVERVIEW]}
        user={props.user}
      />
    );
  }

  let currentStepSection = null;
  switch (currentStep) {
    case PenguinCustomStageType.MODEL_SELECTION:
      currentStepSection = (
        <Step1ModelSelection
          selectedProductCategories={selectedProductCategories}
          setSelectedProductCategories={setSelectedProductCategories}
          nextStage={nextStage}
          previousStage={previousStage}
          user={props.user}
          organization={organization}
        />
      );
      break;
    case PenguinCustomStageType.PRODUCT_SELECTION:
      currentStepSection = (
        <Step2ProductSelection
          user={props.user}
          previousStage={previousStage}
          validateStep={
            StepValidations[PenguinCustomStageType.PRODUCT_SELECTION]
          }
        />
      );
      break;
    case PenguinCustomStageType.VOLUME_INPUTS:
      currentStepSection = (
        <VolumeSuggestionsContextProvider>
          <Step3VolumeSelection
            user={props.user}
            previousStage={previousStage}
            validateStep={StepValidations[PenguinCustomStageType.VOLUME_INPUTS]}
          />
        </VolumeSuggestionsContextProvider>
      );
      break;
    case PenguinCustomStageType.PRICING_ADJUSTMENTS:
      currentStepSection = (
        <Step4PricingCalculator previousStage={previousStage} />
      );
      break;
    default:
      currentStepSection = null;
  }

  return (
    <div className="flex h-full flex-col">
      <FlowProgressBar
        stage={currentStep}
        setStage={setFlowProgressBarStage}
        customSteps={PenguinFlowSteps}
      />
      {currentStepSection}
    </div>
  );
}
