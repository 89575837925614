import { User } from 'src/types';
import {
  PricingCurve,
  PricingFlowCommon,
  PricingFlowType,
  ProductCommon,
} from '../types';
import {
  Count,
  CurrencyValueFlat,
  CurrencyValueTiered,
} from '../types_common/price';

/**
 *
 * NOTE: YOU CAN ONLY ADD TO THESE TYPES, not remove.
 * And, you must update the types in 2024_10_15__initial_approval_rules.ts
 *
 */

type BillingFrequency =
  | 'annual_upfront'
  | 'semi_annual'
  | 'quarterly'
  | 'other';
type PaymentTerms = 'net_30' | 'net_60' | 'net_90' | 'other';

export interface HamsterOpportunityData {
  Owner: string | null;
  // Enterprise | Mid-Market | Self-Serve | Co-Sell
  Segment: string | null;
  // New to Harvey | Renewal | Expansion
  Type: string | null;
  Country: string | null;
  Number_of_lawyers: number | null;
  ARR: number | null;
  TCV: number | null;
  Net_New_ACV: number | null;
  Baseline_ACV__c: number;
  AccountId: string;
}

export interface HamsterPricingInformation {
  listPrice: CurrencyValueTiered<CurrencyValueFlat, Count> | null;
  headOfMidmarketSales?: CurrencyValueTiered<CurrencyValueFlat, Count>;
  vpOfSales?: CurrencyValueTiered<CurrencyValueFlat, Count>;
  // if "true", approval is always required if this product is added
  dealDesk?: CurrencyValueTiered<CurrencyValueFlat, Count> | true;
  legal?: true;
  defaultVolume: number | null;
}
export type HamsterPricingCurve = PricingCurve & {
  pricingInformation: HamsterPricingInformation;
};
export interface HamsterAdditionalData {
  startDate: string | null; // YYYY-MM-DD
  subscriptionTerms: number;
  billingFrequency: BillingFrequency;
  // if billingFrequency === 'other', the rep can enter a free form description
  // of what they're doing
  billingFrequency_Other: string;
  paymentTerms: PaymentTerms;
  // if paymentTerms === 'other', the rep can enter a free form description
  // of what they're doing
  paymentTerms_Other: string;
  nonCommercialTerms: string;
  priceProtection: boolean;
  priceProtectionCap: number;
}
export type HamsterCV = CurrencyValueFlat;
export type HamsterQuotePrice =
  | HamsterCV
  | CurrencyValueTiered<HamsterCV, Count>;
export interface HamsterProduct extends ProductCommon {
  quotePrice: HamsterQuotePrice | null;
  // Note that:
  // - the volume numbers here are increases to the minimum seat count, not the
  //   raw number of seats provisioned at that month. To get that number, add the
  //   product.volume, which is the base seat count
  // - These are not volume forcasts, these are the actual number of seats
  //   they're selling!
  rampedVolumeIncremental: (Count | null)[];
  customName?: string;
}

export const HAMSTER_CATEGORY_NAME = [
  'New customers',
  'Existing customers',
] as const;
export type HamsterCategoryName = (typeof HAMSTER_CATEGORY_NAME)[number];
export const HAMSTER_SUBCATEGORY_NAME = ['Core', 'Add-ons', 'Other'] as const;
export type HamsterSubcategoryName = (typeof HAMSTER_SUBCATEGORY_NAME)[number];
export interface HamsterProductPrice {
  id: string;
  name: string;
  // only one product in an exclusive group can be selected at once
  exclusiveGroup: string | null;
  // number is the fixed volume value, null means the rep can edit it
  fixedVolume: number | null;
  isFreeformProduct: boolean;
  isSelectedByDefault: boolean;
  revenueFrequency: 'one_time' | 'monthly' | 'annual';
  isRebate: boolean;
  currentPricingCurve: HamsterPricingCurve;
  subcategoryMemberships: {
    subcategory: {
      name: HamsterSubcategoryName;
      category: { name: HamsterCategoryName };
    };
  }[];
  displayOrder: number;
  SalesforceProduct__fixedSelf__noParent: string | null;
}
export interface HamsterProductPrices {
  [productId: string]: HamsterProductPrice | undefined;
}
export interface HamsterPricingSheet {
  productInfo: HamsterProductPrices;
}

export interface HamsterPricingFlow extends PricingFlowCommon {
  type: PricingFlowType.HAMSTER;
  opportunity: PricingFlowCommon['opportunity'] & {
    opportunityData: HamsterOpportunityData;
  };
  additionalData: HamsterAdditionalData;
  products: HamsterProduct[];
  pricingSheetData: HamsterPricingSheet;
}

/**
 * APPROVALS
 */

export type StepSnapshot = {
  id: string;
  name: string;
};

type FieldsNeeded = {
  [fieldName: string]: {
    greatestStep: StepSnapshot;
    humanReadables: string[];
  };
};
type ProductsNeeded = {
  [productId: string]: {
    greatestStep: StepSnapshot;
    humanReadables: string[];
  };
};
export type ApprovalsDisplay = {
  fieldsNeeded: FieldsNeeded;
  productsNeeded: ProductsNeeded;
};

type ApprovalRuleEvaluationResult = {
  id: string;
  required: boolean;
  humanReadable: string;
  relatedProducts: string[];
  relatedFields: string[];
};

interface ApprovalFlowStepWithRuleDetails {
  snapshot: StepSnapshot;
  users: User[]; // result of running getUsers(), or the saved output of that function
  rules: ApprovalRuleEvaluationResult[];
}

export type ApprovalFlowWithRuleDetails = {
  steps: ApprovalFlowStepWithRuleDetails[];
};
