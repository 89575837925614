import { TrashIcon } from '@heroicons/react/24/outline';
import { classNames } from '../App';

interface TrashButtonProps {
  editMode: boolean;
  handleDelete: () => void;
}

export default function TrashButton(props: TrashButtonProps) {
  const { editMode, handleDelete } = props;
  return (
    <button
      className={classNames(
        'transition-opacity duration-200 ease-in-out hover:text-fuchsia-950 text-fuchsia-900 flex items-center',
        !editMode && 'hidden',
      )}
      tabIndex={-1}
      onClick={(e) => {
        e.preventDefault();
        handleDelete();
      }}
      disabled={!editMode}
    >
      <TrashIcon className="h-4 w-4" aria-hidden="true" />
    </button>
  );
}
