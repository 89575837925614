import { datadogRum } from '@datadog/browser-rum';
import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { replaceAll } from '@milkdown/kit/utils';
import { useEffect, useState } from 'react';
import Button from 'src/components/Button';
import { classNames } from 'src/dashboard/App';

const PLACEHOLDER = 'Add comment...';

// Generate a unique ID for each editor instance
let editorInstanceCounter = 0;

export function AddComment(props: {
  addComment: (comment: string) => void;
  onCancel?: () => void;
  threadId?: string; // Optional thread ID for replies
}) {
  const { addComment, onCancel, threadId } = props;
  const [crepeEditor, setCrepeEditor] = useState<Crepe | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Create a unique ID for this instance
  const [editorId] = useState(() => {
    editorInstanceCounter += 1;
    return `crepeeditor-${threadId ?? 'new'}-${editorInstanceCounter}`;
  });

  useEffect(() => {
    let isMounted = true;

    const initEditor = async () => {
      try {
        const newCrepe = new Crepe({
          root: `#${editorId}`,
          defaultValue: PLACEHOLDER,
        });

        newCrepe.setReadonly(false);
        await newCrepe.create();

        if (isMounted) {
          setCrepeEditor(newCrepe);
          console.log(`Crepe editor created: ${editorId}`);
        } else {
          // If component unmounted during initialization, clean up
          newCrepe.destroy();
        }
        // eslint-disable-next-line rulesdir/require-datadog-rum-error
      } catch (error) {
        console.error(`Failed to initialize Crepe editor ${editorId}:`, error);
      }
    };

    initEditor();

    return () => {
      isMounted = false;
      if (crepeEditor) {
        crepeEditor.destroy();
        console.log(`Crepe editor destroyed: ${editorId}`);
      }
    };
  }, [editorId]); // Now depends on editorId

  async function createComment() {
    if (!crepeEditor) {
      console.error('Crepe editor not initialized');
      return;
    }

    try {
      setIsSubmitting(true);
      const markdown = await crepeEditor.getMarkdown().trim();

      // Don't submit if it's just the placeholder or empty
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (markdown === PLACEHOLDER || !markdown.trim()) {
        return;
      }

      await addComment(markdown);

      // Reset editor content
      crepeEditor.editor.action(replaceAll(PLACEHOLDER));
    } catch (error) {
      console.error('Failed to create comment:', error);
      datadogRum.addError(error);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <div className="p-4 space-y-2">
      <div className="border border-slate-200 rounded-md">
        <div id={editorId} className={classNames('p-4 min-h-[60px]')} />
      </div>
      <div className="flex justify-end gap-2">
        {onCancel && (
          <Button
            color="ghost"
            label="Cancel"
            onClick={onCancel}
            disabled={isSubmitting}
          />
        )}
        <Button
          color="white"
          label={isSubmitting ? 'Adding...' : 'Comment'}
          onClick={createComment}
          disabled={isSubmitting}
        />
      </div>
    </div>
  );
}
