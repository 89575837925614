import { datadogRum } from '@datadog/browser-rum';
import axios, { AxiosError } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from 'src/api';
import { useToast } from 'src/components/Toast';
import { Organization, User } from 'src/types';
import {
  configForOrg,
  OpportunityDetailPageConfig,
} from './OpportunityDetailPage';
import { Opportunity } from './types';

export type OpportunityFieldUpdateData = {
  // info required to write back to sfdc
  sfdcObjectType: 'Account' | 'Opportunity';
  sfdcObjectId: string;
  sfdcField: string;
  // the actual value written back. Undefined writes nothing, null writes a
  // null value
  writeValue: string | number | null | undefined;
};

interface OpportunityContextType {
  opportunity: Opportunity | null;
  updateOpportunity: (
    updatedOpportunity: Opportunity,
    sfdcUpdates: OpportunityFieldUpdateData[],
  ) => Promise<void>;
  editMode: boolean;
  pageConfig: OpportunityDetailPageConfig;
}

export const OpportunityContext = createContext<
  OpportunityContextType | undefined
>(undefined);

export function useOpportunityContext() {
  const context = useContext(OpportunityContext);
  if (context === undefined) {
    throw new Error(
      'useOpportunityContext must be used within a OpportunityProvider',
    );
  }
  return context;
}

export function useOpportunity({
  user,
  organization,
  sfdcOppId,
}: {
  user: User;
  organization: Organization;
  sfdcOppId: string | undefined;
}) {
  const [opportunity, setOpportunity] = useState<Opportunity | null>(null);
  const navigate = useNavigate();
  const { showToast } = useToast();
  const editMode = user.permissions.includes('edit_pricing_flow');
  const pageConfig = configForOrg(organization.pricingFlowType);

  useEffect(() => {
    let isMounted = true;
    const fetchOrCreateOpportunity = async () => {
      try {
        const opportunityData = await (async () => {
          // try to get existing opportunity
          const existingOpportunityRes = await api.get('opportunity', {
            sfdcOpportunityId: sfdcOppId,
          });
          const { doesOpportunityExistInDealops, opportunityData } =
            existingOpportunityRes.data;
          if (doesOpportunityExistInDealops) {
            return opportunityData;
          } else {
            // otherwise, create an opportunity
            const newOpportunityRes = await api.post('opportunity', {
              sfdcOpportunityId: sfdcOppId,
            });
            const pricingFlows =
              pageConfig.shouldAutoRedirectToPricingFlow && isMounted
                ? [
                    (
                      await api.post('pricingflow', {
                        sfdcOpportunityId: sfdcOppId,
                      })
                    ).data,
                  ]
                : [];
            return {
              ...newOpportunityRes.data.opportunityData,
              pricingFlows,
            };
          }
        })();
        if (isMounted) {
          setOpportunity(opportunityData);
        }
      } catch (error) {
        datadogRum.addError(error);
        if (
          axios.isAxiosError(error) &&
          (error as AxiosError).response?.status === 404
        ) {
          showToast({
            title: 'No Opportunity was Found',
            subtitle: 'Opportunity ID: ' + sfdcOppId,
            type: 'error',
            autoDismiss: false,
          });
          navigate('/app/opportunity');
        } else {
          console.log('error loading opportunity: ', error);
        }
      }
    };
    fetchOrCreateOpportunity();
    return () => {
      isMounted = false;
    };
  }, [sfdcOppId]);

  const updateOpportunity = async (
    updatedOpportunity: Opportunity,
    sfdcUpdates: OpportunityFieldUpdateData[],
  ) => {
    if (!editMode) {
      showToast({
        title: 'You do not have permission to update this opportunity',
        subtitle: 'Please contact support@dealops.com',
        type: 'error',
      });
      return;
    }

    try {
      await api.put(`opportunity/${updatedOpportunity.id}`, {
        updatedOpportunity,
        sfdcUpdates,
      });
      setOpportunity(updatedOpportunity);
    } catch (error) {
      showToast({
        title: 'Failed to update opportunity',
        subtitle: 'Please try again or contact support',
        type: 'error',
      });
      datadogRum.addError(error);
    }
  };

  return {
    opportunity,
    updateOpportunity,
    editMode,
    pageConfig,
  };
}
