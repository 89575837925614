import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'src/api';
import { InlineSpinner } from 'src/components/Loading';
import { getNameForPricingFlow } from 'src/utils';
import { classNames } from '../App';
import { PricingFlowOrSnapshotForNavigation } from '../PricingFlow/QuoteOptionsSection';
import { OpportunityCommon, PricingFlowCommon } from '../PricingFlow/types';

type Tab = 'from_this_opp' | 'any_other_opp';

interface CloneQuoteModalProps {
  opportunity: OpportunityCommon;
  otherPricingFlows: PricingFlowCommon[];
  hideModal: () => void;
}
export default function CloneQuoteModal(props: CloneQuoteModalProps) {
  const { opportunity, otherPricingFlows, hideModal } = props;
  const oppHasPricingFlows = opportunity.pricingFlows.length > 0;
  const navigate = useNavigate();
  const [tab, setTab] = useState<Tab>(
    oppHasPricingFlows ? 'from_this_opp' : 'any_other_opp',
  );
  const [isLoadingNewPricingFlow, setIsLoadingNewPricingFlow] =
    useState<boolean>(false);
  const createNewPricingFlowAndNavigate = async (
    flowToCloneId: string | null,
  ) => {
    setIsLoadingNewPricingFlow(true);
    const newPricingFlow = (
      await api.post('pricingFlow', {
        sfdcOpportunityId: opportunity.sfdcOpportunityId,
        cloneFromPricingFlowId: flowToCloneId,
      })
    ).data;
    navigate(
      `/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${newPricingFlow.id}`,
    );
    hideModal();
  };
  const flowsToShow: {
    opportunity: OpportunityCommon;
    flow: PricingFlowOrSnapshotForNavigation;
  }[] =
    tab === 'from_this_opp'
      ? opportunity.pricingFlows.map((flow) => {
          return { opportunity, flow };
        })
      : otherPricingFlows.map((flow) => {
          return { opportunity: flow.opportunity, flow };
        });
  return (
    <div>
      {/* tabs */}
      <div className="w-full border-t border-b border-gray-200 flex">
        {oppHasPricingFlows && (
          <button
            className={classNames(
              'font-semibold text-sm py-2 px-4',
              tab === 'from_this_opp'
                ? 'text-fuchsia-900  border-b-2 border-fuchsia-900'
                : 'text-slate-500',
            )}
            onClick={() => setTab('from_this_opp')}
            disabled={tab === 'from_this_opp'}
          >
            From this opp
          </button>
        )}
        {otherPricingFlows.length > 0 && (
          <button
            className={classNames(
              'font-semibold text-sm py-2 px-4',
              tab === 'any_other_opp'
                ? 'text-fuchsia-900 border-b-2 border-fuchsia-900'
                : 'text-slate-500',
            )}
            onClick={() => setTab('any_other_opp')}
            disabled={tab === 'any_other_opp'}
          >
            Any other opp
          </button>
        )}
      </div>
      {/* content */}
      <div>
        {flowsToShow.map(({ flow, opportunity }) => {
          const title = getNameForPricingFlow(flow, opportunity.pricingFlows);
          return (
            <div
              key={flow.id}
              className="flex items-center justify-between px-4 pt-4"
            >
              {/* left */}
              <div className="flex items-center h-full gap-x-2">
                <span className="text-sm text-gray-950">
                  {opportunity.sfdcOpportunityName}
                </span>
                <span className="text-xs text-gray-600">{title}</span>
              </div>
              {/* right */}
              <div className="flex items-center h-full gap-x-3">
                <Link
                  to={`/app/opportunity/${opportunity.sfdcOpportunityId}/pricingflow/${flow.id}`}
                  target="_blank"
                >
                  <ArrowTopRightOnSquareIcon className="h-4 w-4 text-fuchsia-900" />
                </Link>
                <button
                  disabled={isLoadingNewPricingFlow}
                  className="border-gray-200 bg-white text-black hover:bg-gray-200 focus-visible:outline-fuchsia-900 col-span-full inline-flex items-center justify-center whitespace-nowrap rounded-lg border px-2 py-1 font-medium text-sm shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  onClick={() => createNewPricingFlowAndNavigate(flow.id)}
                >
                  {isLoadingNewPricingFlow ? <InlineSpinner /> : 'Clone'}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
