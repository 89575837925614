import { datadogRum } from '@datadog/browser-rum';
import { DocumentTextIcon } from '@heroicons/react/24/outline';
import { Crepe } from '@milkdown/crepe';
import dayjs from 'dayjs';
import { isNil } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Button from 'src/components/Button';
import CollapsibleSection from 'src/components/CollapsibleSection';
import EditableTitle from 'src/components/EditableTitle';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useModal } from 'src/components/Modal';
import { classNames } from 'src/dashboard/App';
import { useOpportunityContext } from 'src/dashboard/Opportunity/OpportunityContext';
import {
  Context,
  OpportunityInfo,
} from 'src/dashboard/Opportunity/OpportunityDetailPage';
import ApprovalOptionsModal from 'src/dashboard/PricingFlow/Approvals/ApprovalOptionsModal';
import { Organization, User } from 'src/types';
import { getNameForPricingFlow } from 'src/utils';
import {
  Activity,
  ActivitySection,
  CommentThread,
} from '../Activity/ActivitySection';
import { ApprovalRequest } from '../Approvals/types';
import BottomBar from '../Penguin/Components/BottomBar';
import { usePricingFlowContext } from '../PricingFlow';
import PricingFlowList from '../PricingFlowList';
import { PricingFlowCommon } from '../types';
import HamsterAnnualRevenueTable from './HamsterAnnualRevenueTable';
import HamsterExitModal from './HamsterExitModal';
import HamsterQuoteTable from './HamsterQuoteTable';
import HamsterTermsSection from './HamsterTermsSection';
import { ApprovalsDisplay, HamsterPricingFlow } from './hamster_types';

interface HamsterPricingFlowPageProps {
  user: User;
  organization: Organization;
  viewMode?: 'group';
}

export default function HamsterPricingFlowPage(
  props: HamsterPricingFlowPageProps,
) {
  const { showModal, hideModal } = useModal();
  const {
    pricingFlow,
    modelType,
    editMode,
    updateFlow,
    currentApprovalRequest,
  } = usePricingFlowContext<HamsterPricingFlow>();
  const { opportunity, pageConfig, updateOpportunity } =
    useOpportunityContext();

  const [approvalsNeeded, setApprovalsNeeded] = useState<ApprovalsDisplay>({
    fieldsNeeded: {},
    productsNeeded: {},
  });
  const [showActivity, setShowActivity] = useState(
    !isNil(currentApprovalRequest),
  );
  const [crepeEditors, setCrepeEditors] = useState<
    Record<string, Crepe | null>
  >({});
  const [showOpportunityInfo, setShowOpportunityInfo] = useState(
    props.viewMode !== 'group' && !isNil(currentApprovalRequest),
  );

  const showSidePanel = showActivity || showOpportunityInfo;

  const activityRef = useRef<HTMLDivElement>(null);
  const opportunityRef = useRef<HTMLDivElement>(null);
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    if (scroll && showActivity && activityRef.current) {
      activityRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScroll(false);
    }
  }, [showActivity, scroll]);

  useEffect(() => {
    if (scroll && showOpportunityInfo && opportunityRef.current) {
      opportunityRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
      setScroll(false);
    }
  }, [showOpportunityInfo, scroll]);

  const showExitModal = () => {
    showModal({
      // exit modal with suggested actions
      title: 'What do you want to do next?',
      children: (
        <HamsterExitModal
          hideModal={hideModal}
          pricingFlow={pricingFlow}
          onSubmitForApproval={() =>
            showModal({
              newStyle: true,
              title: 'Ask for approval',
              children: (
                <ApprovalOptionsModal
                  hideModal={hideModal}
                  pricingFlow={pricingFlow}
                  opportunity={pricingFlow.opportunity}
                  user={props.user}
                />
              ),
            })
          }
        />
      ),
    });
  };
  const navigate = useNavigate();

  useEffect(() => {
    // Calculate approvals needed
    // Assumes you have pageConfig.hasApprovals
    async function calculate() {
      const response = await api.get(
        `approvals/flows/calculate/fields?pricingFlowId=${pricingFlow.id}&modelType=${modelType}`,
      );
      if (response.status !== 200) {
        console.error('Failed to calculate approvals needed');
      } else {
        console.log(response.data);
        setApprovalsNeeded(response.data);
      }
    }
    calculate();
  }, [pricingFlow]);

  return (
    <div>
      {props.viewMode !== 'group' && (
        <HeaderBreadcrumbs
          steps={[
            {
              label: 'Opportunities',
              onClick: () => {
                navigate('/app/opportunity');
              },
            },
            {
              label: `${pricingFlow.opportunity.sfdcOpportunityName}`,
              onClick: () => {
                navigate(
                  `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}`,
                );
              },
            },
            {
              label: getNameForPricingFlow(
                pricingFlow,
                pricingFlow.opportunity.pricingFlows,
              ),
              onClick: () => {},
            },
          ]}
        />
      )}
      {/* Header */}
      <div
        className={classNames(
          'flex flex-col gap-4 border-b border-t border-gray-200 px-6 py-4 lg:flex-row lg:justify-between',
          !(props.viewMode === 'group') && 'mt-4',
        )}
      >
        <div className="flex flex-row items-center gap-2">
          {/* Currency icon */}
          <div className="h-12 w-12">
            <div className="absolute flex h-12 w-12 items-center justify-center rounded-full border border-gray-200 bg-white shadow">
              <DocumentTextIcon className="h-5 w-5" aria-hidden="true" />
            </div>
          </div>

          <div className="flex flex-col">
            <EditableTitle
              title={getNameForPricingFlow(
                pricingFlow,
                pricingFlow.opportunity.pricingFlows,
              )}
              updateTitle={(title: string) => {
                updateFlow({ ...pricingFlow, name: title }, false);
              }}
              disabled={!editMode}
            />
            <p className="text-sm text-gray-600 pl-2">
              Configure terms and products
            </p>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <Button
            color="white"
            label={
              showOpportunityInfo
                ? 'Hide opportunity info'
                : 'Show opportunity info'
            }
            onClick={() => {
              setShowOpportunityInfo(!showOpportunityInfo);
              setScroll(!showOpportunityInfo);
            }}
          />
          <Button
            color="white"
            label={showActivity ? 'Hide activity' : 'Show activity'}
            onClick={() => {
              setShowActivity(!showActivity);
              setScroll(!showActivity);
            }}
          />
        </div>
      </div>
      {/* Warning about viewing a snapshot */}
      {modelType === 'pricingFlowSnapshot' && (
        <div className="border-b border-gray-200 px-8 py-4 bg-fuchsia-50 flex items-center gap-x-4 text-gray-700 text-sm">
          <span>
            Viewing a snapshot taken {dayjs(pricingFlow.createdAt).fromNow()}
          </span>
          <Button
            color="white"
            onClick={() => {
              navigate(
                `/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/pricingFlow/${pricingFlow.originalPricingFlowId}`,
              );
            }}
          >
            Go to current version
          </Button>
        </div>
      )}
      {/* Approval section */}
      <div className="border-b border-gray-200 px-6 py-4 bg-neutral-50">
        <PricingFlowList
          pricingFlows={[pricingFlow]}
          modelType={modelType}
          user={props.user}
          hasApprovals
          hideName
          purpose="PricingFlowPage"
          crepes={crepeEditors}
          setCrepes={setCrepeEditors}
          contextEditableMode={
            modelType === 'pricingFlowSnapshot' ? 'no-edit' : 'default'
          }
        />
      </div>
      {/* Page contents */}
      <div className="flex flex-col xl:flex-row overflow-hidden">
        <div className="px-6 py-4 flex-1 min-w-0">
          <CollapsibleSection title="TERMS">
            <HamsterTermsSection approvalsNeeded={approvalsNeeded} />
          </CollapsibleSection>
          <CollapsibleSection title="PRICING">
            <HamsterQuoteTable approvalsNeeded={approvalsNeeded} />
          </CollapsibleSection>
          <div className="flex justify-end mt-6">
            <HamsterAnnualRevenueTable />
          </div>
          <div className="xl:h-64" />
        </div>
        <div
          className={`transform transition-all duration-300 ease-in-out px-4 mb-24 xl:py-2 xl:pr-2 xl:pl-0 xl:mb-0 overflow-hidden min-w-0 flex flex-col gap-y-2
        ${showSidePanel ? 'w-full xl:flex-none xl:basis-[460px] opacity-100' : 'w-0 opacity-0'}`}
        >
          <div ref={opportunityRef} className="flex flex-col gap-y-2">
            {showOpportunityInfo && opportunity && (
              <>
                <Context
                  opportunity={opportunity}
                  updateOpportunity={updateOpportunity}
                  pageConfig={pageConfig}
                />
                <OpportunityInfo
                  opportunity={opportunity}
                  pageConfig={pageConfig}
                  titleOverride="Opportunity Overview"
                />
              </>
            )}
          </div>
          <div ref={activityRef}>
            {showActivity && (
              <PricingFlowActivity
                pricingFlow={pricingFlow}
                user={props.user}
              />
            )}
          </div>
        </div>
      </div>
      {!(props.viewMode === 'group') && (
        <BottomBar
          primaryButtonProps={{
            label: 'Finish',
            successLabel: 'Finish',
            errorLabel: 'Error',
            onClick: async () => {
              showExitModal();
              return 'idle';
            },
          }}
        />
      )}
    </div>
  );
}

export async function getPricingFlowActivities(params: {
  pricingFlow: PricingFlowCommon;
  user: User;
  includeComments?: boolean;
  parentTag?: string;
}): Promise<Activity[]> {
  const { pricingFlow, user, includeComments, parentTag } = params;
  let activities: Activity[] = [];
  try {
    // Fetch created event
    const createdByUser =
      pricingFlow.createdByUserId === user.id
        ? user
        : (await api.get('users/' + pricingFlow.createdByUserId)).data;
    activities.push({
      type: 'system',
      text: 'created quote',
      user: createdByUser,
      timestamp: pricingFlow.createdAt,
      parentTag,
    });

    // Fetch comments if needed
    if (includeComments) {
      const comments = (
        await api.get(
          `comments/threads/pricingFlow/${pricingFlow.originalPricingFlowId}`,
        )
      ).data;
      activities = activities.concat(
        comments.map((c: CommentThread) => ({ ...c, parentTag })),
      );
    }

    // Fetch approval requests and approval actions
    const approvalRequests = (
      await api.get(
        `approvals/requests?pricingFlowId=${pricingFlow.originalPricingFlowId}`,
      )
    ).data as ApprovalRequest[];
    console.log('approvalRequests: ', approvalRequests);
    for (const request of approvalRequests) {
      // created
      activities.push({
        type: 'system',
        text: (
          <>
            submitted an{' '}
            <Link
              to={`/app/opportunity/${pricingFlow.opportunity.sfdcOpportunityId}/pricingFlowSnapshot/${request.pricingFlowSnapshotId}`}
              className="text-fuchsia-900 hover:underline"
            >
              approval request
            </Link>
          </>
        ),
        user: request.createdByUser,
        timestamp: request.createdAt,
        parentTag,
      });
      // actions
      for (const action of request.approvalActions) {
        activities.push({
          type: 'system',
          text: `${action.action.toLowerCase()}d`,
          user: action.user,
          timestamp: action.createdAt,
          parentTag,
        });
        if (action.recalledAt) {
          activities.push({
            type: 'system',
            text: `recalled ${action.action === 'APPROVE' ? 'approval' : 'rejection'}`,
            user: action.user,
            timestamp: action.recalledAt,
            parentTag,
          });
        }
      }
      // if recalled
      if (request.recalledAt) {
        activities.push({
          type: 'system',
          text: `recalled approval request`,
          user: request.createdByUser,
          timestamp: request.recalledAt,
          parentTag,
        });
      }
    }
  } catch (error) {
    datadogRum.addError(
      `Failed to fetch created by user for pricing flow ${pricingFlow.id}`,
    );
    console.error('Failed to fetch created by user: ', error);
  }
  return activities;
}

function PricingFlowActivity({
  pricingFlow,
  user,
}: {
  pricingFlow: HamsterPricingFlow;
  user: User;
}) {
  const [systemActivities, setSystemActivities] = useState<Activity[]>([]);
  useEffect(() => {
    async function fetchActivities() {
      // Fetch created by user
      const activities = await getPricingFlowActivities({ pricingFlow, user });
      setSystemActivities(activities);
    }
    fetchActivities();
  }, []);

  return (
    <ActivitySection
      parentType="pricingFlow"
      parentId={pricingFlow.id}
      systemActivities={systemActivities}
      user={user}
    />
  );
}
