import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams,
} from 'react-router-dom';
import { Organization, User } from '../../types';
import PricingFlowPageRoot, {
  PricingFlowSnapshotPageRoot,
} from '../PricingFlow/PricingFlow';
import PricingFlowGroupPage from '../PricingFlowGroup/PricingFlowGroup';
import { OpportunityContext, useOpportunity } from './OpportunityContext';
import OpportunityDetailPage from './OpportunityDetailPage';
import OpportunitySearchPage from './OpportunitySearchPage';

interface OpportunityRouterProps {
  user: User;
  organization: Organization;
}

export default function OpportunityRouter(props: OpportunityRouterProps) {
  const location = useLocation();

  const { sfdcOppId } = useParams();
  // Single redirect component that returns to the opportunity detail page
  const RedirectToOpportunity = () => {
    const { sfdcOppId } = useParams();
    return <Navigate to={`/app/opportunity/${sfdcOppId}`} replace />;
  };

  const OpportunityContextWrapper = (props: {
    user: User;
    organization: Organization;
    children: React.ReactNode;
  }) => {
    const { sfdcOppId } = useParams();
    const hooks = useOpportunity({
      user: props.user,
      organization: props.organization,
      sfdcOppId,
    });
    return (
      <OpportunityContext.Provider value={hooks}>
        {props.children}
      </OpportunityContext.Provider>
    );
  };

  return (
    <Routes>
      <Route
        index
        caseSensitive={false}
        element={
          <OpportunitySearchPage
            user={props.user}
            organization={props.organization}
          />
        }
      />
      <Route
        path=":sfdcOppId"
        caseSensitive={false}
        element={
          <OpportunityContextWrapper
            user={props.user}
            organization={props.organization}
          >
            <OpportunityDetailPage
              user={props.user}
              organization={props.organization}
            />
          </OpportunityContextWrapper>
        }
      />
      <Route
        path=":sfdcOppId/pricingflow"
        caseSensitive={false}
        element={<RedirectToOpportunity />}
      />
      <Route
        path=":sfdcOppId/pricingflow/:pricingFlowId"
        caseSensitive={false}
        element={
          <OpportunityContextWrapper
            user={props.user}
            organization={props.organization}
          >
            <PricingFlowPageRoot
              user={props.user}
              organization={props.organization}
            />
          </OpportunityContextWrapper>
        }
      />
      <Route
        path=":sfdcOppId/pricingflowsnapshot/:pricingFlowSnapshotId"
        caseSensitive={false}
        element={
          <OpportunityContextWrapper
            user={props.user}
            organization={props.organization}
          >
            <PricingFlowSnapshotPageRoot
              user={props.user}
              organization={props.organization}
            />
          </OpportunityContextWrapper>
        }
      />
      <Route
        path=":sfdcOppId/group"
        caseSensitive={false}
        element={<RedirectToOpportunity />}
      />
      <Route
        path=":sfdcOppId/group/:pricingFlowGroupId"
        caseSensitive={false}
        element={
          <OpportunityContextWrapper
            user={props.user}
            organization={props.organization}
          >
            <PricingFlowGroupPage
              user={props.user}
              organization={props.organization}
            />
          </OpportunityContextWrapper>
        }
      />
    </Routes>
  );
}
