import {
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
} from '@heroicons/react/20/solid';
import {
  ArrowTrendingUpIcon,
  ClockIcon,
  CurrencyDollarIcon,
  GlobeAltIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { produce, setAutoFreeze } from 'immer';
import { useState } from 'react';
import api from 'src/api';
import { BadgeColor } from 'src/components/Badge';
import { InlineSpinner } from 'src/components/Loading';
import {
  OpportunityDisplayField,
  OpportunityFieldValue,
} from 'src/dashboard/Opportunity/OpportunityDetailPage';
import { classNames } from '../../../App';
import { usePricingFlowContext } from '../../PricingFlow';
import { PricingFlowCommon } from '../../types';
import { Currency, CurrencyValueType } from '../../types_common/price';
import { PenguinOpportunityData, PenguinPricingFlow } from '../penguin_types';

export function getOpportunityOverviewBarFields(
  penguinOpportunityData: PenguinOpportunityData,
): OpportunityDisplayField[] {
  const penguinBadgeColor: BadgeColor = (() => {
    switch (penguinOpportunityData.Opportunity_StageName) {
      case 'Closed Won':
        return 'green';
      case 'Closed Lost':
        return 'red';
    }
    return 'gray';
  })();

  return [
    {
      icon: UserIcon,
      name: 'Owner',
      displayValue: {
        type: 'text',
        value: penguinOpportunityData.User_Name ?? '–',
      },
      editable: false,
    },
    {
      icon: TagIcon,
      name: 'GTM segment',
      displayValue: {
        type: 'text',
        value: penguinOpportunityData.Opportunity_GTM_Segment__c ?? '–',
      },
      editable: false,
    },
    {
      icon: ArrowTrendingUpIcon,
      name: 'Type',
      displayValue: {
        type: 'text',
        value: penguinOpportunityData.Opportunity_Type ?? '–',
      },
      editable: false,
    },
    {
      icon: ClockIcon,
      name: 'Stage',
      displayValue: penguinOpportunityData.Opportunity_StageName
        ? {
            type: 'badge',
            value: penguinOpportunityData.Opportunity_StageName,
            color: penguinBadgeColor,
          }
        : { type: 'text', value: '-' },
      editable: false,
    },
    {
      icon: GlobeAltIcon,
      name: 'Product region',
      displayValue: {
        type: 'text',
        value: penguinOpportunityData.Opportunity_Product_Region__c ?? '–',
      },
      editable: false,
    },
    {
      icon: CurrencyDollarIcon,
      name: 'Qualified ACV',
      displayValue: {
        type: 'currency',
        value: {
          type: CurrencyValueType.FLAT,
          currency:
            (penguinOpportunityData.CurrencyIsoCode as Currency) ?? 'USD',
          value: penguinOpportunityData.Opportunity_Qualified_ACV__c ?? 0,
        },
        numDecimals: 0,
      },
      editable: false,
    },
  ];
}

export function getOpportunitySFDCUrl(pricingFlow: PricingFlowCommon): string {
  return (
    pricingFlow.opportunity.sfdcInstanceUrl +
    '/lightning/r/Opportunity/' +
    pricingFlow.opportunity.sfdcOpportunityId +
    '/view'
  );
}

export default function OpportunityOverviewBar(props: {
  opportunityData: PricingFlowCommon['opportunity']['opportunityData'];
  stretchWidth?: boolean;
  loading?: boolean;
}) {
  const { loading, opportunityData } = props;
  const { pricingFlow } = usePricingFlowContext<PenguinPricingFlow>();

  const penguinOpportunityData: PenguinOpportunityData =
    opportunityData as PenguinOpportunityData;

  const fields = getOpportunityOverviewBarFields(penguinOpportunityData);

  return (
    <div className="relative z-40 w-64 border-r border-gray-200 bg-white text-xs font-medium">
      <div className="flex-grow overflow-auto pb-28">
        <div className="my-4 line-clamp-5 px-6 text-xl hover:line-clamp-none">
          {pricingFlow.opportunity.sfdcOpportunityName}
        </div>
        <div className="bg-gray-100 px-6 py-2 text-gray-500">DETAILS</div>
        <div className="flex flex-wrap items-center divide-y">
          {fields.map((field: OpportunityDisplayField, i: number) => {
            return (
              <div
                key={i}
                className="ml-6 flex w-full items-center gap-2 py-4 pr-4 "
              >
                <div className="flex h-10 w-10 justify-center">
                  <field.icon
                    className="m-2 text-gray-600"
                    aria-hidden="true"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <div className="whitespace-nowrap uppercase text-gray-400">
                    {field.name}
                  </div>
                  <div
                    className={classNames(
                      loading ? 'opacity-0' : 'opacity-100',
                      'text-md whitespace-nowrap text-gray-600 transition-opacity duration-500 ease-in-out',
                    )}
                  >
                    <OpportunityFieldValue displayValue={field.displayValue} />
                  </div>
                  {loading && <InlineSpinner />}
                </div>
              </div>
            );
          })}
        </div>
        <div className="w-full flex justify-center mt-6">
          <RefreshDataButton />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 flex h-20 flex-row items-center justify-center border-t bg-white">
        <a
          href={getOpportunitySFDCUrl(pricingFlow)}
          target="_blank"
          rel="noopener noreferrer"
          className="col-span-full inline-flex"
        >
          <button className="flex inline-flex items-center  justify-center gap-2 rounded-lg border border-gray-200 px-5 py-2 font-semibold text-gray-700 shadow-sm hover:border-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900">
            Open in SFDC
            <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          </button>
        </a>
      </div>
    </div>
  );
}

function RefreshDataButton() {
  const { pricingFlow, updateFlow, editMode } =
    usePricingFlowContext<PenguinPricingFlow>();
  async function refreshSalesforceData(): Promise<void> {
    setIsRefreshing(true);
    const newPricingFlow = await api.post(
      'refresh-salesforce-data/' + pricingFlow.id,
      {},
    );

    if (!editMode) {
      // This is a little annoying, but I imagine people are not going to do this very often
      // If you don't have edit access, I think it's okay for you to refresh the SFDC data, but
      // the page will refresh because I don't want anything else to update
      window.location.reload();
      return;
    }
    const opportunityData = newPricingFlow.data.opportunity
      .opportunityData as PenguinOpportunityData;
    setAutoFreeze(false);
    updateFlow(
      produce(pricingFlow, (draftPricingFlow) => {
        draftPricingFlow.opportunity.opportunityData = opportunityData;
      }),
      false,
    );
    setIsRefreshing(false);
  }
  const [isRefreshing, setIsRefreshing] = useState(false);
  return (
    <button
      type="button"
      className={classNames(
        'flex flex-row gap-1 items-center rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50',
        isRefreshing ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={refreshSalesforceData}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <div className="h-4 w-4 flex items-center">
          <InlineSpinner height={12} width={12} />
        </div>
      ) : (
        <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
      )}
      Refresh data
    </button>
  );
}
