import { datadogRum } from '@datadog/browser-rum';
import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  ArrowTopRightOnSquareIcon,
  CurrencyDollarIcon,
  DocumentDuplicateIcon,
  GlobeAltIcon,
  PlusIcon,
  TagIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { Crepe } from '@milkdown/crepe';
import '@milkdown/crepe/theme/common/style.css';
import '@milkdown/crepe/theme/frame.css';
import { replaceAll } from '@milkdown/kit/utils';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { produce } from 'immer';
import _, { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import api from 'src/api';
import Badge, { BadgeColor } from 'src/components/Badge';
import { Button } from 'src/components/Button';
import 'src/components/CrepeEditor.css';
import { FormattedNumberField } from 'src/components/Fields';
import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { FullscreenSpinner, InlineSpinner } from 'src/components/Loading';
import { useModal } from 'src/components/Modal';
import { unreachable } from 'src/typeUtils';
import { safeParseFloat } from 'src/utils';
import { Organization, User } from '../../types';
import { classNames } from '../App';
import { AlpacaOpportunityData } from '../PricingFlow/Alpaca/alpaca_types';
import { formatCurrencyValue } from '../PricingFlow/Alpaca/alpaca_utils';
import { getOpportunityOverviewFields } from '../PricingFlow/Alpaca/Components/AlpacaOpportunitySidebar';
import { HamsterOpportunityData } from '../PricingFlow/Hamster/hamster_types';
import { getOpportunityOverviewBarFields } from '../PricingFlow/Penguin/Components/OpportunityOverviewBar';
import { PenguinOpportunityData } from '../PricingFlow/Penguin/penguin_types';
import PricingFlowList from '../PricingFlow/PricingFlowList';
import { PricingFlowOrSnapshotForNavigation } from '../PricingFlow/QuoteOptionsSection';
import {
  OpportunityCommon,
  PricingFlowCommon,
  PricingFlowType,
} from '../PricingFlow/types';
import {
  CurrencyValueFlat,
  CurrencyValuePercent,
  CurrencyValueType,
} from '../PricingFlow/types_common/price';
import { CreateOrUpdateGroupModal } from '../PricingFlowGroup/PricingFlowGroup';
import CloneQuoteModal from './CloneQuoteModal';
import {
  OpportunityFieldUpdateData,
  useOpportunityContext,
} from './OpportunityContext';
import { Opportunity, PricingFlowGroup } from './types';

dayjs.extend(relativeTime);

interface OpportunityDetailPageProps {
  user: User;
  organization: Organization;
}

export type OpportunityDetailPageConfig = {
  isOpportunityEditable: boolean;
  hasApprovals: boolean;
  canCloneFromOppDetailsPage: boolean;
  shouldAutoRedirectToPricingFlow: boolean;
  hasGroups: boolean;
  hasContext: boolean;
};

export function configForOrg(
  type: PricingFlowType | undefined,
): OpportunityDetailPageConfig {
  switch (type) {
    case PricingFlowType.HAMSTER:
      return {
        isOpportunityEditable: true,
        hasApprovals: true,
        canCloneFromOppDetailsPage: true,
        shouldAutoRedirectToPricingFlow: false,
        hasGroups: true,
        hasContext: true,
      };
    case PricingFlowType.DEALOPS:
    case PricingFlowType.PENGUIN:
    case PricingFlowType.COMPLEX_DEMO:
    case PricingFlowType.ALPACA:
    default:
      return {
        isOpportunityEditable: false,
        hasApprovals: false,
        // ##CloneFromOppDetailPage
        // do NOT turn this to true for a pricing flow without implementing the
        // cloning behavior on the server!
        canCloneFromOppDetailsPage: false,
        shouldAutoRedirectToPricingFlow: true,
        hasGroups: false,
        hasContext: false,
      };
  }
}

interface RefreshDataButtonProps {
  editMode: boolean;
}
// TODO look for all other components named RefreshDataButton and swap em out for this one
export function RefreshDataButton(props: RefreshDataButtonProps) {
  const { editMode } = props;
  const { opportunity, updateOpportunity } = useOpportunityContext();
  const [isRefreshing, setIsRefreshing] = useState(false);
  if (isNil(opportunity)) {
    return null;
  }
  const refreshSalesforceData = async () => {
    setIsRefreshing(true);
    const newOpportunityData = (
      await api.post(
        'refresh-salesforce-data-2/' + opportunity.sfdcOpportunityId,
        {},
      )
    ).data;

    if (!editMode) {
      // This is a little annoying, but I imagine people are not going to do this very often
      // If you don't have edit access, I think it's okay for you to refresh the SFDC data, but
      // the page will refresh because I don't want anything else to update
      window.location.reload();
      return;
    }
    updateOpportunity(
      produce(opportunity, (draftOpportunity) => {
        draftOpportunity.opportunityData = newOpportunityData;
      }),
      [],
    );
    setIsRefreshing(false);
  };
  return (
    <Button
      color="white"
      className={classNames(
        'flex-1 flex flex-row gap-1 items-center px-2 py-1 text-xs font-semibold text-gray-900 ',
        isRefreshing ? 'cursor-not-allowed' : 'cursor-pointer',
      )}
      onClick={refreshSalesforceData}
      disabled={isRefreshing}
    >
      {isRefreshing ? (
        <div className="h-4 w-4 flex items-center">
          <InlineSpinner height={12} width={12} />
        </div>
      ) : (
        <ArrowPathIcon className="h-4 w-4" aria-hidden="true" />
      )}
      Refresh data
    </Button>
  );
}

export default function OpportunityDetailPage(
  props: OpportunityDetailPageProps,
) {
  const navigate = useNavigate();
  const { opportunity, updateOpportunity, pageConfig } =
    useOpportunityContext();

  if (pageConfig.shouldAutoRedirectToPricingFlow) {
    const firstPricingFlow = _.first(opportunity?.pricingFlows);
    return opportunity && firstPricingFlow ? (
      <Navigate to={`pricingflow/${firstPricingFlow.id}`} relative="path" />
    ) : (
      <FullscreenSpinner />
    );
  } else {
    return opportunity ? (
      <>
        {/* breadcrumbs */}
        <HeaderBreadcrumbs
          steps={[
            {
              label: 'Opportunities',
              onClick: () => {
                navigate('/app/opportunity');
              },
            },
            {
              label: `${opportunity.sfdcOpportunityName}`,
              onClick: () => {},
            },
          ]}
        />
        {/* title */}
        <div className="px-4 sm:px-6 lg:px-8 mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              {opportunity.sfdcOpportunityName}
            </h2>
          </div>
        </div>
        {/* content */}
        <div className="min-h-screen p-8">
          <div className="mx-auto pb-10 flex flex-col gap-6 items-start md:flex-row">
            <OpportunityInfo
              opportunity={opportunity}
              pageConfig={pageConfig}
              className="md:w-80 md:min-w-80"
            />
            <div className="w-full flex flex-col space-y-4">
              <Context
                opportunity={opportunity}
                updateOpportunity={updateOpportunity}
                pageConfig={pageConfig}
              />
              <Groups opportunity={opportunity} pageConfig={pageConfig} />
              <Quotes
                opportunity={opportunity}
                pageConfig={pageConfig}
                user={props.user}
              />
            </div>
          </div>
        </div>
      </>
    ) : (
      <FullscreenSpinner />
    );
  }
}

export function OpportunityFieldValue(props: {
  displayValue: OpportunityFieldDisplayValue;
}) {
  const { displayValue } = props;
  switch (displayValue.type) {
    case 'badge':
      return <Badge color={displayValue.color}>{displayValue.value}</Badge>;
    case 'currency':
      return formatCurrencyValue(displayValue.value, displayValue.numDecimals);
    case 'text':
      return displayValue.value;
    default:
      unreachable(displayValue);
  }
}
export function getOpportunityFieldValueTextOnly(
  displayValue: OpportunityFieldDisplayValue,
) {
  switch (displayValue.type) {
    case 'badge':
    case 'text':
      return displayValue.value;
    case 'currency':
      return formatCurrencyValue(displayValue.value, displayValue.numDecimals);
    default:
      unreachable(displayValue);
  }
}

function getHamsterOpportunityDetails(
  oppData: HamsterOpportunityData,
): OpportunityDisplayField[] {
  return [
    {
      name: 'Owner',
      editable: false,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Owner ?? '-',
      },
    },
    {
      name: 'Segment',
      editable: false,
      icon: TagIcon,
      displayValue: oppData.Segment
        ? {
            type: 'badge',
            value: oppData.Segment,
            color: 'blue',
          }
        : {
            type: 'text',
            value: '-',
          },
    },
    {
      name: 'Type',
      editable: false,
      icon: TagIcon,
      displayValue: oppData.Type
        ? {
            type: 'badge',
            value: oppData.Type,
            color: 'blue',
          }
        : {
            type: 'text',
            value: '-',
          },
    },
    {
      name: 'Country',
      editable: false,
      icon: GlobeAltIcon,
      displayValue: {
        type: 'text',
        value: oppData.Country ?? '-',
      },
    },
    {
      name: 'Number of lawyers',
      editable: true,
      icon: UserIcon,
      displayValue: {
        type: 'text',
        value: oppData.Number_of_lawyers?.toLocaleString() ?? '-',
      },
      inputType: 'number',
      sfdcObjectType: 'Account',
      sfdcObjectId: oppData.AccountId,
      sfdcField: 'Number_of_Lawyers__c',
      dealopsField: 'Number_of_lawyers',
      writeValue: oppData.Number_of_lawyers ?? 0,
    },
    {
      name: 'ARR',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: oppData.ARR
        ? {
            type: 'currency',
            value: {
              type: CurrencyValueType.FLAT,
              value: oppData.ARR,
              currency: 'USD',
            },
            numDecimals: 0,
          }
        : { type: 'text', value: '-' },
    },
    {
      name: 'TCV',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: oppData.TCV
        ? {
            type: 'currency',
            value: {
              type: CurrencyValueType.FLAT,
              value: oppData.TCV,
              currency: 'USD',
            },
            numDecimals: 0,
          }
        : { type: 'text', value: '-' },
    },
    {
      name: 'Net New ACV',
      editable: false,
      icon: CurrencyDollarIcon,
      displayValue: oppData.Net_New_ACV
        ? {
            type: 'currency',
            value: {
              type: CurrencyValueType.FLAT,
              value: oppData.Net_New_ACV,
              currency: 'USD',
            },
            numDecimals: 0,
          }
        : { type: 'text', value: '-' },
    },
  ];
}

type OpportunityFieldDisplayValue =
  | {
      type: 'badge';
      color: BadgeColor;
      value: string;
    }
  | {
      type: 'currency';
      value: CurrencyValueFlat | CurrencyValuePercent;
      numDecimals: number;
    }
  | { type: 'text'; value: string };
export type OpportunityDisplayField = {
  // label and value that is displayed
  name: string;
  displayValue: OpportunityFieldDisplayValue;
  icon: React.FC<React.HTMLAttributes<SVGSVGElement>>;
} & (
  | ({
      editable: true;
      dealopsField: string;
      inputType: 'number';
    } & OpportunityFieldUpdateData)
  | { editable: false }
);
export function getOpportunityDetails(
  opportunity: Opportunity,
): OpportunityDisplayField[] {
  switch (opportunity.type) {
    case PricingFlowType.PENGUIN: {
      return getOpportunityOverviewBarFields(
        opportunity.opportunityData as PenguinOpportunityData,
      );
    }
    case PricingFlowType.ALPACA: {
      return getOpportunityOverviewFields(
        opportunity.opportunityData as AlpacaOpportunityData,
      );
    }
    case PricingFlowType.HAMSTER:
      const oppData = opportunity.opportunityData as HamsterOpportunityData;
      return getHamsterOpportunityDetails(oppData);
    case PricingFlowType.DEALOPS:
    case PricingFlowType.COMPLEX_DEMO:
    default:
      return [];
  }
}

function EditableFieldInput(props: {
  field: OpportunityDisplayField & { editable: true };
  updateValue: (newValue: any) => void;
}) {
  const { field, updateValue } = props;
  switch (field.inputType) {
    case 'number':
      return (
        <FormattedNumberField
          type="text"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? ''}
          updateValue={updateValue}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
    default:
      return (
        <input
          type="text"
          className="w-full text-sm text-right rounded-lg px-2 py-1 border border-gray-300 focus:ring-1 focus:ring-fuchsia-900 focus:border-transparent truncate overflow-ellipsis appearance-none"
          value={field.writeValue ?? undefined}
          onChange={(e) => updateValue(e.target.value)}
          title={getOpportunityFieldValueTextOnly(field.displayValue)}
        />
      );
  }
}

type OpportunityInfoProps = {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  className?: string;
  titleOverride?: string;
};
export function OpportunityInfo(props: OpportunityInfoProps) {
  const { opportunity, pageConfig, className, titleOverride } = props;
  const { updateOpportunity } = useOpportunityContext();
  const originalFields = getOpportunityDetails(props.opportunity);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  // intermediate values of fields while in editing state
  const [editedFields, setEditedFields] = useState(originalFields);
  useEffect(() => {
    setEditedFields(getOpportunityDetails(props.opportunity));
  }, [opportunity]);
  return (
    <div
      className={classNames(
        'divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border border-slate-200 w-full',
        className,
      )}
    >
      {/* header */}
      <div className="px-4 py-4 flex justify-between items-center font-medium">
        <p className="text-xs text-gray-500 uppercase">
          {titleOverride ?? 'Overview'}
        </p>
        {pageConfig.isOpportunityEditable && (
          <button
            className="text-sm text-fuchsia-900 hover:text-fuchsia-700 "
            onClick={() => {
              setIsEditing((prevIsEditing) => {
                if (prevIsEditing) {
                  const updateData = editedFields
                    .filter((f) => f.editable)
                    .filter((f) => f.writeValue !== undefined);
                  const updatedOpportunity = produce(
                    opportunity,
                    (draftOpp) => {
                      const oppData = draftOpp.opportunityData as Record<
                        string,
                        any
                      >;
                      for (const update of updateData) {
                        try {
                          oppData[update.dealopsField] = update.writeValue;
                        } catch (error) {
                          console.log(
                            `error updating opportunity: ${update.dealopsField} ${update.writeValue} ${draftOpp.sfdcOpportunityId}`,
                            error,
                          );
                          datadogRum.addError(
                            `error updating opportunity: ${update.dealopsField} ${update.writeValue} ${draftOpp.sfdcOpportunityId}`,
                          );
                        }
                      }
                    },
                  );
                  updateOpportunity(updatedOpportunity, updateData);
                }
                return !prevIsEditing;
              });
            }}
          >
            {isEditing ? 'Save' : 'Edit'}
          </button>
        )}
      </div>
      {/* body */}
      <div className="px-4 py-4 w-full">
        <div className="overflow-x-auto">
          <table className="w-full">
            <tbody className="[&>tr]:py-2">
              {editedFields.map((field: OpportunityDisplayField, i: number) => {
                const isFieldEditable = isEditing && field.editable;
                return (
                  <tr className="w-full" key={field.name}>
                    <td
                      className="text-sm text-gray-500 whitespace-normal pr-4 py-2"
                      style={{ minWidth: '140px', maxWidth: '200px' }}
                    >
                      {field.name}
                    </td>
                    <td className="w-full py-1">
                      {isFieldEditable ? (
                        <div className="relative w-full">
                          <EditableFieldInput
                            field={field}
                            updateValue={(newValue) => {
                              setEditedFields(
                                produce((draftFields) => {
                                  const updatedField = draftFields[i];
                                  if (!updatedField.editable) {
                                    return;
                                  }
                                  updatedField.writeValue = newValue;
                                  switch (updatedField.displayValue.type) {
                                    case 'badge':
                                    case 'text':
                                      updatedField.displayValue.value =
                                        newValue;
                                      break;
                                    case 'currency':
                                      const cv =
                                        updatedField.displayValue.value;
                                      switch (cv.type) {
                                        case CurrencyValueType.FLAT:
                                        case CurrencyValueType.PERCENT:
                                          cv.value = safeParseFloat(newValue);
                                          break;
                                        default:
                                          unreachable(cv);
                                      }
                                      break;
                                    default:
                                      unreachable(updatedField.displayValue);
                                  }
                                }),
                              );
                            }}
                          />
                        </div>
                      ) : (
                        <p className="text-sm text-right px-2 py-1 truncate">
                          {getOpportunityFieldValueTextOnly(field.displayValue)}
                        </p>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      {/* footer */}
      <div className="px-4 py-4 flex flex-row justify-between items-center gap-x-2">
        <RefreshDataButton editMode={true} />
        <Button
          className="flex flex-row gap-1 items-center px-2 py-1 text-xs font-semibold text-gray-900 flex-1"
          color="white"
          onClick={() => {
            window.open(
              `${opportunity.sfdcInstanceUrl}/lightning/r/Opportunity/${opportunity.sfdcOpportunityId}/view`,
              '_blank',
            );
          }}
        >
          <ArrowTopRightOnSquareIcon className="h-4 w-4" aria-hidden="true" />
          Open in SFDC
        </Button>
      </div>
    </div>
  );
}

export function Context(props: {
  opportunity: Opportunity;
  updateOpportunity: (
    updatedOpportunity: Opportunity,
    sfdcUpdates: OpportunityFieldUpdateData[],
  ) => Promise<void>;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { opportunity, updateOpportunity, pageConfig } = props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [crepeEditor, setCrepeEditor] = useState<Crepe | null>(null);

  const defaultValueForDisplay = 'No opportunity context';
  const templateForEditing = getOpportunityContextTemplate({ opportunity });

  useEffect(() => {
    const newCrepe = new Crepe({
      root: '#crepeeditor--Context',
      defaultValue:
        opportunity.context ??
        (isEditing ? templateForEditing : defaultValueForDisplay),
    });
    newCrepe.setReadonly(true);

    newCrepe.create().then(() => {
      console.log('Crepe editor created for OpportunityDetailPage');
      setCrepeEditor(newCrepe);
    });

    return () => {
      // Destroy the editor when unmounting component
      newCrepe.destroy();
      console.log('Crepe editor destroyed for OpportunityDetailPage');
    };
  }, []);

  useEffect(() => {
    if (crepeEditor) {
      console.log(
        'Setting crepe for OpportunityDetailPage readonly to: ',
        !isEditing,
      );
      crepeEditor.setReadonly(!isEditing);

      if (isEditing && isNil(opportunity.context)) {
        crepeEditor.editor.action(replaceAll(templateForEditing));
      }
    }
  }, [isEditing, crepeEditor]);

  if (!pageConfig.hasContext) {
    return null;
  }
  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="p-4 flex justify-between items-center font-medium">
        <span className="text-xs text-gray-500 uppercase">
          Opportunity Context
        </span>
        {isEditing ? (
          <button
            className="text-sm text-fuchsia-900 hover:text-fuchsia-700"
            onClick={async () => {
              const markdown = await crepeEditor?.getMarkdown().trim();
              updateOpportunity(
                produce(opportunity, (draftOpportunity) => {
                  draftOpportunity.context = markdown ?? null;
                }),
                [],
              );
              setIsEditing(false);
            }}
          >
            Save
          </button>
        ) : (
          <button
            className="text-sm text-fuchsia-900 hover:text-fuchsia-700"
            onClick={() => {
              setIsEditing(true);
            }}
          >
            Edit
          </button>
        )}
      </div>
      {/* body */}
      <div id="crepeeditor--Context" className={classNames('p-4')} />
    </div>
  );
}

function Groups(props: {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
}) {
  const { opportunity, pageConfig } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [pricingFlowGroups, setPricingFlowGroups] = useState<
    PricingFlowGroup[] | null
  >(null);

  useEffect(() => {
    if (pageConfig.hasGroups && isNil(pricingFlowGroups)) {
      setIsLoading(true);
      api
        .get('pricingFlowGroups', {
          opportunityId: opportunity.id,
        })
        .then((res) => {
          setPricingFlowGroups(res.data);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, []);
  const { showModal } = useModal();

  if (!pageConfig.hasGroups) {
    return null;
  }
  return (
    <div className="divide-y divide-gray-200 rounded-lg bg-white border border-slate-200 w-full md:flex-grow">
      {/* header */}
      <div className="px-4 py-2 flex justify-between items-center font-medium">
        <span className="text-xs text-gray-500 uppercase">Groups</span>
        <Button
          color="white"
          onClick={() => {
            showModal({
              newStyle: true,
              title: 'Create a group',
              children: (
                <CreateOrUpdateGroupModal
                  opportunity={opportunity}
                  showArchiveWarning={
                    !isNil(pricingFlowGroups) && pricingFlowGroups.length > 0
                  }
                />
              ),
            });
          }}
          label="Create a group"
          icon="plus"
        />
      </div>
      {/* body */}
      <div className="px-4 py-5 flex-grow space-y-5">
        {isLoading || isNil(pricingFlowGroups) ? (
          <InlineSpinner />
        ) : (
          <PricingFlowGroupsList pricingFlowGroups={pricingFlowGroups} />
        )}
      </div>
    </div>
  );
}

function PricingFlowGroupsList(props: {
  pricingFlowGroups: PricingFlowGroup[];
}) {
  const { pricingFlowGroups } = props;
  const navigate = useNavigate();

  if (pricingFlowGroups.length === 0) {
    return (
      <div className="text-center p-8 text-slate-600">
        <p>You do not have any groups yet.</p>
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {pricingFlowGroups.map((group) => {
        const isActive = !group.archivedAt;

        return (
          <div
            key={group.id}
            className={`flex justify-between p-4 rounded-lg border ${
              isActive
                ? 'border-fuchsia-200 bg-fuchsia-50'
                : 'border-gray-200 bg-gray-50'
            }`}
          >
            {/* left side */}
            <div className="space-y-1">
              <div className="flex items-center gap-x-2">
                <div className="font-medium text-slate-950 text-sm">
                  {group.name}
                </div>
                {!isActive && (
                  <div className="flex items-center gap-x-1 text-slate-500">
                    <ArchiveBoxIcon className="w-4 h-4" />
                    <span className="text-xs">
                      Archived {dayjs(group.archivedAt).fromNow()}
                    </span>
                  </div>
                )}
              </div>

              <div className="text-xs text-slate-600">
                Last updated {dayjs(group.updatedAt).fromNow()}
              </div>
            </div>

            {/* right side */}
            <div className="flex items-center gap-x-2">
              <button
                className={`flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border py-1 px-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900 text-slate-900 ${
                  isActive
                    ? 'border-fuchsia-200 bg-white hover:bg-fuchsia-50'
                    : 'border-gray-200 bg-white hover:bg-gray-50'
                }`}
                onClick={() => {
                  navigate(`group/${group.id}`, {
                    relative: 'path',
                  });
                }}
              >
                <span className="text-sm font-medium">Open</span>
              </button>
            </div>
          </div>
        );
      })}
    </div>
  );
}

function EmptyState(props: {
  primaryButton?: { label: string; onClick: () => void };
  secondaryButton?: { label: string; onClick: () => void };
  children?: React.ReactNode;
}) {
  const { primaryButton, secondaryButton, children } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="flex-grow flex flex-col items-center space-y-4 text-gray-500 text-sm items-center">
      {children}
      {primaryButton && (
        <button
          className="border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            primaryButton.onClick();
          }}
        >
          {isLoading ? (
            <InlineSpinner />
          ) : (
            <>
              <PlusIcon className="w-4 h-4 pl-0" />
              <span className="font-medium text-sm">{primaryButton.label}</span>
            </>
          )}
        </button>
      )}
      {secondaryButton && (
        <button
          className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
          onClick={secondaryButton.onClick}
        >
          <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
          <span className="text-sm font-medium">{secondaryButton.label}</span>
        </button>
      )}
    </div>
  );
}

interface NamePricingFlowModalProps {
  opportunity: OpportunityCommon;
  createAndNavigate: (name: string) => void;
  hideModal: () => void;
}
function NamePricingFlowModal(props: NamePricingFlowModalProps) {
  const { opportunity, createAndNavigate, hideModal } = props;
  const defaultName = `Option ${opportunity.pricingFlows.length + 1}`;
  const [name, setName] = useState<string>(defaultName);
  const handleSubmit = async () => {
    createAndNavigate(name);
    hideModal();
  };

  return (
    <div>
      <div className="my-4">
        <input
          id="name"
          type="text"
          name="Name"
          defaultValue={defaultName}
          onChange={(e) => setName(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }} // Add keydown event listener
          className="block w-full appearance-none rounded-md shadow-sm border border-gray-300 px-3 py-1.5 text-gray-900 placeholder-gray-400 focus:border-fuchsia-900 focus:bg-white focus:outline-none focus:ring-fuchsia-900 sm:text-sm"
          data-1p-ignore
        />
      </div>
      <button
        type="submit"
        className="whitespace-nowrap inline-flex w-full items-center justify-center rounded-md bg-fuchsia-900 text-sm py-2 font-semibold text-white shadow-sm hover:bg-fuchsia-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-fuchsia-900"
        onClick={handleSubmit}
      >
        Start pricing
      </button>
    </div>
  );
}
interface QuotesProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  user: User;
}
function Quotes(props: QuotesProps) {
  const { opportunity, pageConfig, user } = props;
  const { pricingFlows } = opportunity;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [prevPricingFlows, setPrevPricingFlows] = useState<
    PricingFlowCommon[] | null
  >(null);
  const createAndNavigate = async (name: string) => {
    setIsLoading(true);
    const newPricingFlow = (
      await api.post('pricingflow', {
        sfdcOpportunityId: opportunity.sfdcOpportunityId,
        name,
      })
    ).data;
    navigate(`pricingflow/${newPricingFlow.id}`, {
      relative: 'path',
    });
  };
  useEffect(() => {
    if (isNil(prevPricingFlows)) {
      api.get('pricingFlows', { matchingPricingFlowId: null }).then((res) => {
        setPrevPricingFlows(res.data);
      });
    }
  }, []);
  const { showModal, hideModal } = useModal();

  const showSetNameModal = () => {
    showModal({
      title: 'Name your quote',
      children: (
        <NamePricingFlowModal
          opportunity={opportunity}
          createAndNavigate={createAndNavigate}
          hideModal={hideModal}
        />
      ),
    });
  };

  const showCloneModal = () => {
    if (!isNil(prevPricingFlows)) {
      showModal({
        title: 'Clone quote',
        children: (
          <CloneQuoteModal
            opportunity={opportunity}
            otherPricingFlows={prevPricingFlows.filter(
              (flow) => flow.opportunity.id !== opportunity.id,
            )}
            hideModal={hideModal}
          />
        ),
      });
    }
  };

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white border border-slate-200 w-full md:flex-grow ">
      {isNil(pricingFlows) || pricingFlows.length === 0 ? (
        <>
          {/* header */}
          <div className="px-4 py-4 text-xs text-slate-500 font-medium">
            <span>QUOTES</span>
          </div>
          {/* body */}
          <QuotesEmptyState
            opportunity={opportunity}
            pageConfig={pageConfig}
            prevPricingFlows={prevPricingFlows}
            showCloneModal={showCloneModal}
          />
        </>
      ) : (
        <>
          {/* header */}
          <div className="px-4 py-2 text-xs text-slate-500 font-medium flex items-center justify-between">
            <span>QUOTES</span>
            <div className="flex gap-x-2">
              {pageConfig.canCloneFromOppDetailsPage &&
                (prevPricingFlows?.length ?? 0) > 0 && (
                  <Button
                    color="white"
                    onClick={showCloneModal}
                    icon="document-duplicate"
                    label="Clone existing"
                  />
                )}
              <Button
                color="white"
                onClick={showSetNameModal}
                icon="plus"
                label="Create a quote"
                disabled={isLoading}
              />
            </div>
          </div>
          {/* body */}
          <QuotesList
            opportunity={opportunity}
            pageConfig={pageConfig}
            user={user}
          />
        </>
      )}
    </div>
  );
}

interface QuotesEmptyStateProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  prevPricingFlows: PricingFlowCommon[] | null;
  showCloneModal: () => void;
}
/**
 * Deprecate this component and use EmptyState
 */
function QuotesEmptyState(props: QuotesEmptyStateProps) {
  const navigate = useNavigate();
  const { opportunity, pageConfig, prevPricingFlows, showCloneModal } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  return (
    <div className="px-4 py-5 flex-grow flex flex-col items-center space-y-4">
      <div className="text-4xl">🌵</div>
      <div className="text-gray-500 text-sm flex flex-col items-center">
        <p>You do not have any quotes yet.</p>
        <p>Create your first quote</p>
      </div>
      <div className="flex flex-col gap-2">
        <button
          className="border-fuchsia-900 bg-fuchsia-900 text-white hover:bg-fuchsia-800 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const newPricingFlow = (
              await api.post('pricingflow', {
                sfdcOpportunityId: opportunity.sfdcOpportunityId,
              })
            ).data;
            navigate(`pricingflow/${newPricingFlow.id}`, { relative: 'path' });
          }}
        >
          {isLoading ? (
            <InlineSpinner />
          ) : (
            <>
              <PlusIcon className="w-4 h-4 pl-0" />
              <span className="font-medium text-sm">Create a new quote</span>
            </>
          )}
        </button>
        {pageConfig.canCloneFromOppDetailsPage &&
          (prevPricingFlows?.length ?? 0) > 0 && (
            <button
              className=" border-gray-200 bg-white  hover:bg-gray-200 focus-visible:outline-fuchsia-900 flex items-center justify-center gap-2 whitespace-nowrap rounded-lg border px-3 py-2 shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  text-slate-900"
              onClick={showCloneModal}
            >
              <DocumentDuplicateIcon className="w-4 h-4 pl-0" />
              <span className="text-sm font-medium">Clone existing</span>
            </button>
          )}
      </div>
    </div>
  );
}

interface QuotesListProps {
  opportunity: Opportunity;
  pageConfig: OpportunityDetailPageConfig;
  user: User;
}
function QuotesList(props: QuotesListProps) {
  const navigate = useNavigate();
  const { opportunity, pageConfig, user } = props;
  const [crepeEditors, setCrepeEditors] = useState<
    Record<string, Crepe | null>
  >({});
  return (
    <>
      <div className="px-4 py-5 flex-grow space-y-3">
        <PricingFlowList
          pricingFlows={props.opportunity.pricingFlows}
          modelType="pricingFlow"
          onClick={(pricingFlow: PricingFlowOrSnapshotForNavigation) => {
            navigate(`pricingflow/${pricingFlow.id}`, {
              relative: 'path',
            });
          }}
          user={user}
          hasApprovals={pageConfig.hasApprovals}
          listItemClassName="border border-gray-200 rounded-lg bg-white p-4"
          purpose="OpportunityDetailPage"
          crepes={crepeEditors}
          setCrepes={setCrepeEditors}
          contextEditableMode="no-edit"
        />
      </div>
    </>
  );
}

// ##OpportunityContextTemplate
export function getOpportunityContextTemplate(params: {
  opportunity: Opportunity;
}): string {
  const { opportunity } = params;
  switch (opportunity.type) {
    case 'HAMSTER':
      return getHamsterOpportunityContextTemplate({
        opportunity,
      }); // // @TODO opportunity is not type split correctly
    default:
      return `[Fill this in with context about this opportunity]`;
  }
}
function getHamsterOpportunityContextTemplate(params: {
  opportunity: Opportunity;
}): string {
  const { opportunity } = params;
  const opportunityData = opportunity.opportunityData as HamsterOpportunityData;
  if (opportunityData.Type === 'Renewal') {
    return `**Deal Overview**:\n
[Please share deal context in 2-4 sentences (e.g., any context on # of negotiation rounds we’ve had, how far along the negotiation process the opportunity is, any more space to push back on discounts / fallback discount %)]\n\n

**Renewal Details**:\n\n
[Please provide context on the existing contract including # of Seats, Price Per Seat, ARR, any non-standard terms, Contract Start/End Date, etc.]\n\n

**Business Justification**:\n\n
[What is the justification/reason behind this exception request? Why do we need to make this exception?]\n\n

**Due Date**:\n\n
[Please add in the date that you would like Deal Desk to make a decision by; Deal Desk will try their best to get back to you within this time but please keep in mind that more complex requests may take longer to resolve.]`;
  } else {
    return `**Deal Overview**:\n\n
[Please share deal context in 2-4 sentences (e.g., any context on # of negotiation rounds we’ve had, how far along the negotiation process the opportunity is, any more space to push back on discounts / fallback discount %)]\n\n

**Business Justification**:\n\n
[What is the justification/reason behind this exception request? Why do we need to make this exception?]\n\n

**Due Date:**\n\n
[Please add in the date that you would like Deal Desk to make a decision by; Deal Desk will try their best to get back to you within this time but please keep in mind that more complex requests may take longer to resolve.]`;
  }
}

// ##QuoteContextTemplate
const QUOTE_TEMPLATE = `[Please describe this quote option]`;

export function getQuoteContextTemplate(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
}): string {
  const { pricingFlow } = params;
  switch (pricingFlow.type) {
    case 'HAMSTER':
      return getHamsterQuoteContextTemplate({
        pricingFlow,
      });
    default:
      return QUOTE_TEMPLATE;
  }
}

function getHamsterQuoteContextTemplate(params: {
  pricingFlow: PricingFlowOrSnapshotForNavigation;
}): string {
  const { pricingFlow } = params;
  if (pricingFlow.type !== 'HAMSTER') {
    datadogRum.addError(
      'getHamsterQuoteContextTemplate called with non-Hamster pricingFlow',
    );
    return QUOTE_TEMPLATE;
  }
  return `[Please add in what elements of the deal you would like approval on. (e.g., discounts, non-annual billing, custom products, etc.)]`;
}
