import { datadogRum } from '@datadog/browser-rum';
import { isNil } from 'lodash';
import Tooltip from 'src/components/Tooltip';
import { formatCurrency } from 'src/utils/formatters';
import { formatCurrencyValue } from '../../Alpaca/alpaca_utils';
import { PricingFlowType } from '../../types';
import { CurrencyValueType } from '../../types_common/price';
import {
  PenguinPricingFlowWithProductVolumes,
  PenguinProductPrices,
  PenguinProductWithVolume,
} from '../penguin_types';
import {
  calculateCostForEachMonth,
  monthlyRevenueForQuotePrice,
} from './PenguinQuoteTable';

function calculateSupportCostsByMonth(params: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}): number[] {
  const { products, recommendedQuote, manualQuote, additionalData } =
    params.pricingFlow;
  const productPrices = params.pricingFlow.pricingSheetData.productInfo;
  let supportCostPerMonth: number[] = [];
  for (
    let monthIdx = 0;
    monthIdx < additionalData.subscriptionTerms;
    monthIdx++
  ) {
    const supportCost = products.reduce(
      (acc, product: PenguinProductWithVolume) => {
        const skuGroup = productPrices[product.id]?.skuGroup;
        if (
          skuGroup === 'Support' ||
          product.name === 'Platform Licensing Fee' || // Platform Licensing Fees are calculated the same way
          product.name === 'Platform Licensing Fee (CRA)'
        ) {
          const quotePrice = manualQuote?.products?.[product.id] ??
            recommendedQuote.products?.[product.id] ?? {
              type: CurrencyValueType.FLAT,
              value: 0,
              currency: params.pricingFlow.additionalData.quoteCurrency,
            };
          acc +=
            monthlyRevenueForQuotePrice(
              quotePrice,
              product.volume.value,
              product.transactionSize?.value,
              productPrices[product.id],
              monthIdx,
            ) ?? 0;
        }
        return acc;
      },
      0,
    );
    supportCostPerMonth.push(supportCost);
  }
  return supportCostPerMonth;
}

function calculateImplementationServicesCost(params: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
}): number[] {
  const { pricingFlow, productPrices } = params;

  // create an array of pricingFlow.additionalData.subscriptionTerms with 0
  let implementationServicesCostPerMonth: number[] = [];
  for (let i = 0; i < pricingFlow.additionalData.subscriptionTerms; i++) {
    implementationServicesCostPerMonth.push(0);
  }

  // get the implementation services products
  const implementationServicesProducts = pricingFlow.products.filter(
    (product) => {
      const skuGroup = productPrices[product.id]?.skuGroup;
      return (
        skuGroup === 'Implementation Services' ||
        (skuGroup === 'Partnerships' &&
          product.name !== 'Platform Licensing Fee' &&
          product.name !== 'Platform Licensing Fee (CRA)') // Most partnerships products are calculated the same way, excpet for Platform Licensing Fees
      );
    },
  );

  // for each product, for each month, add the price to the implementationServicesCostPerMonth array
  for (const product of implementationServicesProducts) {
    // check how many months this product is billed for
    const productPrice = productPrices[product.id];
    if (isNil(productPrice)) {
      continue;
    }
    const priceBilledForNumberOfMonths =
      productPrice.priceBilledForNumberOfMonths;
    // add the price to the implementationServicesCostPerMonth array
    let quotePrice =
      pricingFlow.manualQuote?.products?.[product.id] ??
      pricingFlow.recommendedQuote.products?.[product.id];
    if (quotePrice?.type !== CurrencyValueType.FLAT) {
      datadogRum.addError(
        `Implementation Services product must have a flat price, got instead: ${quotePrice}`,
      );
      quotePrice = { type: CurrencyValueType.FLAT, value: 0, currency: 'USD' }; // just set it to 0 then
    }
    const quotePriceMonthly = quotePrice.value / priceBilledForNumberOfMonths;
    for (let i = 0; i < priceBilledForNumberOfMonths; i++) {
      implementationServicesCostPerMonth[i] += quotePriceMonthly ?? 0;
    }
  }
  return implementationServicesCostPerMonth;
}

/**
 * Calculate contractual revenue cost per month
 * @param params
 * @returns an array of the monthly costs for the contractual revenue from month 1 to month subscriptionTerms
 */
export function calculateMinimumAndSupportCostDynamicPerMonth(params: {
  productPrices: PenguinProductPrices;
  pricingFlow: PenguinPricingFlowWithProductVolumes;
}): number[] {
  const { productPrices, pricingFlow } = params;
  const { recommendedQuote, manualQuote } = pricingFlow;

  // Calculate the monthly support products cost
  const supportCostsMonthly = calculateSupportCostsByMonth({
    pricingFlow,
  });

  const implementationServicesCost = calculateImplementationServicesCost({
    pricingFlow,
    productPrices,
  });

  // Create an array of the monthly minimums for each month of the subscriptionTerms
  let contractualCostPerMonth: number[] = [];
  for (
    let monthIdx = 0;
    monthIdx < pricingFlow.additionalData.subscriptionTerms;
    monthIdx++
  ) {
    // Each monthly minimum product code incurs a copy of the monthly minimum
    // cost
    const numMonthlyMinProducts =
      pricingFlow.additionalData.additionalMonthlyMinimumProductCodes?.length ??
      0;
    const enteredMonthlyMin: number =
      manualQuote?.monthlyMinimum?.value ??
      recommendedQuote.monthlyMinimum.value;

    if (pricingFlow.additionalData?.minimumRampUp == null) {
      // There's no minimum ramp up, so use the fixed monthly minimum
      contractualCostPerMonth.push(
        enteredMonthlyMin * numMonthlyMinProducts +
          supportCostsMonthly[monthIdx] +
          implementationServicesCost[monthIdx],
      );
    } else {
      // There's a minimum ramp up
      const minimum =
        (pricingFlow.additionalData.minimumRampUp[monthIdx]?.value ??
          enteredMonthlyMin) * numMonthlyMinProducts;
      contractualCostPerMonth.push(
        minimum +
          supportCostsMonthly[monthIdx] +
          implementationServicesCost[monthIdx],
      );
    }
  }

  return contractualCostPerMonth;
}

export default function AnnualCosts(props: {
  pricingFlow: PenguinPricingFlowWithProductVolumes;
  productPrices: PenguinProductPrices;
}) {
  const { pricingFlow, productPrices } = props;

  const numberOfYears = Math.ceil(
    pricingFlow.additionalData.subscriptionTerms / 12,
  );

  /**
   * CONTRACTUAL REVENUE
   */
  // Sum up the minimum costs for each year of the subscriptionTerms
  const contractualCostPerMonth: number[] =
    calculateMinimumAndSupportCostDynamicPerMonth({
      productPrices,
      pricingFlow,
    });

  let minCostPerYear: number[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    let costForYear = 0;
    for (let j = 0; j < 12; j++) {
      const month = i * 12 + j;
      if (month < contractualCostPerMonth.length) {
        costForYear += contractualCostPerMonth[month];
      } else {
        break; // no more months left in subscription terms
      }
    }
    minCostPerYear.push(costForYear);
  }

  /**
   * USAGE BASED REVENUE
   */

  // Calculate the cost based on usage products for each month of the subscriptionTerms
  const productCostPerMonth = calculateCostForEachMonth({
    pricingFlow,
  });

  // Sum up the cost for each year of the subscriptionTerms
  let productCostPerYear: number[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    let costForYear = 0;
    for (let j = 0; j < 12; j++) {
      const month = i * 12 + j;
      if (month < productCostPerMonth.length) {
        costForYear += productCostPerMonth[month].value;
      } else {
        break; // no more months left in subscription terms
      }
    }
    productCostPerYear.push(costForYear);
  }

  /**
   * COMBINE
   */

  // Combine minCostPerYear and productCostPerYear into a single array for display ease
  // costPerYear = {minCost: number, productCost: number}
  let costPerYear: { minCost: number; productCost: number }[] = [];
  for (let i = 0; i < numberOfYears; i++) {
    costPerYear.push({
      minCost: minCostPerYear[i],
      productCost: productCostPerYear[i],
    });
  }

  // @ts-ignore
  const IS_COMPLEX_DEMO = pricingFlow.type === PricingFlowType.COMPLEX_DEMO;
  let supportPricePercentage: number | undefined = undefined;
  if (IS_COMPLEX_DEMO) {
    // Find the recommended price of the Support products and add them together
    const supportBasicPriceRecommended = pricingFlow.recommendedQuote.products[
      'Developer Support (Basic)'
    ]
      ? pricingFlow.recommendedQuote.products['Developer Support (Basic)'].value
      : undefined;
    const supportBasicPriceManual =
      isNil(pricingFlow.manualQuote?.products?.['Developer Support (Basic)']) ||
      pricingFlow.manualQuote.products?.['Developer Support (Basic)'].type ===
        'tiered' ||
      pricingFlow.manualQuote.products?.['Developer Support (Basic)'].type ===
        'ramped'
        ? undefined
        : pricingFlow.manualQuote.products['Developer Support (Basic)'].value;
    const supportBasicPrice =
      supportBasicPriceManual ?? supportBasicPriceRecommended ?? undefined;
    const supportPremiumRecommended = pricingFlow.recommendedQuote.products[
      'Developer Support (Premium)'
    ]
      ? pricingFlow.recommendedQuote.products['Developer Support (Premium)']
          .value
      : undefined;

    const supportPremiumManual =
      isNil(
        pricingFlow.manualQuote?.products?.['Developer Support (Premium)'],
      ) ||
      pricingFlow.manualQuote?.products['Developer Support (Premium)'].type ===
        'tiered' ||
      pricingFlow.manualQuote?.products['Developer Support (Premium)'].type ===
        'ramped'
        ? undefined
        : pricingFlow.manualQuote.products['Developer Support (Premium)'].value;
    const supportPremiumPrice =
      supportPremiumManual ?? supportPremiumRecommended ?? undefined;
    // Add all the support prices together
    const hasSupport =
      supportBasicPrice !== undefined || supportPremiumPrice !== undefined;
    supportPricePercentage = hasSupport
      ? (supportBasicPrice ?? 0) + (supportPremiumPrice ?? 0)
      : undefined;
  }

  return (
    <div className="mt-4 flex w-full rounded-xl border border-gray-200 bg-white">
      <table className="min-w-full border-separate border-spacing-0">
        <thead>
          <tr>
            <th
              scope="col"
              className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              Total
            </th>
            <th
              scope="col"
              className="has-tooltip rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              <Tooltip
                as="th"
                scope="col"
                className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                location="TOP"
                text="Annual revenue from monthly minimums and support fees"
              >
                <>Contractually committed revenue</>
              </Tooltip>
            </th>
            <th
              scope="col"
              className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
            >
              <Tooltip
                as="th"
                scope="col"
                className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                location="TOP"
                text="Annual revenue based on usage"
              >
                <>Usage-based revenue</>
              </Tooltip>
            </th>
            {IS_COMPLEX_DEMO && (
              <th
                scope="col"
                className="rounded-tl-xl border-b px-6 py-3.5 text-left text-xs font-medium text-gray-700 backdrop-blur backdrop-filter"
              >
                <Tooltip
                  as="th"
                  scope="col"
                  className="has-tooltip text-left text-xs font-medium text-gray-700 backdrop-filter"
                  location="TOP"
                  text="Revenue from developer support"
                >
                  <>Support revenue</>
                </Tooltip>
              </th>
            )}
          </tr>
        </thead>

        <tbody>
          {costPerYear.map((costs, idx) => {
            const { minCost, productCost } = costs;
            // For each year, display the number of billable months
            // which may be less than 12 in the last year
            const numberOfBillableMonths =
              idx < numberOfYears - 1
                ? 12
                : pricingFlow.additionalData.subscriptionTerms % 12 === 0
                  ? 12
                  : pricingFlow.additionalData.subscriptionTerms % 12;
            return (
              <tr key={idx}>
                <td className="text-md px-6 py-3 text-gray-500">
                  Year {idx + 1} ({numberOfBillableMonths} month
                  {numberOfBillableMonths === 1 ? '' : 's'})
                </td>
                <td className="text-md relative px-6 py-3 font-semibold text-gray-500">
                  {formatCurrencyValue(
                    {
                      type: CurrencyValueType.FLAT,
                      value: minCost,
                      currency: pricingFlow.additionalData.quoteCurrency,
                    },
                    2,
                  )}
                </td>
                <td className="text-md px-6 py-3 font-semibold text-gray-500">
                  {formatCurrencyValue(
                    {
                      type: CurrencyValueType.FLAT,
                      value: productCost,
                      currency: pricingFlow.additionalData.quoteCurrency,
                    },
                    2,
                  )}
                </td>
                {IS_COMPLEX_DEMO && (
                  <td className="text-md px-6 py-3 font-semibold text-gray-500">
                    {typeof supportPricePercentage === 'number'
                      ? formatCurrency({
                          amount: (productCost * supportPricePercentage) / 100,
                          currency: pricingFlow.additionalData.quoteCurrency,
                          rounding: true,
                        })
                      : 'N/A'}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
